import { useContext, useState } from 'react'
import { useFormikContext } from 'formik'
import { motion } from "framer-motion"
import { useCustomEventListener } from 'react-custom-events'
import classnames from 'classnames/bind'
import * as MotionElement from '~/components/ui/MotionElement'
import { StepperContext } from '~/components/ui/ViewStepper'
import { StoreContext } from '~/store'
import { FormContent, FormHeader, PackageDetails, PackageBookingSummary, ButtonDrawer } from '~/components'
import { deleteBooking } from '~/lib/api'
import { getStatusText, allowCancel } from '~/utils/data'
import { showErrorModal } from '~/utils'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(common)

const BookingDone = () => {
  const { t } = useTranslation()
  const { values, resetForm } = useFormikContext()
  const { reset: resetStepper } = useContext(StepperContext)
  const { bookings, getActiveBookings } = useContext(StoreContext)
  const [isLoading, setIsLoading] = useState(false)

  const booking = bookings?.find(item => item.id === values.bookingId)

  const cancelBooking = () => {
    setIsLoading(true)
    deleteBooking(values?.bookingId).then((response) => {
      console.log(response)
      getActiveBookings()
      close()
    })
    .catch((error) => {
      if (error?.response?.error === 'ALREADY_CANCELLED_BOOKING') {
        getActiveBookings()
        close()
      }
      else if (!(error?.response?.status === 401 && error?.response?.error === 'Unauthorized')) {
        console.error(error)
        showErrorModal(error?.response?.message)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  // TODO: Use old JS for now, replace the ModalView with React in the future.
  const cancel = () => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      COPY.booking.receipt_confirm_cancel, [
        { title: COPY.booking.button_ok, callback: cancelBooking },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  const close = () => {
    resetForm()
    resetStepper()
  }

  const buttons = [
    { func: cancel, title: t('button-cancel-booking'), disabled: booking && !allowCancel(booking), isLoading },
    { func: close, title: t('button-close') },
  ]

  useCustomEventListener(
    'CANCEL_BOOKING',
    (bookingId) => {
      if (values.bookingId === bookingId) close()
    },
    [values.bookingId]
  )

  return (
    <motion.div layout transition={{duration:.18}} className={cx(common.widgetWrapper, { isLoading })}>
      <FormContent>
        <FormHeader>{booking ? getStatusText(booking) : '\u00A0'}</FormHeader>
        <PackageBookingSummary values={values} showHow showPayment />
      </FormContent>
      <MotionElement.div className={cx(common.contentBottom, { largePadding: true })}>
        <PackageDetails values={values} />
      </MotionElement.div>
      <ButtonDrawer buttons={buttons} />
    </motion.div>
  )
}

BookingDone.validationSchema = null

export default BookingDone
