.info {
  padding: var(--spacing) calc(var(--spacing) * 2);
  color: var(--color-black-50);
  font-size: 13px;

  & > a {
    color: var(--color-black-50);
  }
}

.instructions {
  color: var(--color-black-50);
  font-size: 13px;
}

.message {
  padding: calc(var(--spacing) * 2);
}
