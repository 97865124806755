import { useContext, useState, useRef } from 'react'
import { useCustomEventListener } from 'react-custom-events'
import classnames from 'classnames/bind'
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'
import { StoreContext } from '~/store'
import { getUserVerificationToken, getPaymentToken } from '~/lib/api'
import { processPayment } from '~/lib/piq'
import { CloseButton } from '~/components'
import { showErrorModal } from '~/utils'
import * as styles from './index.module.css'
import { addCardConfig } from './config'

const cx = classnames.bind(styles)
const { PAYMENTIQ_ENVIRONMENT, MERCHANT_ID } = process.env

export default function PaymentModal() {
  const { t } = useTranslation()
  const { user } = useContext(StoreContext)
  const [isVisible, setIsVisible] = useState(false)
  const [isCashier, setIsCashier] = useState(false)
  const statusFuncRef = useRef()
  const doneLoadingRef = useRef()
  const containerRef = useRef()
  const cashierRef = useRef()
  const wrapperRef = useRef()


  const showCashier = (baseConfig, userId, paymentToken, amount, accountId = null, success = null) => {
    // Reset cashier if it was already rendered
    window._PaymentIQCashierReset()

    const config = {
      ...baseConfig,
      environment: PAYMENTIQ_ENVIRONMENT,
      merchantId: MERCHANT_ID,
      userId: userId,
      sessionId: paymentToken,
      amount: amount,
      attributes: {
        paymentToken: paymentToken,
        userId: userId
      },
      locale: i18next.language === 'sv' ? 'sv_SE' : 'en_GB',
    }
    if (accountId) config.accountId = accountId

    console.log(config)

    new _PaymentIQCashier('#cashier',
      config,
      (api) => {
        console.log('Cashier inited.')
        api.on({
          cashierInitLoad: () => console.log('Cashier init load'),
          update: data => console.log('The passed in data was set', data),
          success: data => {
            console.log('Transaction was completed successfully', data)
            if (success) success()
            setTimeout(closeModal, 100)
          },
          failure: data => console.log('Transaction failed', data),
          pending: data => console.log('Transaction is pending', data),
          unresolved: data => console.log('Transaction is unresolved', data),
          isLoading: data => console.log('Data is loading', data),
          doneLoading: data => console.log('Data has been successfully downloaded', data),
          newProviderWindow: data => console.log('A new window / iframe has opened', data),
          paymentMethodSelect: data => console.log('Payment method was selected', data),
          paymentMethodPageEntered: data => console.log('New payment method page was opened', data),
          navigate: data => console.log('Path navigation triggered', data),
          cancelledPendingWD: data => console.log('A pending withdrawal has been cancelled', data),
          validationFailed: data => console.log('Transaction attempt failed at validation', data),
          cancelled: data => console.log('Transaction has been cancelled by user', data),
          onLoadError: data => console.log('Cashier could not load properly', data),
          transactionInit: data => console.log('A new transaction has been initiated', data)
        })
        api.css(``)
      }
    )
  }

  const addCard = ({ success }) => {
    if (user?.userId) {
      setIsVisible(true)
      setIsCashier(true)
      getUserVerificationToken(user.userId).then((paymentToken) => {
        console.log(paymentToken)
        showCashier(addCardConfig, user.userId, paymentToken, 0, null, success)
      })
    }
  }

  // const payBooking = ({ bookingId, accountId, success }) => {
  //   if (user?.userId) {
  //     setIsVisible(true)
  //     getPaymentToken(user.userId, bookingId).then((paymentToken) => {
  //       console.log(paymentToken)
  //       const decoded = jwt_decode(paymentToken)
  //       showCashier(payBookingConfig, user.userId, paymentToken, decoded.transactionAmount, accountId, success)
  //     })
  //   }
  // }

  const payBooking = ({ bookingId, accountId, success, doneLoading }) => {
    if (user?.userId) {
      getPaymentToken(user.userId, bookingId).then((paymentToken) => {
        console.log(paymentToken)
        processPayment(user.userId, accountId, paymentToken).then((response) => {
          console.log(response)
          if (response.success) {
            // Show IFRAME with provider redirect
            if (response.redirectOutput) {
              console.log('Success, redirect to provider.')
              doneLoadingRef.current = doneLoading
              openRedirect(response.redirectOutput, success)
            }
            // No redirect needed, close overlay
            else {
              console.log('Success, no redirect needed.')
              success()
            }
          }
          // No success, show error message
          else {
            let message = response.errors.map(item => item.msg).join('. ')
            if (!/\.$/.test(message)) {
              message += '.'
            }
            showErrorModal(message)
            doneLoading()
          }
        })
        .catch((error) => {
          console.error(error)
          doneLoading()
        })
      })
      .catch((error) => {
        console.error(error)
        doneLoading()
      })
    }
  }

  const closeModal = () => {
    setIsVisible(false)
    setIsCashier(false)
    if (doneLoadingRef.current) {
      doneLoadingRef.current()
      doneLoadingRef.current = null
    }
    if (wrapperRef.current) {
      wrapperRef.current.remove()
    }
    if (statusFuncRef.current) {
      window.removeEventListener('message', statusFuncRef.current)
      statusFuncRef.current = null
    }
    window._PaymentIQCashierReset()
  }

  const openRedirect = (redirect, success) => {
    console.log('openRedirect')

    if (containerRef.current) {

      // Eventhandler that handles postMessage from the redirect URL:s 
      statusFuncRef.current = ({ origin, data }) => {
        if (origin === location.origin && data?.type === 'PAYMENT_STATUS') {
          if (data.status === 'success') {
            closeModal()
            success()
          }
          else if (data.status === 'failure') {
            closeModal()
            showErrorModal(t('error-payment'))
          }
          else if (data.status === 'pending') {
          }
          else if (data.status === 'cancel') {
            closeModal()
          }
        } 
      }
      window.addEventListener('message', statusFuncRef.current)
      
      // NOTE: Testing of POST with parameters
      // redirect.method = 'POST'
      // redirect.parameters = {
      //   testing: 'testing the POST',
      //   param: 'a parameter',
      //   terms: 'termslink'
      // }

      // Create wrapper to hold all created elements
      const wrapper = document.createElement('div')
      containerRef.current.appendChild(wrapper)
      wrapperRef.current = wrapper

      // Create IFRAME
      const iframe = document.createElement('iframe')
      iframe.name = 'payment-iframe'
      iframe.className = styles.iframe
      iframe.width = redirect.width
      iframe.height = redirect.height
      wrapper.appendChild(iframe)

      // If POST create a form and post to the IFRAME
      if (redirect.method === 'POST') {
        const form = document.createElement('form')
        form.className = styles.form
        form.method = 'POST'
        form.target = 'payment-iframe'
        form.action = redirect.url

        // Create inputs
        Object.entries(redirect.parameters).forEach(([key, value]) => {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = key
          input.value = value
          form.appendChild(input)
        })

        wrapper.appendChild(form)
        form.submit()
      }
      // Otherwise just set the redirect URL
      else {
        iframe.src = redirect.url
      }

      setIsVisible(true)
    }
  }

  // useCustomEventListener('SHOW_IFRAME', openRedirect)
  useCustomEventListener('CASHIER_ADD_CARD', addCard)
  useCustomEventListener('CASHIER_PAY_BOOKING', payBooking)

  return (
    <div className={cx(styles.wrapper, { isVisible })}>
      <div ref={containerRef} className={cx(styles.container, { isCashier })}>
        <CloseButton onClick={closeModal} />
        <div ref={cashierRef} id="cashier" />
      </div>
    </div>
  )
}

