import { useLocation }  from 'wouter'
import { getLocalizedSlug } from '../'
import { emitCustomEvent } from 'react-custom-events'
import { useContext } from 'react'
import { StoreContext } from '~/store'

export default function InfoView({ headline, body, showLogin = false}) {
  const { user } = useContext(StoreContext)
  const [location, setLocation] = useLocation()

  const onLoginComplete = () => {
    setLocation(getLocalizedSlug('profile'))
  }

  const onLoginClick = () => {
    emitCustomEvent('SHOW_LOGIN', { show: true, callback: onLoginComplete })
  }

  const COPY = window.COPY

  return (
    <section className="not-logged-in-view info">
      <div className="text-container">
        <h2>{headline}</h2>
        <p className="body" dangerouslySetInnerHTML={{__html: body}} />
        <div className="form-rows-wrapper">
          <div className="form-row-bottom">
            {!user && showLogin && <a href="#" onClick={onLoginClick}>{COPY.my_pages.register_login}</a>}
          </div>
        </div>
      </div>
    </section>
  )
}
