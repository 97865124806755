.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconWrapper {
  position: relative;
  width: 40px;
  height: 40px;
}

.icon {
  position: absolute;

  &.ENVELOPE {
    width: 58px;
    height: 58px;
    left: -9px;
    top: -15px;
  }

  &.BOX {
    width: 74px;
    height: 74px;
    left: -17px;
    top: -22px;
  }

  &.BAG {
    width: 78px;
    height: 78px;
    left: -19px;
    top: -23px;
  }

  &.MOVING_BOX {
    width: 56px;
    height: 56px;
    left: -8px;
    top: -16px;
  }

}

.right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.field {
  width: 50px;
}
