import classnames from 'classnames'
import { useState } from 'react'
import { Link } from 'wouter'
import { getLocalizedSlug } from '../'
import { useTranslation } from 'react-i18next'

export default function Menu() {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const COPY = window.COPY

  return (
    <nav id="my-pages-menu">
      <button className="btn white fw menu-button" onClick={toggleMenu}>{COPY.my_pages.menu_header}</button>
      <ul className={classnames({ open: isOpen })}>
        <li><Link href={getLocalizedSlug('profile')}>{COPY.my_pages.menu_profile}</Link></li>
        <li><Link href={getLocalizedSlug('payment')}>{COPY.my_pages.menu_payment}</Link></li>
        <li><Link href={getLocalizedSlug('orders')}>{t('menu-my-bookings')}</Link></li>
      </ul>
    </nav>
  )
}
