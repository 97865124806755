.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: white;

  &.isDisabled {
    cursor: default;
    pointer-events: none;

    & > * {
      opacity: 0.2;
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black-50);

  &.isChecked {
    color: black;
  }
}
