.wrapper {
  position: relative;

  &.isOpen {
    & .button {
      transform: scaleY(-100%)
    }
    & .dropDown {
      display: block;
    }
  }
}

.button {
  position: relative;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible:before {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px var(--color-yellow);
  }
}

.dropDown {
  position: absolute;
  display: none;
  width: 100%;
  padding-top: 5px;
  margin-top: -5px;
  background: var(--color-cool-gray);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  z-index: 1;
}
