.wrapper {
  display: flex;
  align-items: center;
  color: black;
  font-size: 13px;
  font-weight: 500;
  margin-right: 20px;
  height: 32px;

  &.notLoggedIn {
    text-decoration: underline;
  }
}

.userIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  background: var(--color-yellow);
  color: white;
}
