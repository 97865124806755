import { motion } from "framer-motion"

const conf = {
  transition:{duration:.18},
      initial:{ y: 20, opacity:0 },
      animate:{ y: 0,  opacity:1 },
      exit:{ y: 20,  opacity:0 }
}

const confScale = {
  transition:{duration:.18},
  initial:{ height:0, opacity:0 },
  animate:{ height:'auto', opacity:1 },
  exit:{ y: 20,  opacity:0 }
}

export function h3(props) {return <motion.h3 {...props} {...conf}></motion.h3>}
export function div(props) {const c = props.type=="scale" ? confScale : conf; return <motion.div {...props} {...c}></motion.div>}