import { useContext } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import classnames from 'classnames/bind'
import { StoreContext } from '~/store'
import { StepperContext } from '~/components/ui/ViewStepper'
import { getPackages, formatReadableDate } from '~/utils/format'
import { getTaxiAndEta, getPackageText, getPriceFromEstimate, hasTaxiAndEta } from '~/utils/data'
import { ChangeButton } from '~/components'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(styles)

const formatAddress = address => {
  return address.streetNumber 
    ? address.streetName + ' ' + address.streetNumber
    : address.streetName
}

const renderPayment = (value, accounts) => {
  if (value !== 'PayInCar' && accounts) {
    const account = accounts.find(a => a.accountNumber === parseInt(value))
    return (
      <>
        <div className={common.title}>{account.accountName}</div>
        <div className={common.subtitle}>{account.accountNumber}</div>
      </>
    )
  }
  return <div className={common.title}>{i18next.t('title-pay-in-car')}</div>
}

export default function PackageBookingSummary({ values, showHow = false, showPayment = false }) {
  const { t } = useTranslation()
  const { user, bookings } = useContext(StoreContext)
  const { prev } = useContext(StepperContext)

  const booking = bookings?.find(item => item.id === values.bookingId)

  const hasDeparture = values.departure?.address !== undefined
  const hasDestination = values.destination?.address !== undefined
  const fromAddress = hasDeparture ? formatAddress(values.departure.address) : ''
  const fromCity = hasDeparture ? values.departure.address.city : ''
  const toAddress = hasDestination ? formatAddress(values.destination.address) : ''
  const toCity = hasDestination ? values.destination.address.city : ''

  const taxiAndEta = booking ? getTaxiAndEta(booking) : ''
  const time = values.pickupTime ? formatReadableDate(values.pickupTime) : t('label-as-soon-as-possible')
  const showPrice = values.priceEstimate
  const packages = getPackages(values)
  const hasBooking = !isNaN(values?.bookingId)

  return (
    <>
      <MotionElement.div className={common.content}>
        <div className={common.group}>
          <div>
            <div className={common.label}>{t('label-from')}{!hasBooking && <ChangeButton onClick={() => prev('locations')}/>}</div>
            <div className={styles.content}>
              <div className={common.title}>{fromAddress}</div>
              <div className={common.subtitle}>{fromCity}</div>
            </div>
          </div>
          <div>
            <div className={common.label}>{t('label-to')}{!hasBooking && <ChangeButton onClick={() => prev('locations')}/>}</div>
            <div className={styles.content}>
              <div className={common.title}>{toAddress}</div>
              <div className={common.subtitle}>{toCity}</div>
            </div>
          </div>
        </div>
        <div className={common.group}>
          <div>
            <div className={common.label}>{t('label-when')}{!hasBooking && <ChangeButton onClick={() => prev('time')}/>}</div>
            <div className={styles.content}>
              <div className={cx(common.title, common.capitalize)}>{time}</div>
              {hasTaxiAndEta(booking) && taxiAndEta && <div className={common.subtitle}>{taxiAndEta}&nbsp;</div>}
            </div>
          </div>
          {showPayment && <div>
            <div className={common.label}>{t('label-payment')}</div>
            {renderPayment(values.payment, user?.accounts)}
          </div>}
          <div>
            <div className={common.label}>{t('label-price')}</div>
            <div className={common.title}>
              {t('label-fixed-price')} {showPrice && getPriceFromEstimate(values.priceEstimate.prices, values.serviceType) + ':-'}
            </div>

            {<ul className={styles.options}>
            <AnimatePresence>
              {packages.map((value, index) => <motion.li key={index} transition={{duration:.15}} initial={{ height:0, opacity:0 }} animate={{ height:'auto', opacity:1 }} exit={{ height: 0,  opacity:0 }} className={styles.option}>{getPackageText(value)}</motion.li>)}
              </AnimatePresence>
            </ul>}

          </div>
          {hasBooking && <div>
            <div className={common.label}>{t('label-booking-number')}</div>
            <div className={common.title}>{values.bookingId}</div>
          </div>}
        </div>
      </MotionElement.div>
    </>
  )
}
