import classnames from 'classnames/bind'
import { FieldWrapper, TitleInfo } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function BoosterField({ field, form: { setFieldValue }, title, info, reverseLayout, alternateBackground, numSeatsMax, ...props }) {
  const isDisabled = props.disabled
  const items = numSeatsMax === 1 ? [0, 1] : [0, 1, 2]

  const onClick = e => {
    const value = e.target.getAttribute('data-index')
    setFieldValue(field.name, parseInt(value))
  }

  const onKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const onKeyUp = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
      let value = field.value + 1
      if (value > 2) value = 0
      setFieldValue(field.name, value)
    }
  }

  return (
    <FieldWrapper reverseLayout={reverseLayout} alternateBackground={alternateBackground} isDisabled={isDisabled}>
      <TitleInfo title={title} info={info} />
      <ul tabIndex={props.tabIndex} className={cx(styles.options, { isSelected: field.value !== 0 })} onClick={onClick} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        {items.map((value, index) => (
          <li key={index} data-index={index} className={cx(styles.option, { isSelected: index === field.value })}>{value}</li>)
        )}
      </ul>
    </FieldWrapper>
  )
}
