.phoneGroup {
  padding-bottom: 5px !important;
}

.phoneInfo {
  padding: 0 25px 25px;
  font-size: 11px;
  line-height: 1.2em;
}

.wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 26px 25px 20px;
}

.marketingInfo {
  font-size: 11px;
}
