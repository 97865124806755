import { useRef } from 'react'
import { Field } from 'formik'
import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function Switch({ isChecked, name, field, form, onChange, onBlur, innerRef, ...props }) {
  const inputRef = useRef()

  const onKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const onKeyUp = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
      inputRef?.current?.click()
      if (props.onClick) {
        props.onClick()
      }
    }
  }

  const handleChange = e => {
    onChange(e)
    form.setFieldTouched(name, true, false)
  }

  return (
    <div className={styles.wrapper}>
      <label ref={innerRef} tabIndex={props?.disabled ? -1 : props?.tabIndex} className={cx(styles.label, { isChecked })} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        <Field 
          type="checkbox" 
          as="input"
          innerRef={inputRef}
          className={styles.field} 
          name={name}
          onChange={handleChange} 
          onBlur={onBlur} 
          {...props} 
          tabIndex="-1"
        />
      </label>
    </div>
  )
}
