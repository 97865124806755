.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  background: #FFF;
  font-size: 14px;
  color: #000;
}

.button {
  color: var(--color-black-30);
  width: 24px;
  height: 100%;
}

