{
  "/accounts/{accountId}/validate": {
    "INVALID_ACCOUNT": {
      "en": "Account was not found",
      "sv": "Kontot finns inte"
    },
    "ACCOUNT_LOCKED": {
      "en": "Account is locked because of too many failed login attempts, try again later",
      "sv": "Taxikontot är låst på grund av för många misslyckade inloggningsförsök, försök igen senare"
    },
    "WRONG_PASSWORD": {
      "en": "Invalid password, please try again",
      "sv": "Lösenordet är felaktigt, var vänlig försök igen"
    },
    "WRONG_PASSWORD_LAST_RETRY": {
      "en": "There is only one password attempt left",
      "sv": "Det är bara ett inloggningsförsök kvar"
    }
  },
  "/users/{userId}/accounts": {
    "USER_DOES_NOT_EXIST": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    },
    "ACCOUNT_ERROR": {
      "en": "It was not possible to add the account",
      "sv": "Det gick inte att lägga till Taxi Stockholm kontot"
    },
    "ACCOUNT_TEMPORARILY_BLOCKED_ERROR": {
      "en": "Too many attempts, the account is temporarily blocked",
      "sv": "För många försök, du kan prova igen om en stund"
    }
  },
  "/users/{userId}/accounts/{accountNumber}": {
    "USER_DOES_NOT_EXIST": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    },
    "ACCOUNT_NOT_CONNECTED_TO_USER": {
      "en": "Account not found",
      "sv": "Taxi Stockholm kontot finns inte"
    }
  },
  "/bookings": {
    "INVALID_PHONE_NUMBER": {
      "en": "The phone number provided is not in a correct format",
      "sv": "Telefonnumret har fel format"
    },
    "INVALID_DEPARTURE_ADDRESS": {
      "en": "Departure address is unknown",
      "sv": "Okänd hämtadress"
    },
    "INVALID_DESTINATION_ADDRESS": {
      "en": "Destination address is unknown",
      "sv": "Okänd destinationsadress"
    },
    "INVALID_BOOKING_DATETIME": {
      "en": "The selected datetime is too far ahead",
      "sv": "Den valda tidpunkten ligger för långt fram i tiden"
    },
    "INVALID_SERVICE_TYPE_ESTIMATE": {
      "en": "Fixed price is not available",
      "sv": "Fastpris är tyvärr inte tillgängligt"
    },
    "INVALID_PRICE_TOKEN": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpriset är tyvärr inte tillgängligt,var vänlig försök igen"
    },
    "INVALID_EXTERNAL_USER_ID": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_USER_ID": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    },
    "MISSING_INTERNAL_BOOKING_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_REQUEST_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "ACCOUNT_NOT_FOUND": {
      "en": "Account not found",
      "sv": "Kontot finns inte"
    },
    "UNMATCHED_ACCOUNT_NUMBER": {
      "en": "Account not found",
      "sv": "Kontot finns inte"
    },
    "PASSWORD_IS_NOT_STORED": {
      "en": "Password is missing for the account",
      "sv": "Lösenord saknas för ditt Taxi Stockholm konto"
    },
    "INVALID_PAYMENT_METHOD": {
      "en": "Payment method is required",
      "sv": "För att boka krävs en betalningsmetod"
    },
    "FIXED_PRICE_REQUIRED": {
      "en": "Only fixed price is available for this trip",
      "sv": "Endast fastpris är tillgängligt för denna resa"
    },
    "FIXED_PRICE_OR_PRICE_TOKEN_REQUIRED": {
      "en": "Only fixed price is available for this trip",
      "sv": "Endast fastpris är tillgängligt för denna resa"
    },
    "BOOKING_ADDRESS_OR_TIME_MISMATCH_WITH_PRICE_ESTIMATE": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpris är tyvärr inte tillgängligt,var vänlig försök igen"
    },
    "ACCOUNT_NUMBER_REQUIRED": {
      "en": "Account number is required",
      "sv": "Var vänlig ange Taxi Stockholm konto"
    },
    "DESTINATION_REQUIRED": {
      "en": "Your TSAB account require a specified destination",
      "sv": "För att kunna boka med ditt TSAB konto så behöver du ange en destination för din resa"
    },
    "UNMAPPED_TTES_ERROR": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "RESTRICTED_DEPARTURE_FOR_DIRECT_BOOKING": {
      "en": "Unfortunately we are not able to handle direct bookings from Arlanda in the app. If you want a car right away please contact us on 08-15 00 00. You are always welcome to prebook a cab from Arlanda in the app.",
      "sv": "Tyvärr kan vi inte ta emot direktbeställningar från Arlanda i appen. om du vill ha en taxi omgående vänligen ring oss på 08-15 00 00. Du är alltid välkommen att förboka en taxi från Arlanda via appen."
    },
    "NO_TAXI_AVAILABLE": {
      "en": "We cannot accept any more pre-bookings at the selected time, please choose another time",
      "sv": "Vi kan tyvärr inte ta emot fler förbokningar den valda tiden, var vänlig välj en annan tid"
    },
    "OVERLOADED": {
      "en": "Right now all our cars are occupied. However, you can make a pre-booking or try again later.",
      "sv": "Just nu är alla våra bilar upptagna. Du kan däremot göra en förbokning eller prova igen senare."
    },
    "INCOMPARABLE_VEHICLE_OPTION_WITH_SERVICE_TYPE": {
      "en": "This service_type dont allow using the specified vehicle option",
      "sv": "Du kan tyvärr inte välja denna biltyp"
    }
  },
  "/bookings/{bookingId}": {
    "INVALID_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_BOOKING_SEARCH_USER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_EXTERNAL_USER_ID": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_USER_ID": {
      "en": "Oops! Something went wrong",
      "sv": "Oj, något gick fel"
    },
    "MISSING_INTERNAL_BOOKING_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_REQUEST_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "Not found": {
      "en": "Booking not found",
      "sv": "Bokningen kunde inte hittas"
    },
    "INVALID_PREPARE_STATUS_FOR_CANCEL": {
      "en": "Booking is not possible to cancel",
      "sv": "Bokningen kunde inte avbeställas"
    },
    "INVALID_PAYMENT_STATUS_FOR_CANCEL": {
      "en": "Booking is not possible to cancel",
      "sv": "Bokningen kunde inte avbeställas"
    },
    "ALREADY_COMPLETED_BOOKING": {
      "en": "Booking is already completed",
      "sv": "Bokningen är redan slutförd"
    },
    "ALREADY_CANCELLED_BOOKING": {
      "en": "Booking is already cancelled",
      "sv": "Bokningen är redan avbeställd"
    },
    "NOT_ALLOWED_TO_CANCEL_BOOKING": {
      "en": "Booking is not possible to cancel",
      "sv": "Bokningen kunde inte avbeställas"
    }
  },
  "/bookings/status": {
    "INVALID_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_BOOKING_SEARCH_USER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_EXTERNAL_USER_ID": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_USER_ID": {
      "en": "Oops! Something went wrong",
      "sv": "Oj, något gick fel"
    },
    "MISSING_INTERNAL_BOOKING_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_REQUEST_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "Not found": {
      "en": "Booking not found",
      "sv": "Bokningen kunde inte hittas"
    }
  },
  "/bookings/users/{userId}": {
    "INVALID_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_SEARCH_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_BOOKING_SEARCH_USER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_EXTERNAL_USER_ID": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_USER_ID": {
      "en": "Oops! Something went wrong",
      "sv": "Oj, något gick fel"
    },
    "MISSING_INTERNAL_BOOKING_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_REQUEST_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "Not found": {
      "en": "Booking not found",
      "sv": "Bokningen kunde inte hittas"
    }
  },
  "/bookings/multistop": {
    "INVALID_PHONE_NUMBER": {
      "en": "The phone number provided is not in a correct format",
      "sv": "Telefonnumret har fel format"
    },
    "INVALID_DEPARTURE_ADDRESS": {
      "en": "Departure address is unknown",
      "sv": "Okänd hämtadress"
    },
    "INVALID_DESTINATION_ADDRESS": {
      "en": "Destination address is unknown",
      "sv": "Okänd destinationsadress"
    },
    "INVALID_BOOKING_DATETIME": {
      "en": "The selected datetime is too far ahead",
      "sv": "Den valda tidpunkter ligger för långt fram i tiden"
    },
    "INVALID_SERVICE_TYPE_ESTIMATE": {
      "en": "Fixed price is not available",
      "sv": "Fastpris är tyvärr inte tillgängligt"
    },
    "INVALID_PRICE_TOKEN": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpriset är tyvärr inte tillgängligt,var vänlig försök igen"
    },
    "INVALID_EXTERNAL_USER_ID": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_USER_ID": {
      "en": "Oops! Something went wrong",
      "sv": "Oj, något gick fel"
    },
    "MISSING_INTERNAL_BOOKING_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_BOOKING_REQUEST_OBJECT": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "ACCOUNT_NOT_FOUND": {
      "en": "Account not found",
      "sv": "Kontot finns inte"
    },
    "UNMATCHED_ACCOUNT_NUMBER": {
      "en": "Account not found",
      "sv": "Kontot finns inte"
    },
    "PASSWORD_IS_NOT_STORED": {
      "en": "Password is missing for the account",
      "sv": "Lösenord saknas för ditt Taxi Stockholm konto"
    },
    "INVALID_PAYMENT_METHOD": {
      "en": "Payment method is required",
      "sv": "För att boka krävs en betalningsmetod"
    },
    "FIXED_PRICE_REQUIRED": {
      "en": "Only fixed price is available for this trip",
      "sv": "Endast fastpris är tillgängligt för denna resa"
    },
    "FIXED_PRICE_OR_PRICE_TOKEN_REQUIRED": {
      "en": "Only fixed price is available for this trip",
      "sv": "Endast fastpris är tillgängligt för denna resa"
    },
    "BOOKING_ADDRESS_OR_TIME_MISMATCH_WITH_PRICE_ESTIMATE": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpris är tyvärr inte tillgängligt,var vänlig försök igen"
    },
    "ACCOUNT_NUMBER_REQUIRED": {
      "en": "Account number is required",
      "sv": "Var vänlig ange Taxi Stockholm konto"
    },
    "TOO_MANY_INTERMEDIATE_STOPS": {
      "en": "Sorry, but you have added too many intermediate stops",
      "sv": "Tyvärr så har du angivit för många via-adresser"
    },
    "NOT_ALLOWED_INTERMEDIATE_STOP": {
      "en": "Sorry, but this location can not be added as an intermediate stop",
      "sv": "Tyvärr så kan denna adress inte läggas till som via-adress"
    },
    "INVALID_INTERMEDIATE_ADDRESS": {
      "en": "Sorry, but we can not recognize this intermedate adress",
      "sv": "Tyvärr så känner vi inte igen denna adress"
    }
  },
  "/bookings/{bookingId}/email-confirmation": {
    "BOOKING_NOT_FOUND": {
      "en": "Booking was not found",
      "sv": "Bokningen hittades inte"
    }
  },
  "/bookings/users/{userId}/{bookingId}/pdf-receipt": {
    "INVALID_USER_ID": {
      "en": "Oops! Something went wrong",
      "sv": "Oj, något gick fel"
    },
    "PERMISSION_DENIED": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "RECEIPT_NOT_AVAILABLE": {
      "en": "The receipt is not availble",
      "sv": "Det är tyvärr inte möjligt att visa kvittot för denna bokning"
    },
    "RECEIPT_PENDING": {
      "en": "Your receipt is not available yet",
      "sv": "Ditt kvitto är inte tillgängligt ännu"
    }
  },
  "/locations/{locationId}": {
    "LOCATION_NOT_FOUND": {
      "en": "Location not found",
      "sv": "Adressen saknas"
    },
    "MISSING_CITY_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/locations/numbers": {
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/locations/nearby": {
    "MISSING_LONGITUDE_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/locations/search": {
    "MISSING_ADDRESS_STRING_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/locations/search/street-only": {
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MISSING_ADDRESS_STRING_PARAMETER": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/price/estimate": {
    "GPS_POS_NOT_MATCHING": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_LOCATION": {
      "en": "Location not found",
      "sv": "Adressen är inte giltig"
    },
    "LOCATION_NOT_FOUND": {
      "en": "Location not found",
      "sv": "Adressen saknas"
    },
    "ORIGIN_SAME_AS_DESTINATION": {
      "en": "Origin and destination are the same",
      "sv": "Hämtadress och destinationsadress är samma"
    },
    "SERVICE_TYPE_MISSING": {
      "en": "Service type missing",
      "sv": "Tjänst saknas i anropet"
    },
    "ZONE_ID_NOT_MATCHING": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpris saknas, var vänlig försök igen"
    },
    "FIXED_PRICE_FOR_ORIGIN_NOT_FOUND": {
      "en": "Unfortunately we are not able to offer a fixed price between the selected addresses. We have switched to taximeter.",
      "sv": "Tyvärr kan vi inte ervjuda ett fastpris mellan dessa adresser. Vi har bytt till taxameter."
    },
    "FIXED_PRICE_DISABLED": {
      "en": "Unfortunately we are not able to offer a fixed price right now. Payment method is changed to taximeter",
      "sv": "Tyvärr kan vi inte ervjuda ett fastpris just nu. Betalmetod har ändrats till taxameter."
    }
  },
  "/price/estimate/{priceToken}": {
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "PRICE_NOT_FOUND_FOR_TOKEN": {
      "en": "The fixed price has expired, please try again",
      "sv": "Fastpriset har upphört att gälla vänligen försök igen"
    }
  },
  "/price/estimate/multistop": {
    "GPS_POS_NOT_MATCHING": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "INVALID_LOCATION": {
      "en": "Location not found",
      "sv": "Adressen är inte giltig"
    },
    "LOCATION_NOT_FOUND": {
      "en": "Location not found",
      "sv": "Adressen saknas"
    },
    "ORIGIN_SAME_AS_DESTINATION": {
      "en": "We've noticed that the same address has been added multiple times",
      "sv": "Du har angivit samma adress flera gånger"
    },
    "SERVICE_TYPE_MISSING": {
      "en": "Service type missing",
      "sv": "Tjänst saknas i anropet"
    },
    "ZONE_ID_NOT_MATCHING": {
      "en": "Fixed price is not available, please try again",
      "sv": "Fastpris saknas, var vänlig försök igen"
    },
    "FIXED_PRICE_DISABLED": {
      "en": "Unfortunately we are not able to offer a fixed price right now. Payment method is changed to taximeter",
      "sv": "Tyvärr kan vi inte ervjuda ett fastpris just nu. Betalmetod har ändrats till taxameter."
    }
  },
  "/price/estimate/multistop/{priceToken}": {
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "MULTISTOP_PRICE_NOT_FOUND_FOR_TOKEN": {
      "en": "The fixed price has expired, please try again",
      "sv": "Fastpriset har upphört att gälla vänligen försök igen"
    }
  },
  "/estimates/wait": {
    "Bad Request": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/activation/initiate": {
    "BAD_REQUEST_ERROR": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "CLIENT_DOES_NOT_EXIST": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "TOO_MANY_OTP_RESEND_REQUESTS": {
      "en": "Too many attempts, please try again later",
      "sv": "För många försök, du kan prova igen om ett tag"
    },
    "TOO_MANY_OTP_FAILED_ATTEMPTS": {
      "en": "Too many attempts, please try again later",
      "sv": "För många försök, du kan prova igen om ett tag"
    }
  },
  "/activation/validate-phone-otp": {
    "INVALID_OTP": {
      "en": "Invalid code, please try again",
      "sv": "Ogiltig kod, vänligen försök igen"
    }
  },
  "/activation/resend-phone-otp": {
    "DEVICE_ACTIVATION_BAD_STATE": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "DEVICE_ACTIVATION_NOT_FOUND": {
      "en": "You code has expired, please restart your log in",
      "sv": "Din kod gäller inte längre, var vänlig börja om din inloggning"
    },
    "TOO_MANY_OTP_RESEND_REQUESTS": {
      "en": "Too many attempts, please try again later",
      "sv": "För många försök, du kan prova igen om ett tag"
    }
  },
  "/activation/validate-email-otp": {
    "INVALID_OTP": {
      "en": "Invalid code, please try again",
      "sv": "Ogiltig kod, vänligen försök igen"
    }
  },
  "/activation/resend-email-otp": {
    "DEVICE_ACTIVATION_BAD_STATE": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "DEVICE_ACTIVATION_NOT_FOUND": {
      "en": "You code has expired, please restart your log in",
      "sv": "Din kod gäller inte längre, var vänlig börja om din inloggning"
    },
    "TOO_MANY_OTP_RESEND_REQUESTS": {
      "en": "Too many attempts, please try again later",
      "sv": "För många försök, du kan prova igen om ett tag"
    }
  },
  "/activation/add-email": {
    "DEVICE_ACTIVATION_BAD_STATE": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "DEVICE_ACTIVATION_NOT_FOUND": {
      "en": "You code has expired, please restart your log in",
      "sv": "Din kod gäller inte längre, var vänlig börja om din inloggning"
    }
  },
  "/activation/add-name": {
    "DEVICE_ACTIVATION_BAD_STATE": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "DEVICE_ACTIVATION_NOT_FOUND": {
      "en": "You code has expired, please restart your log in",
      "sv": "Din kod gäller inte längre, var vänlig börja om din inloggning"
    }
  },
  "/activation/complete": {
    "DEVICE_ACTIVATION_BAD_STATE": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "DEVICE_ACTIVATION_NOT_FOUND": {
      "en": "You code has expired, please restart your log in",
      "sv": "Din kod gäller inte längre, var vänlig börja om din inloggning"
    }
  },
  "/users/{userId}": {
    "USER_DOES_NOT_EXIST": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    },
    "USER_NOT_ALLOWED_TO_BE_REMOVED": {
      "en": "Unfortunately it is not possible to delete this user, please contact Customer service 08-728 27 00",
      "sv": "Det är tyvärr inte möjligt att ta bort denna användare, kontakta kundtjänst för att få hjälp: 08-728 27 00"
    },
    "USER_HAS_ONGOING_OR_FUTURE_TRIPS_ERROR": {
      "en": "It seems like you still have ongoing trips.You need to cancel them before you can delete your profile.",
      "sv": "Det verkar som du bokningar som inte är genomförda än. Du behöver avboka dem innan du kan ta bort din profil."
    },
    "USER_HAS_PENDING_PAYMENTS_ERROR": {
      "en": "Unfortunately it is not possible to delete this user, since there are ongoing payments",
      "sv": "Det är tyvärr inte möjligt att ta bort denna användare, då det finns pågående betalningar"
    },
    "BAD_REQUEST_ERROR": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "USER_EMAIL_EXISTS": {
      "en": "This email address is already in use, please enter another email address",
      "sv": "Denna epost-adress används redan, var vänlig att ange en annan"
    }
  },
  "/users/{userId}/vouchers": {
    "VOUCHER_ERROR": {
      "en": "This voucher is not valid",
      "sv": "Rabattkoden är inte giltig"
    },
    "EXPIRED_VOUCHER": {
      "en": "This voucher has expired",
      "sv": "Giltighetstiden för rabattkoden har passerats"
    },
    "BAD_REQUEST": {
      "en": "Your voucher could not be added at the moment. Please try again later.",
      "sv": "Rabattkoden kunde dessvärre inte registreras. Försök igen senare"
    },
    "ADD_VOUCHER_TEMPORARILY_BLOCKED_ERROR": {
      "en": "Too many incorrect attempts. Try again later.",
      "sv": "För många felaktiga försök. Försök igen senare."
    },
    "FORBIDDEN_ERROR": {
      "en": "Your voucher can unfortunately not be added.",
      "sv": "Din rabattkod kunde tyvärr inte läggas till."
    },
    "USER_DOES_NOT_EXIST": {
      "en": "Your voucher could not be added at the moment. Please try again later.",
      "sv": "Rabattkoden kunde dessvärre inte registreras. Försök igen senare"
    },
    "TOO_MANY_REQUESTS": {
      "en": "Too many incorrect attempts. Try again later.",
      "sv": "För många felaktiga försök. Försök igen senare."
    },
    "BAD_GATEWAY_ERROR": {
      "en": "Your voucher could not be added at the moment. Please try again later.",
      "sv": "Rabattkoden kunde dessvärre inte registreras. Försök igen senare"
    }
  },
  "/emailverifications/{token}": {
    "USER_DOES_NOT_EXIST": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    },
    "VERIFICATION_TOKEN_EXPIRED_ERROR": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    }
  },
  "/status-messages/search": {
    "BAD_REQUEST": {
      "en": "Oops! Something went wrong, please try again",
      "sv": "Oj, något gick fel. Var vänlig försök igen"
    },
    "USER_DOES_NOT_EXIST": {
      "en": "User does not exist",
      "sv": "Användaren finns inte"
    }
  }
}