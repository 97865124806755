.content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  position: relative;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  &:focus-visible:before {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px var(--color-yellow);
  }

  &:hover {
    background: var(--color-yellow);
    &> svg {
      fill-opacity: 1;
    }
  }
}
