const baseConfig = {
  mode: 'ecommerce',
  listType: 'grid',
  displayLogoOrName: 'name',
  providerType: 'creditcard',

  accountDelete: false,
  autoOpenFirstPaymentMethod: false,
  autoSetIframeProviderHeight: false,
  disableBtnAtZero: false,
  hideTxOverview: true,
  predefinedValues: false,
  showAccounts: false,
  showFee: false,
  showFooter: false,
  showHeader: false,
  showReceipt: false,
  showTransactionOverview: false,
  singlePageFlow: true,

  theme: {
    // labels: {
    //   color: '#FFFFFF'
    // },
    loader: {
      color: '#F7CE46'
    },
    error: {
      color: '#FF0000'
    },
    buttons: {
      color: '#FEBF00'
    }
  },
}

export const addCardConfig = {
  ...baseConfig
}

export const payBookingConfig = {
  ...baseConfig,
  showAccounts: 'list-first',
  showFee: true,
}
