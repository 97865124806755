import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Backend from 'i18next-http-backend'
// NOTE: inline the translations for now since the loading does not work with the setup of validation messages
import * as resources from './i18n-resources.json'

const getLang = () => {
  const lang = document.querySelector('html')?.getAttribute('lang')?.substr(0, 2)
  return ['sv', 'en'].indexOf(lang) !== -1 ? lang : 'sv'
}

i18n
  .use(initReactI18next)
  // .use(Backend)
  .init({
    lng: getLang(),
    fallbackLng: 'sv',
    resources,
    // backend: {
    //   loadPath: '/app/themes/taxi-stockholm/static/locales/{{lng}}/{{ns}}.json'
    // },
    interpolation: {
      escapeValue: false
    },
    // react: {
    //   transSupportBasicHtmlNodes: true,
    //   transKeepBasicHtmlNodesFor: ['br', 'strong', 'i']
    // }
  })

export default i18n
