import { Field, ErrorMessage } from 'formik'
import classnames from 'classnames/bind'
import { FormError, Icon } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function InputField({ field, form, border, alternateBackground, uppercase, changeIcon, ...props }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper}>
        <Field as="input" className={cx(styles.field, { border, alternateBackground, uppercase })} {...field} {...props} />
        {changeIcon && <Icon id="change" className={styles.changeIcon} />}
      </div>
      <ErrorMessage name={field.name} component={FormError} />
    </div>
  )
}
