export const sleep = ms => new Promise(r => setTimeout(r, ms))

let timeout = null

export const timer = {
  start: (at, cb) => {
    if (timeout !== null) timer.clear()
    timeout = setTimeout(cb, at * 1000)
  },
  clear: () => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
  }
}

// Recursively set all properties in an object to true
const setTrue = (obj) => {
  return Object.keys(obj).reduce((a, v) => {
    // return typeof obj[v] === 'object' ? { ...a, [v]: setTrue(obj[v]) } : { ...a, [v]: true }
    if (Array.isArray(obj[v])) return { ...a, [v]: obj[v].map(item => true) }
    else if (typeof obj[v] === 'object') return { ...a, [v]: setTrue(obj[v]) }
    else return { ...a, [v]: true }
  }, {})
}

// Wrap a function with Formik validation
export const wrapWithValidation = (func, { validateForm, setErrors, setTouched }) => {
  return (id) => {
    validateForm()
      .then((errors) => {
        // console.log(errors)
        // console.log(Object.keys(errors))
        if (Object.keys(errors).length > 0) {
          setErrors(errors)
          const touched = setTrue(errors)
          setTouched(touched)
        }
        else func(id)
      })
  }
}

export const validateSetErrorsAndTouch = ({ validateForm, setErrors, setTouched }) => {
  return () => {
    validateForm()
      .then((errors) => {
        // console.log(errors)
        // console.log(Object.keys(errors))
        if (Object.keys(errors).length > 0) {
          setErrors(errors)
          const touched = setTrue(errors)
          setTouched(touched)
        }
      })
  }
}

export const showErrorModal = (message, callback = null) => {
  const btn = callback ? { title: COPY.booking.button_ok, callback: callback } : { title: COPY.booking.button_ok }
  APP.trigger(APP.events.SHOW_MODAL, message, [btn])
}

// DOM selector functions
export const qs = selector => document.querySelector(selector)
export const qsa = selector => [...document.querySelectorAll(selector)]

