import { useContext, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { StoreContext } from '~/store'
import { showErrorModal } from '~/utils'
import { fetchEta, fetchPriceEstimate, fetchServiceAvailability } from '~/lib/api'
import { getDefaultAvailability } from './service-availability-responses'
import i18next from '~/i18n'

const getNextTimeToRun = estimate => {
  const seconds = estimate?.prices?.[0]?.expirationTime
  return seconds ? (seconds - 60) * 1000 : false
}


/**
 * Component that fetches data depending on the Formik 
 * values for example service availability and price estimates.
 */
export default function Fetcher() {
  const estimateTimeout = useRef()

  const { values, setFieldValue, setFieldError } = useFormikContext()
  const { user } = useContext(StoreContext)

  const estimatePrice = async () => {
    console.log('estimatePrice')
    setFieldValue('priceIsLoading', true, false)
    try {
      const priceEstimate = await fetchPriceEstimate(values)
      console.log('Estimate:', priceEstimate)
      setFieldValue('priceEstimate', priceEstimate, false)
      setFieldValue('priceToken', priceEstimate.priceToken, false)
      const ms = getNextTimeToRun(priceEstimate)
      if (ms) {
        console.log(`Next run in ${ms} milliseconds`)
        estimateTimeout.current = setTimeout(estimatePrice, ms)
      }
    }
    catch(error) {
      if (error.name === 'AbortError') {
        return
      }
      else if (error?.response?.error === 'FIXED_PRICE_FOR_DESTINATION_NOT_FOUND' 
        || error?.response?.error === 'FIXED_PRICE_FOR_ORIGIN_NOT_FOUND') {
        console.error(error.response)
        const message = i18next.language === 'sv' ? 'Tyvärr ej tillgängligt' : 'Not available'
        setFieldError('fixedPrice', message) // TODO: This error should be fetched from the JSON
        setFieldValue('fixedPrice', false, false)
      }
      else {
        console.error(error.response)
        if (error.status === 500) {
          showErrorModal(i18next.t('error-no-estimate'))
        }
        setFieldError('fixedPrice', error?.response?.message)
        setFieldValue('fixedPrice', false, false)
      }
    }
    setFieldValue('priceIsLoading', false, false)
  }

  const getAutoValues = (availability, estimate = null) => {
    // Check fixed price / taximeter availability
    let fixedPrice = null
    const fixedPriceAvailable = availability.priceAndPaymentRules?.priceTypes?.find(item => item.type === 'FIXED') !== undefined
    const taxiMeterAvailable = availability.priceAndPaymentRules?.priceTypes?.find(item => item.type === 'TAXIMETER') !== undefined
    const priceAvailable = serviceType => {
      return estimate.prices?.find(item => item.serviceType === serviceType) !== undefined
    }

    if (fixedPriceAvailable && !taxiMeterAvailable) fixedPrice = true
    else if (!fixedPriceAvailable && taxiMeterAvailable) fixedPrice = false

    if (availability?.availabilities?.length > 0) {
      const a = availability.availabilities.find(item => item.serviceType === values.serviceType)
      // Check if price is available
      if (a !== undefined && a.availableAt === 'AS_REQUESTED' && estimate) {
        if (fixedPriceAvailable && !priceAvailable(a.serviceType)) fixedPrice = false
        return { pickupTime: null, serviceType: null, fixedPrice }
      }
      // Set pickupTime autoValue to time for matching service type
      if (a !== undefined && a.availableAt === 'DELAYED') {
        if (estimate && fixedPriceAvailable && !priceAvailable(a.serviceType)) fixedPrice = false
        return { pickupTime: a.pickupTime, serviceType: null, fixedPrice }
      }
      // If the selected service type is NOT_AVAILABLE, select the first available
      if (a === undefined || a.availableAt === 'NOT_AVAILABLE') {
        const b = availability.availabilities.find(item => item.availableAt === 'AS_REQUESTED' || item.availableAt === 'DELAYED')
        if (b !== undefined) {
          if (estimate && fixedPriceAvailable && !priceAvailable(b.serviceType)) fixedPrice = false
          return { pickupTime: b.pickupTime, serviceType: b.serviceType, fixedPrice }
        }
      }
    }
    return { pickupTime: null, serviceType: null, fixedPrice }
  }

  // const fetchAvailability = () => {
  //   const key = values.pickupTime === '' ? 'availability.direct' : 'availability.prebook'
  //   setFieldValue('availabilityIsLoading', true, false)
  //   fetchServiceAvailability(values, user)
  //     .then((response) => {
  //       console.log(key, response)

  //       // Sort and filter the availabilities
  //       if (response?.availabilities?.length > 0) {
  //         const order = ['taxi', 'largeCar', 'business']
  //         response.availabilities = response.availabilities.filter(item => order.includes(item.serviceType))
  //         response.availabilities.sort((a, b) => {
  //           const indexA = order.indexOf(a.serviceType)
  //           const indexB = order.indexOf(b.serviceType)    
  //           if (indexA === -1 && indexB === -1) return 0
  //           else if (indexA === -1 && indexB > -1) return 1
  //           else if (indexA > -1 && indexB === -1) return -1
  //           return indexA - indexB
  //         })
  //       }

  //       setFieldValue(key, response, false)
  //       setFieldValue('availabilityIsLoading', false, false)
  //     })
  //     .catch((error) => {
  //       // NOTE: Any error but AbortError results in using the default values
  //       if (error.name !== 'AbortError') {
  //         console.error(error)
  //         // if (error.status === 500) {
  //           // Return default values depending on region
  //           console.log('We got a status 500 so using default values')
  //           const defaultAvailability = getDefaultAvailability(values.departure.address.region)
  //           console.log(defaultAvailability)
  //           setFieldValue(key, defaultAvailability, false)
  //           setFieldValue('availabilityIsLoading', false, false)
  //         // }
  //         // else {
  //         //   setFieldValue(key, false, false)
  //         //   setFieldValue('availabilityIsLoading', false, false)
  //         // }
  //       }
  //     })
  // }


  // NOTE: This is a combined version of fetch service availability and fetch estimate
  const fetchData = async () => {
    // First we check service availability
    let availability = false
    const key = values.pickupTime === '' ? 'availability.direct' : 'availability.prebook'
    setFieldValue('availabilityIsLoading', true, false)

    try {
      availability = await fetchServiceAvailability(values, user)
      console.log(key, availability)

      // TODO: Needs to handle delivery too
      // Sort and filter the availabilities
      if (availability?.availabilities?.length > 0) {
        const order = values.serviceType === 'delivery' ? ['delivery'] : ['taxi', 'largeCar', 'business']
        availability.availabilities = availability.availabilities.filter(item => order.includes(item.serviceType))
        availability.availabilities.sort((a, b) => {
          const indexA = order.indexOf(a.serviceType)
          const indexB = order.indexOf(b.serviceType)    
          if (indexA === -1 && indexB === -1) return 0
          else if (indexA === -1 && indexB > -1) return 1
          else if (indexA > -1 && indexB === -1) return -1
          return indexA - indexB
        })
      }
    }
    catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error)
        // Return default values depending on region
        console.log('We got a status 500 so using default values')
        availability = getDefaultAvailability(values.departure.address.region)
      }
      else {
        return // NOTE: If we get an AbortError we return early
      }
    }

    // Get auto values
    const autoValues = getAutoValues(availability)

    // Then we try to fetch a price estimate
    const fixedPrice = autoValues?.fixedPrice !== null ? autoValues?.fixedPrice : values.fixedPrice
    if (fixedPrice && values.departure?.address && values.destination?.address && isNaN(values.bookingId)) {
      await estimatePrice()
    }

    setFieldValue(key, availability, false)
    setFieldValue('availabilityIsLoading', false, false)
  }



  const getPriceType = () => {
    const key = values.pickupTime === '' ? 'direct' : 'prebook'
    if (values.availability[key] !== false) {
      const fixedPrice = values.autoValues.fixedPrice !== null ? values.autoValues.fixedPrice : values.fixedPrice
      const type = fixedPrice ? 'FIXED' : 'TAXIMETER'
      return values.availability[key]?.priceAndPaymentRules?.priceTypes?.find(item => item.type === type)
    }
  }

  // if (values.serviceType !== 'delivery') {
    useEffect(() => {
      // NOTE: When fetching service availability we set auto values depending on what's available for selected service type etc.
      // These are used to override user selected values instead of overwriting them. For example:
      // - The user has selected to do a direct booking of a taxi but the service returns a 45 min delay
      // - We then set the pickupTime auto value to delayed pickup time and use that to show info in the booking summary
      // - If the user navigates back and selects an address that does not have a delay the auto value is removed and the pickup time 
      //   is shown in the booking summary as normal (ie. direct)
      const key = values.pickupTime === '' ? 'direct' : 'prebook'
      if (values.availability[key] !== false) {
        let autoValues = getAutoValues(values.availability[key], values.priceEstimate)
        if (autoValues !== undefined) {
          if (values.destination?.address === undefined) {
            console.log('No destination. Disable fixed price')
            autoValues = { ...autoValues, fixedPrice: false }
          }
          console.log('We got autoValues:', autoValues)
          setFieldValue('autoValues', autoValues, false)
        }
      }
    }, [values.availability, values.serviceType, values.priceEstimate])

    useEffect(() => {
      const priceType = getPriceType()
      if (priceType !== undefined) {
        console.log('We have a priceType:', priceType)
        setFieldValue('paymentMethods', priceType.paymentMethods, false)

        // Default to PayInCar if selected payment type is not available
        if (values.account !== false && values.payment !== 'PayInCar') {
          let isAvailable = false
          if ((values.account.type === 'creditcard' && priceType.paymentMethods.includes('CreditCard'))
            || (values.account.type === 'TCA' && priceType.paymentMethods.includes('TravelClearingAccount'))
            || (values.account.type === 'TSAB' && priceType.paymentMethods.includes('TSABAccount'))) {
            isAvailable = true
          }
          if (!isAvailable) {
            console.log('Payment method not available, defaulting to PayInCar')
            setFieldValue('payment', 'PayInCar', false)
          }
        }
      }
    }, [values.availability, values.fixedPrice, values.autoValues.fixedPrice])
  // }

  useEffect(() => {
    const fixedPrice = values.autoValues.fixedPrice !== null ? values.autoValues.fixedPrice : values.fixedPrice
    if (fixedPrice && values.departure?.address && values.destination?.address) {
      if (isNaN(values.bookingId)) {
        estimatePrice()
      }
    }
    else {
      clearTimeout(estimateTimeout.current)
      if (values.priceEstimate) {
        setFieldValue('priceEstimate', '', false)
        setFieldValue('priceToken', '', false)
      }
    }
    return () => {
      clearTimeout(estimateTimeout.current)
    }
  }, [values.fixedPrice, values.vouchers])

  useEffect(() => {
    // if (values.serviceType !== 'delivery') {
      if (values.departure?.address) {
        console.log('Fetch availability')
        // fetchAvailability()
        fetchData()
      }
      else {
        console.log('No departure, clear availability and estimate')
        setFieldValue('availability', { direct: false, prebook: false }, false)
        setFieldValue('autoValues', { pickupTime: null, serviceType: null, fixedPrice: null }, false)
        if (values.priceEstimate) {
          setFieldValue('priceEstimate', '', false)
          setFieldValue('priceToken', '', false)
        }
      }
    // }
  }, [values.departure, values.destination, values.intermediateStopLocations, values.pickupTime, values.account, user?.phone])


  useEffect(() => {
    if (!isNaN(values.bookingId)) {
      clearTimeout(estimateTimeout.current)
    }
  }, [values.bookingId])

  return null
}
