import classnames from 'classnames/bind'
import * as styles from './index.module.css';
import * as MotionElement from '~/components/ui/MotionElement'

const cx = classnames.bind(styles)

export default function FormError({ children, noMarginTop, useMotion = true }) {
  const Element = useMotion ? MotionElement.div : "div"
  return (
    <Element className={cx(styles.error, { noMarginTop })}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" className={styles.icon}>
        <path fillRule="evenodd" clipRule="evenodd" fill="#E30613" d="M7.5,13.5c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6
          C1.5,10.8,4.2,13.5,7.5,13.5z M7.5,14.5c3.9,0,7-3.1,7-7c0-3.9-3.1-7-7-7c-3.9,0-7,3.1-7,7C0.5,11.4,3.6,14.5,7.5,14.5z"/>
        <path fill="#E30613" d="M7.5,3.8L7.5,3.8c0.4,0,0.8,0.3,0.8,0.8l0,0c0,0.4-0.3,0.8-0.8,0.8l0,0c-0.4,0-0.8-0.3-0.8-0.8l0,0
          C6.8,4.1,7.1,3.8,7.5,3.8z"/>
        <path fill="#E30613" d="M7.2,6.2h0.6C7.9,6.2,8,6.3,8,6.4v4.6c0,0.1-0.1,0.2-0.2,0.2H7.2c-0.1,0-0.2-0.1-0.2-0.2V6.4
          C7,6.3,7.1,6.2,7.2,6.2z"/>
      </svg>
      <span className={styles.text}>{children}</span>
    </Element>
  )
};

