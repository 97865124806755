.locations {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.addLocationForm {
  margin-top: var(--spacing);
}

.addLocationButton {
  margin-top: var(--spacing);
  font-weight: 500;

  &:focus-visible {
    text-decoration: underline;
  }

  &:before {
    display: inline-block;
    content: '+';
    margin-right: 5px;
  }

  &:disabled {
    cursor: default;
  }
}

.text {
  font-size: 13px;
  color: var(--color-black-50);
  margin: calc(var(--spacing) * 2) 0 0;
}

.row {
  position: relative;
  display: flex;
  gap: var(--spacing);
}

.dragging {
  z-index: 2 !important;
}

.field {
  position: relative;
  width: 100%;
  z-index: 1;
}

.dragHandle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 48px;
  height: 56px;
  border-radius: var(--border-radius);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 100%);
  user-select: none;
  opacity: 0.1;
  pointer-events: none;
  z-index: 3;

  &.enabled {
    cursor: grab;
    opacity: 1;
    pointer-events: auto;

    &:hover svg {
      fill-opacity: 1;
    }
  }
}

.removeButton {
  width: 20px;
  height: 56px;

  & .removeBackground {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
  }

  & svg {
    transform: rotate(45deg);
  }

  &:hover .removeBackground {
    background: var(--color-yellow);
    &> svg {
      fill-opacity: 1;
    }
  }
}
