import { Icon } from '~/components'
import * as styles from './index.module.css'

export default function ChangeButton({ onClick }) {
  return (
    <button type="button" onClick={onClick} className={styles.button}>
      <Icon id="change" />
    </button>
  )
}
