import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function NumberStepper({ value, min, max, onChange, className }) {
  const decrease = () => {
    if (value > min) onChange(value - 1)
  }

  const increase = () => {
    if (value < max) onChange(value + 1)
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <button className={styles.button} onClick={decrease}>-</button>
      {value}
      <button className={styles.button} onClick={increase}>+</button>
    </div>
  )
}

