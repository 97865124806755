.options {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.option {
  color: black;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 12px;
  border-radius: var(--border-radius);
  padding: 4px 6px;
}

.showStops {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 18px;
    left: 10px;
    width: 1px;
    border-left: 1px dotted #A7A8A9;
    height: calc(100% - 45px);
  }

  & .departure, & .destination {
    position: relative;
  }
}

.alignStart {
  align-items: start;
}

.hasStops {
  padding-left: 30px;
}

.stops {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
}

.stop {
  position: relative;
  font-size: 15px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 4px;
    left: -23px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #A7A8A9;
  }
}

.charIcon {
  left: 0;
}
