import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import { useContext, useState } from 'react'
import { ButtonDrawer, InputField } from '~/components'
import { StoreContext } from '~/store'
import { addAccount } from '~/lib/api'
import { sleep } from '~/utils'
import { useTranslation } from 'react-i18next'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function AddAccount({ onComplete }) {
  const { t } = useTranslation()
  const { user, refreshUser } = useContext(StoreContext)
  const [message, setMessage] = useState()

  const initialValues = {
    accountNumber: '',
    accountPassword: '',
  }

  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={Yup.object({
        accountNumber: Yup.string().required(t('validation-need-account-number')),
        accountPassword: Yup.string().required(t('validation-need-account-password')),
      })}
      onSubmit={async (values) => {
        setMessage('')
        await sleep(500)
        addAccount(user.userId, values.accountNumber, values.accountPassword).then((response) => {
          console.log(response)
          refreshUser()
          if (onComplete) onComplete()
        })
        .catch((error) => {
          console.error(error)
          setMessage(error?.response?.message)
        })
      }}
    >
      {({ errors, submitForm, isSubmitting }) => (
        <>
          <div className={styles.wrapper}>
            <div className={styles.title}>{t('header-add-account')}</div>
            <Field name="accountNumber" placeholder={t('placeholder-account-number')} component={InputField} alternateBackground />
            <Field name="accountPassword" type="password" placeholder={t('placeholder-account-password')} component={InputField} alternateBackground />
            {message && <div className={styles.message}>{message}</div>}
          </div>
          <ButtonDrawer buttons={[{ func: submitForm, title: t('button-save'), isLoading: isSubmitting }]} isLoading={isSubmitting} />
        </>
      )}
    </Formik>
  )
}

