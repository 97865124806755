import { useRef } from 'react'
import { useFormikContext, Field } from 'formik'
import classnames from 'classnames/bind'
import { FieldWrapper, TitleInfo } from '~/components'
import { formatTime } from '~/utils/format'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export default function CarRadioField({ field, form: { setFieldValue }, title, info, /*eta*/availability, price, labelRef, ...props }) {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  let isChecked = field.checked

  // Override state with autoValues
  if (values.autoValues.serviceType) {
    isChecked = values.autoValues.serviceType === field.value
  }

  const isDisabled = props.disabled
  const clickRef = useRef()

  const onKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const onKeyUp = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
      setFieldValue(field.name, field.value)
      if (props.onClick) {
        props.onClick()
      }
    }
  }

  const wrapperClick = () => {
    (labelRef || clickRef)?.current?.click()
  }

  return (
    <FieldWrapper onClick={wrapperClick} isDisabled={isDisabled} alternateBackground responsive>
      <div className={styles.wrapper}>
      <img src={`/app/themes/taxi-stockholm/static/img/car-icons/${field.value}.png`} className={styles.icon} />
      <TitleInfo title={title} info={info}>
        {/*eta && <span className={styles.eta}>{t('approx-min', { minutes: eta })}</span>*/}
        {availability?.availableAt === 'AS_REQUESTED' && availability?.expectedWaitTime && 
          <div className={styles.pill}>{t('approx-min', { minutes: availability.expectedWaitTime })}</div>}
        {availability?.availableAt === 'DELAYED' && !isDisabled && 
          <div className={cx(styles.pill, { yellow: true })}>Endast förbokning {formatTime(new Date(availability.pickupTime))}</div>}
        {isDisabled && 
          <div className={styles.pill}>Ej tillgänglig</div>}
      </TitleInfo>
      </div>
      <div className={styles.right}>
        {price && <div className={styles.price}>{price}</div>}
        <label ref={labelRef || clickRef} className={cx(styles.label, { isChecked })} tabIndex="0" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
          <Field 
            type="radio" 
            as="input" 
            className={styles.field} 
            name={field.name} 
            onChange={field.onChange} 
            onBlur={field.onBlur} 
            {...props} 
          />
        </label>
      </div>
    </FieldWrapper>
  )
}
