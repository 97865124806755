import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function Icon({ id, className }) {
  switch (id) {
    case 'change':
      return (
        <svg width="18" height="5" viewBox="0 0 18 5" className={cx(styles.svg, className)} xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="2.5" r="2" />
          <circle cx="9" cy="2.5" r="2" />
          <circle cx="2" cy="2.5" r="2" />
        </svg>
      )
    case 'drag':
      return (
        <svg width="16" height="10" fill="black" fillOpacity="0.5" className={cx(className)} viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 4h16v2H0zm0-4h16v2H0zm0 8h16v2H0z"/>
        </svg>
      )
    case 'plus':
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="black" fillOpacity="0.5" className={cx(className)} xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9 11V15H11V11H15V9H11V5H9V9H5V11H9Z" />
        </svg>
      )
    case 'white-minus':
      return (
        <svg width="10" height="10" viewBox="0 0 10 2" fill="none" stroke="#FFF" strokeWidth="2" className={cx(className)} xmlns="http://www.w3.org/2000/svg">
          <path d="M0 1h10"/>
        </svg>
      )
    case 'white-plus':
      return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" stroke="#FFF" strokeWidth="2" className={cx(className)} xmlns="http://www.w3.org/2000/svg">
          <path d="M5 0v10M0 5h10"/>
        </svg>
      )
    default: 
      return null
}

