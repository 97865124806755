import { useContext, useEffect } from 'react'
import { useFormikContext, Field } from 'formik'
import * as Yup from 'yup'
import { emitCustomEvent } from 'react-custom-events'
import classnames from 'classnames/bind'
import { LocationSearch, ButtonDrawer, FocusElement, FormContent } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import * as styles from './index.module.css'
import * as common from '~/config/common.module.css'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(common)

const SelectLocations = () => {
  const { t } = useTranslation()
  const { next } = useContext(StepperContext)
  const { values, setFieldValue } = useFormikContext()
  const wrappedNext = wrapWithValidation(next, useFormikContext())

  const handleFocus = (event, location) => {
    if (location?.address) {
      emitCustomEvent('ZOOM_LOCATION', location)
    }
    // Disable cover widget
    APP.trigger(APP.events.BOOKING_MODULE_DISABLE_HIDE_MAP)
  }

  const gotoTime = () => {
    wrappedNext('time')
  }

  const gotoWhat = () => {
    setFieldValue('pickupTime', '')
    wrappedNext('what')
  }

  const buttons = [
    { func: gotoTime, title: t('button-prebook-delivery') },
    { func: gotoWhat, title: t('button-book-delivery-now') },
  ]

  useEffect(() => {
    APP.trigger(APP.events.BOOKING_MODULE_ENABLE_HIDE_MAP)
  }, [])

  return (
    <motion.div layout transition={{duration:.18}} className={cx(common.widgetWrapper, { tabsLeft: false })}>
      <FormContent>
        <FocusElement />
        <div className={styles.locations}>
          <Field name="departure" fieldIndex={0} placeholder={t('placeholder-pickup-address')} component={LocationSearch} onFocus={(e) => handleFocus(e, values.departure)} useLocation={true} />
          <Field name="destination" fieldIndex={1} placeholder={t('placeholder-delivery-address')} component={LocationSearch} onFocus={(e) => handleFocus(e, values.destination)} />
        </div>
        <div className={styles.text}>{t('booking-login-info')}</div>
      </FormContent>
      <ButtonDrawer buttons={buttons} />
    </motion.div>
  )
}

SelectLocations.validationSchema = Yup.object({
  // TODO: This does not work when loading JSON from the server... 
  departure: Yup.object().nullable().required(i18next.t('validation-pickup-address-required')),
  destination: Yup.object().nullable().required(i18next.t('validation-delivery-address-required')),
})

export default SelectLocations
