import jwt_decode from 'jwt-decode'
import i18next from '~/i18n'

const { PAYMENTIQ_ENVIRONMENT, PAYMENTIQ_API_URL, MERCHANT_ID } = process.env

// console.log('PAYMENTIQ_ENVIRONMENT:', PAYMENTIQ_ENVIRONMENT)
// console.log('PAYMENTIQ_API_URL:', PAYMENTIQ_API_URL)
// console.log('MERCHANT_ID:', MERCHANT_ID)
// console.log(i18next.language)
const locale = i18next.language === 'sv' ? 'sv_SE' : 'en_GB'


async function api(endPoint, body = null, method = 'GET') {
  const headers = {
    'Content-Type': 'application/json',
  }

  let query = endPoint.indexOf('?') === -1 ? '?' : '&'
  query += 'locale=' + locale

  const res = await fetch(`${PAYMENTIQ_API_URL}${endPoint}${query}`, {
    method,
    headers,
    body,
  })
  .catch((error) => {
    console.error(error)
  })

  // console.log(res)
  const json = await res.json()

  // TODO: Implement error handling

  // Check for API errors
  // if (res.status !== 200) {
  //   if (json?.error) json.message = getErrorMessage(endPoint, json.error)

  //   if (res.status === 401 && json?.error === 'Unauthorized') {
  //     emitCustomEvent('UNAUTHORIZED')
  //   }

  //   throw new ApiError(`${json.error} ${json.message}`, json)
  // }

  return json
}


export async function getUserAccounts(userId, sessionId) {
  const res = await api(`/user/account/${MERCHANT_ID}/${userId}?sessionId=${sessionId}`)
  return res
}

export async function deletePaymentAccount(userId, accountId, sessionId) {
  const res = await api(`/user/account/${MERCHANT_ID}/${userId}/${accountId}?sessionId=${sessionId}`, null, 'DELETE')
  return res
}

export async function processPayment(userId, accountId, sessionId) {
  const decoded = jwt_decode(sessionId)
  const redirectBase = `${location.origin}/payment?status=`
  const req = {
    sessionId: `${sessionId}`,
    userId: userId,
    merchantId: `${MERCHANT_ID}`,
    amount: decoded.transactionAmount,
    accountId: `${accountId}`,
    attributes: {
      paymentToken: `${sessionId}`,
      userId: userId,
      successUrl: `${redirectBase}success`,
      failureUrl: `${redirectBase}failure`,
      pendingUrl: `${redirectBase}pending`,
      cancelUrl: `${redirectBase}cancel`,
    }
  }
  const res = await api(`/creditcard/deposit/process`, JSON.stringify(req), 'POST')
  return res
}
