.locations {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);

  & > div {
    position: relative;
    z-index: 1;
  }
}

.text {
  font-size: 13px;
  color: var(--color-black-50);
  margin: calc(var(--spacing) * 2) 0 0;
}
