export const getDefaultAvailability = region => {
  const availability = (region === 'Stockholm') ? stockholm : partner
  // NOTE: Add a timestamp to trigger the useEffect that sets auto values
  return { ...availability, timestamp: Date.now() }
}

const stockholm = {
  availabilities: [
    {
      serviceType: 'taxi',
      availableAt: 'AS_REQUESTED',
      vehicleOptions: [
        'pet',
        'boosterSeat1',
        'boosterSeat2',
      ]
    },
    {
      serviceType: 'largeCar',
      availableAt: 'NOT_AVAILABLE'
    },
    {
      serviceType: 'business',
      availableAt: 'NOT_AVAILABLE'
    }
  ],
  priceAndPaymentRules: {
    priceTypes: [
      {
        type: 'TAXIMETER',
        paymentMethods: [
          'PayInCar',
          'TSABAccount'
        ]
      },
      {
        type: 'FIXED',
        paymentMethods: [
          'PayInCar',
          'TravelClearingAccount',
          'TSABAccount',
          'CreditCard'
        ]
      }
    ]
  },
  tripRules: {
    maxAllowedIntermediateStops: 4
  }
}

const partner = {
  availabilities: [
    {
      serviceType: 'taxi',
      availableAt: 'AS_REQUESTED',
      vehicleOptions: []
    },
    {
      serviceType: 'largeCar',
      availableAt: 'NOT_AVAILABLE'
    }
  ],
  priceAndPaymentRules: {
    priceTypes: [
      {
        type: 'FIXED',
        paymentMethods: [
          'PayInCar',
          'TravelClearingAccount',
          'TSABAccount',
          'CreditCard'
        ]
      }
    ]
  },
  tripRules: {
    maxAllowedIntermediateStops: 0
  }
}
