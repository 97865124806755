import { useState, useEffect } from 'react'
import i18next from '~/i18n'
import { motion } from 'framer-motion'
import { getStatusMessages } from '~/lib/api'
import * as styles from './index.module.css'

export default function StatusMessages() {

  const [messages, setMessages] = useState([])

  const getMessage = item => {
    return item?.messages[i18next.language]
  }

  useEffect(() => {
    getStatusMessages().then(response => {
      setMessages(response)
    })
    .catch(error => {
      console.error(error)
    })
  }, [])

  return (
    <>
      {messages.map(item => {
        return (
          <motion.div 
            key={item.id} 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            className={styles.alertItem}
          >
            <div className={styles.info}></div>
            <p>{getMessage(item)}</p>
          </motion.div>
        )
      })}
    </>
  )
}

