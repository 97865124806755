.date { 
  position: relative;
  display: block;
  height: 56px;
  line-height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  font-size: 16px;
  border-radius: var(--border-radius);
  background-color: white;

  &::first-letter {
    text-transform: capitalize;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' y='5' width='18' height='3' fill='black'/%3E%3Crect x='6' y='3' width='2' height='3' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 9H21V21H3V9ZM6 12H8V14H6V12ZM8 16H6V18H8V16ZM11 16H13V18H11V16ZM18 16H16V18H18V16ZM11 12H13V14H11V12ZM18 12H16V14H18V12Z' fill='black'/%3E%3Crect x='16' y='3' width='2' height='3' fill='black'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  & .input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (--lg) {
    display: none;
  }
}

@media (--lg-down) {
  :global(.react-datepicker) {
    display: none !important;
  }
}


/*-------------------------------------------
// Customize the date picker
//-----------------------------------------*/

:global(.react-datepicker) {
  border: none !important;
  border-radius: none !important;
}

:global(.react-datepicker__header) {
  background-color: white !important;
  border: none !important;
}

:global(.react-datepicker__current-month) {
  text-transform: capitalize;
}

:global(.react-datepicker__day--selected) {
  color: black !important;
  background-color: var(--color-yellow) !important;
  border-radius: 50% !important;
}

:global(.react-datepicker__day--keyboard-selected) {
  color: black !important;
  background-color: var(--color-cool-gray) !important;
  border-radius: 50% !important;
}
