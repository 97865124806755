import { useContext, useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import { StoreContext } from '~/store'
import { default as Booking } from '../../Booking'
import { getHistoricBookings } from '~/lib/api'
import Header from '../Header'
import Menu from '../Menu'
import { useTranslation } from 'react-i18next'

export default function BookingsView() {
  const { t } = useTranslation()
  const { user, bookings, getActiveBookings } = useContext(StoreContext)

  const [historicBookings, setHistoricBookings] = useState()
  const [pagedBookings, setPagedBookings] = useState()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()
  const [skip, setSkip] = useState(0)
  const limit = 10

  const headerRef = useRef()
  const COPY = window.COPY

  const prevPage = () => {
    setSkip(skip - limit)
    if (headerRef.current) headerRef.current.scrollIntoView()
  }

  const nextPage = () => {
    setSkip(skip + limit)
    if (headerRef.current) headerRef.current.scrollIntoView()
  }

  useEffect(() => {
    if (historicBookings?.length > 0) {
      setPagedBookings(historicBookings.slice(skip, skip + limit))
    }
  }, [historicBookings, skip])

  useEffect(() => {
    if (user?.userId) {
      setLoading(true)
      getHistoricBookings(user.userId).then((response) => {
        setHistoricBookings(response)
      })
      .catch((error) => {
        console.error(error.response)
        setMessage(error?.response?.message)
      })
      .finally(() => {
        setLoading(false)
      })
    }
  }, [user?.userId])

  return (
    <section className="orders-view">
      <Header />
      <div className="my-pages-container">

        <Menu />
        <div className="my-pages-content">
          <div className="orders-content">
            <h2 className="active-header">{COPY.my_pages.my_orders_active}</h2>
            <ul className="active-orders">
            {bookings && bookings.map(booking => (
              <Booking key={booking.id} booking={booking} onCancel={getActiveBookings} showBorder />
            ))}
            {bookings && bookings.length === 0 && <li>{COPY.my_pages.no_active_orders}</li>}
            </ul>
            <h2 ref={headerRef} className={classnames('historic-header', { loading })}>{COPY.my_pages.my_orders_historic}</h2>
            <ul className="historic-orders">
            {pagedBookings && pagedBookings.map(booking => (
              <Booking key={booking.id} booking={booking} showBorder showDate />
            ))}
            {pagedBookings && pagedBookings.length === 0 && <li>{COPY.my_pages.no_historic_orders}</li>}
            {message && <li>{message}</li>}
            </ul>

            <div className={classnames('buttons', { show: true })}>
              <button id="prev-button" className="btn dynamic" disabled={skip === 0} onClick={prevPage}>{COPY.my_pages.prev_page}</button>
              <button id="next-button" className="btn dynamic" disabled={!historicBookings || historicBookings?.length <= skip + limit} onClick={nextPage}>{COPY.my_pages.next_page}</button>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}
