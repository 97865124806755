import { Switch } from '~/components'

export default function SimpleSwitch({ field, form, title, reverseLayout, alternateBackground, ...props }) {
  const isChecked = field.value
  return (
    <Switch 
      isChecked={isChecked}
      name={field.name} 
      form={form}
      onChange={field.onChange} 
      onBlur={field.onBlur} 
      {...props} 
    />
  )
}
