import { useRef } from 'react'
import { FieldWrapper, TitleInfo, Switch } from '~/components'
import * as styles from './index.module.css'

export default function CheckboxField({ field, form, title, info, reverseLayout, alternateBackground, larger, children, innerRef, ...props }) {
  const isChecked = Array.isArray(field.value) ? field.value.includes(props?.value) : field.value
  const isDisabled = props.disabled
  const clickRef = useRef()

  const wrapperClick = e => {
    if (e.clientX !== 0 && e.clientY !== 0) {
      (innerRef || clickRef)?.current?.click()
    }
  }

  return (
    <FieldWrapper reverseLayout={reverseLayout} alternateBackground={alternateBackground} isDisabled={isDisabled} larger={larger} onClick={wrapperClick}>
      <div className={styles.content}>
        <TitleInfo title={title} info={info} />
        {children}
      </div>
      <Switch 
        innerRef={innerRef || clickRef}
        isChecked={isChecked}
        name={field.name} 
        field={field}
        form={form}
        onChange={field.onChange} 
        onBlur={field.onBlur} 
        {...props} 
      />
    </FieldWrapper>
  )
}
