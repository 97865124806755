import './i18n'
import { createRoot } from 'react-dom/client'
import Store from './store'
import { 
  ActiveBookings,
  BookingModuleWrapper,
  PaymentModal,
  LoginModule,
  LoginStatus,
  Map,
  StatusMessages,
  ProfileModule,
  DebugPanel 
} from '~/components'
import { qs, qsa } from '~/utils'

const MODERN = {
  allowHideMap: true // This variable is true for all booking module views except locations
}

const apps = [
  // Booking module
  ...qsa('.booking-module-modern-form').map(el => {
    return { el, component: BookingModuleWrapper }
  }),
  // Cashier
  ...qsa('#payment-modal').map(el => {
    return { el, component: PaymentModal }
  }),
  // Profile module
  ...qsa('#profile-module').map(el => {
    return { el, component: ProfileModule }
  }),
  // Login module
  { el: qs('#login-module'), component: LoginModule },
  // Login status
  ...qsa('.login-status-modern').map(el => {
    return { el, component: LoginStatus }
  }),
  // Map
  ...qsa('#map-modern').map(el => {
    return { el, component: Map }
  }),
  // Status messages
  ...qsa('#status-messages').map(el => {
    return { el, component: StatusMessages }
  }),
  // Active orders
  { 
    el: qs('#active-bookings'), 
    component: ActiveBookings, 
    props: { 
      slideableContent: qs('.slideable-content'),
      header: qs('.header') 
    } 
  }
]

// Add debug panel if in dev
if (process.env.NODE_ENV !== 'production') {
  apps.push({ el: qs('#debug-panel'), component: DebugPanel })
}

const rootElement = createRoot(document.getElementById('reactStore'))
rootElement.render(<Store apps={apps} />)

const bmw = qs('.booking-module-modern')
if (bmw) {
  const hasCover = bmw.classList.contains('has-cover')
  if (hasCover) {
    APP.on(APP.events.BOOKING_MODULE_HIDE_COVER, () => bmw.classList.remove('cover-visible'))
    APP.on(APP.events.BOOKING_MODULE_SHOW_COVER, () => bmw.classList.add('cover-visible'))
    APP.on(APP.events.BOOKING_MODULE_DISABLE_HIDE_MAP, () => {
      MODERN.allowHideMap = false
    })
    APP.on(APP.events.BOOKING_MODULE_ENABLE_HIDE_MAP, () => {
      MODERN.allowHideMap = true
    })

    $('#map-modern').on('click', () => {
      if (MODERN.allowHideMap) {
        APP.trigger(APP.events.BOOKING_MODULE_SHOW_COVER)
        APP.trigger(APP.events.BOOKING_MODULE_CONTRACTED)
      }
    })

    $('.booking-module-modern-form').on('click', () => {
      APP.trigger(APP.events.BOOKING_MODULE_HIDE_COVER)
    })
  }
}
