import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function CloseButton({ innerRef, onClick, small }) {
  return (
    <div ref={innerRef} className={styles.wrapper}>
      <button type="button" onClick={onClick} className={cx(styles.button, { isLarge: !small })}>
        <svg className={cx(styles.svg, { isLarge: !small })} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle className={styles.bg} cx="24" cy="24" r="24" />
          <path className={styles.fg} fillRule="evenodd" clipRule="evenodd" d="M24.1424 25.4142L26.9708 28.2426L28.385 26.8284L25.5566 24L28.385 21.1716L26.9708 19.7574L24.1424 22.5858L21.3139 19.7574L19.8997 21.1716L22.7281 24L19.8997 26.8284L21.3139 28.2426L24.1424 25.4142Z" />
        </svg>
      </button>
    </div>
  )
}
