import { useContext, useState } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { addDays, addMinutes, clamp, roundToNearestMinutes, setHours, setMinutes, setSeconds } from 'date-fns'
import { motion, AnimatePresence } from "framer-motion"
import { InputField, CheckboxField, FormContent, FormHeader, ButtonDrawer, FormError, DatePicker, TimePicker, Notifications, BasicModal } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { formatTime } from '~/utils/format'
import { hasAvailability, isBookingAvailable, isBookingAvailableDelayed, isBookingAvailableAsRequested } from '~/utils/data'
import { useDidMountEffect } from '~/utils/hooks'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const getMinDate = () => addMinutes(setSeconds(roundToNearestMinutes(new Date(), { nearestTo: 5 }), 0), 40)
const getMaxDate = () => addDays(setHours(setMinutes(setSeconds(new Date(), 0), 59), 23), 27)

const SelectTime = () => {
  const { t } = useTranslation()
  const { prev, next } = useContext(StepperContext)
  const wrappedNext = wrapWithValidation(next, useFormikContext())

  const { values, setFieldValue } = useFormikContext()
  const departureIsAirport = values.departure?.locationTypes?.includes('AIRPORT')
  const isDirectBookDisabled = hasAvailability(values, 'direct') ? !isBookingAvailable(values, 'direct') : false
  const isBookingDelayed = hasAvailability(values, 'prebook') ? isBookingAvailableDelayed(values, 'prebook') : false
  const isBookingDisabled = hasAvailability(values, 'prebook') ? !isBookingAvailableAsRequested(values, 'prebook') : false
  const [isNextClicked, setIsNextClicked] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState()
  const [modalButtons, setModalButtons] = useState()

  const minDate = getMinDate()
  const maxDate = getMaxDate()
  const clampDate = date => clamp(date, { start: minDate, end: maxDate })

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const goNow = () => {
    setFieldValue('pickupTime', '', false)
    next()
  }

  const goNext = () => {
    if (values.pickupTime === '') {
      setIsNextClicked(true)
      setFieldValue('pickupTime', minDate, false)
    }
    else if (values.availabilityIsLoading) {
      setIsNextClicked(true)
    }
    else {
      wrappedNext()
    }
  }

  const buttons = [
    { func: prev, isBack: true },
    { func: goNow, title: t('button-go-now'), disabled: departureIsAirport || isDirectBookDisabled },
    { 
      func: goNext, 
      title: t('button-continue'), 
      // disabled: (isBookingDisabled && !isBookingDelayed), 
      disabled: isBookingDisabled, 
      isLoading: (isNextClicked || isBookingDisabled) && values.availabilityIsLoading 
    }, 
  ]

  useDidMountEffect(() => {
    if (hasAvailability(values, 'prebook') && !values.availabilityIsLoading) {
      if (isBookingDisabled && !isBookingDelayed) {
        setIsModalOpen(true)
        setModalMessage(t('message-select-other-time'))
        setModalButtons([
          { func: closeModal, title: 'Ok' },
        ])
      }
      else if (isBookingDisabled && isBookingDelayed) {
        const service = values.availability.prebook.availabilities.find(item => item.availableAt === 'DELAYED')
        const time = formatTime(new Date(service.pickupTime))
        setIsModalOpen(true)
        setModalMessage(t('message-select-next-available-time', { time }))
        setModalButtons([
          { func: closeModal, title: t('button-select-other-time') },
          { func: goNext, title: t('button-select-available-time') }
        ])
      }
      else if (isNextClicked) {
        wrappedNext() 
      }
    }
  }, [values.availability, values.availabilityIsLoading])

  return (
      <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <BasicModal 
        title={t('title-fully-booked')}
        message={modalMessage}
        buttons={modalButtons} 
        onClose={closeModal} 
        isOpen={isModalOpen} 
        absolute 
      />
        <Notifications />
        <FormContent>
          <FormHeader>{departureIsAirport ? t('header-when-does-your-flight-land') : t('header-when-do-you-want-to-go')}</FormHeader>
          <MotionElement.div className={common.content}>
            <div>
              <div className={styles.datepicker}>
                <Field name="pickupTime" minDate={minDate} maxDate={maxDate} clampDate={clampDate} component={DatePicker} />
              </div>
              <ErrorMessage name="pickupTime" component={FormError} />
            </div>
            <div className={common.group}>
              <Field name="pickupTime" minDate={minDate} maxDate={maxDate} clampDate={clampDate} component={TimePicker} />
              {departureIsAirport && <>
                <div>
                  <Field name="flightNumber" placeholder={t('placeholder-flight-number')} component={InputField} />
                  <p className={common.subtitle}>{t('info-flight-number')}</p>
                </div>
                <div>
                  <Field name="checkedInBaggage" title={t('title-baggage')} tabIndex="0" reverseLayout component={CheckboxField} />
                  <p className={common.subtitle}>{t('info-baggage')}</p>
                </div>
              </>}
            </div>
          </MotionElement.div>
        </FormContent>
        <ButtonDrawer buttons={buttons} />
      </motion.div>
  )
}

SelectTime.validationSchema = Yup.object({
  pickupTime: Yup.date()
    .min(getMinDate(), i18next.t('validation-date-not-valid'))
    .max(getMaxDate(), i18next.t('validation-date-too-far-ahead'))
    .required(i18next.t('validation-need-date')),
  flightNumber: Yup.string()
    .when('departure', (departure) => {
      return departure?.locationTypes?.includes('AIRPORT') ? Yup.string().required(i18next.t('validation-need-flight-number')) : Yup.string()
    }),
})

export default SelectTime
