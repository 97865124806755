.wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 200%;
  overflow: hidden;
  pointer-events: none;

  &.isOpen {
    & .overlay {
      opacity: 0.25;
      pointer-events: auto;
    }
    & .panel {
      transform: translateY(0);
    }
  }

  @media (--lg) {
    position: absolute;
    border-radius: var(--border-radius-large);
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s var(--easeInOutCirc);

  @media (--md) {
    position: absolute;
    border-radius: var(--border-radius-large);
  }
}

.panel {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  pointer-events: auto;
  transform: translateY(100%);
  transition: transform 0.3s var(--easeInOutCirc);
  overflow: hidden;
  z-index: 1;
} 

.panelContent {
  padding: calc(var(--spacing) * 2);
  overflow: hidden;

  &.noPadding {
    padding: 0;
  }

  @media (--md) {
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: var(--spacing);
  margin-bottom: var(--spacing);
}

.alternateBackground {
  background: var(--color-cool-gray);
}
