import classnames from 'classnames'
import { useContext, useState } from 'react'
import { emitCustomEvent } from 'react-custom-events'
import { StoreContext } from '~/store'
import Header from '../Header'
import Menu from '../Menu'
import { Modal, AddVoucher, AddAccount } from '~/components'
import { deleteAccount, getUserVerificationToken } from '~/lib/api'
import { formatReadableDateOnly } from '~/utils/format'
import { getVoucherValue } from '~/utils/data'
import { deletePaymentAccount } from '~/lib/piq'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

export default function PaymentView() {
  const { t } = useTranslation()
  const { user, paymentAccounts, refreshUser } = useContext(StoreContext)
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)
  const COPY = window.COPY
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false)
  const [isAddVoucherOpen, setIsAddVoucherOpen] = useState(false)
  const hasAccounts = user?.accounts?.length > 0
  const hasCreditCards = paymentAccounts?.length > 0
  const hasVouchers = user?.vouchers?.length > 0
  
  const removeAccount = accountNumber => {
    setLoading(true)
    deleteAccount(user.userId, accountNumber).then((response) => {
      console.log(response)
      setMessage('')
      refreshUser()
    })
    .catch((error) => {
      console.error(error)
      setMessage(error?.response?.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const removePaymentAccount = accountId => {
    setLoading(true)
    getUserVerificationToken(user.userId).then((userVerificationToken) => {
      console.log('userVerificationToken:', userVerificationToken)
      deletePaymentAccount(user.userId, accountId, userVerificationToken).then((response) => {
        console.log(response)
        setMessage('')
        refreshUser()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
    })
    .catch((error) => {
      console.error(error)
      setLoading(false)
    })
  }

  const addCreditCard = () => {
    emitCustomEvent('CASHIER_ADD_CARD', { success: refreshUser })
  }

  const openAddAccount = () => {
    setIsAddAccountOpen(true)
  }

  const closeAddAccount = () => {
    setIsAddAccountOpen(false)
  }

  const openAddVoucher = voucher => {
    setIsAddVoucherOpen(true)
  }

  const closeAddVoucher = () => {
    setIsAddVoucherOpen(false)
  }

  const onDeleteAccount = accountNumber => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      COPY.my_pages.delete_account_confirm, [
        { title: COPY.booking.button_ok, callback: () => removeAccount(accountNumber) },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  const onDeletePaymentAccount = accountId => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      t('info-confirm-remove-card'), [
        { title: COPY.booking.button_ok, callback: () => removePaymentAccount(accountId) },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  return (
    <section className="payment-view">
      {isAddAccountOpen && <Modal onClose={closeAddAccount} isOpen>
        <AddAccount onComplete={closeAddAccount} />
      </Modal>}
      {isAddVoucherOpen && <Modal onClose={closeAddVoucher} isOpen>
        <AddVoucher onComplete={closeAddVoucher} />
      </Modal>}

      <Header />
      <div className="my-pages-container">

        <Menu />
        <div className="my-pages-content account-form">
          <h2>{COPY.my_pages.payment_header}</h2>
          <div className={classnames('my-pages-form', { loading })}>
            <header className="form-header">{t('header-accounts')}</header>
            {!hasAccounts && <div className="account-info">{t('info-no-accounts')}</div>}
            <ul className={classnames('form-group accounts', { show: hasAccounts })}>
              {user?.accounts?.map(item => (
                <li key={item.accountNumber} className={classnames('account-item', styles.item)}>
                  <span className="account-name">{item.accountName}</span>
                  <div className={styles.info}>{item.accountNumber}</div>
                  <button className="delete-account" onClick={() => onDeleteAccount(item.accountNumber)}></button>
                </li>
              ))}
            </ul>
            <div className="form-group with-padding">
              <div className={classnames('message', { show: message })}>{message}</div>
              <button className="btn" onClick={openAddAccount}>{t('button-add-account')}</button>
            </div>
            <header className="form-header">{t('header-cards')}</header>
            {!hasCreditCards && <div className="account-info">{t('info-no-cards')}</div>}
            <ul className={classnames('form-group accounts', { show: hasCreditCards })}>
              {paymentAccounts?.map(item => (
                <li key={item.accountId} className={classnames('account-item card', styles.item)}>
                  <span className="account-name">{item.maskedAccount}</span>
                  <div className={styles.info}>{item.cardType}</div>
                  <button className="delete-account" onClick={() => onDeletePaymentAccount(item.accountId)}></button>
                </li>
              ))}
            </ul>
            <div className="form-group with-padding">
              <button className="btn" onClick={addCreditCard}>{t('button-add-card')}</button>
            </div>
            <header className="form-header">{t('header-vouchers')}</header>
            {!hasVouchers && <div className="account-info">{t('info-no-vouchers')}</div>}
            <ul className={classnames('form-group accounts', { show: hasVouchers })}>
              {user?.vouchers?.map(item => (
                <li key={item.voucherCode} className={classnames('account-item', styles.item)}>
                  <span className="account-name">{item.voucherCode} - {getVoucherValue(item)}</span>
                  <div className={styles.info}>
                    {t('expires-at', { date: formatReadableDateOnly(new Date(item.expiry)) })}
                    {item.valueType == 'PERCENTAGE' && <><br />{t('info-voucher-max', { amount: item.maxPriceToBeDiscounted })}</>}
                  </div>
                </li>
              ))}
            </ul>
            <div className="form-group with-padding">
              <button className="btn" onClick={openAddVoucher}>{t('button-add-voucher')}</button>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}
