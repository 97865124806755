.buttons {
  display: flex;
  justify-content: start;

  @media (--lg) {
    width: 624px;
    margin-left: auto;
    margin-right: initial;
    margin-bottom: -1px; /* NOTE: Fix 1px gap in Safari */
  }
}

.button {
  position: relative;
  padding: 10px 24px;
  color: var(--color-black-50);
  background: #BFC0C2;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  pointer-events: auto;

  font-size: 16px;

  &.active {
    color: var(--color-black);
    background: var(--color-cool-gray);
    z-index: 1;

    &:before, &:after {
      display: none;
    }

    & > .corner.left:before {
      box-shadow: 4px 4px 0 0 var(--color-cool-gray);
    }

    & > .corner.right:before {
      box-shadow: -4px 4px 0 0 var(--color-cool-gray);
    }
  }

  &:before, &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    background: #BFC0C2;
  }

  &:before {
    left: -5px;
  }

  &:after {
    right: -5px;
  }

  &:first-child:before, &:last-child:after {
    display: none;
  }
}

@media (--lg-down) {
  .button {
    width: 50%;
  }
  .button:first-child {
    border-top-left-radius: 0;
  }
  .button:last-child {
    border-top-right-radius: 0;
  }
}

.cornerTabsLeft {
  position: absolute;
  left: 0px;
  bottom: -8px;
  width: 8px;
  height: 8px;
  background: #BFC0C2;
}

.corner {
  position: absolute;
  bottom: 0px;
  width: 8px;
  height: 8px;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
  }

  &.left {
    left: -8px;

    &:before {
      right: 0;
      box-shadow: 4px 4px 0 0 #BFC0C2;
    }
  }

  &.right {
    right: -8px;

    &:before {
      left: 0;
      box-shadow: -4px 4px 0 0 #BFC0C2;
    }
  }
}
