import { useState } from 'react'
import { useLocation, useSearch } from 'wouter'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import { getLocalizedSlug } from '../'
import classnames from 'classnames'

export default function ResetPasswordView() {
  const searchString = useSearch()
  const [location, setLocation] = useLocation()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState(false)

  const initialValues = {
    newPassword: '',
    repeatPassword: '',
  }

  const COPY = window.COPY

  return (
    <Formik 
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={Yup.object({
        newPassword: Yup
          .string()
          .required()
          .min(8),
        repeatPassword: Yup
        .string()
        .required()
        .min(8)
        .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.')
      })}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true)

        const data = {
          token: new URLSearchParams(searchString).get('token'),
          newPassword: values.newPassword
        }

        $.ajax(APP.getAjaxConfig({
          url: CONFIG.api_base_url + '/api/update-password',
          method: 'POST',
          data: data,
          success: () => {
            setLoading(false)
            setSuccess(true)
            setMessage(COPY.my_pages.password_change_success)
            resetForm()
          },
          error: (jqXHR) => {
            setLoading(false)
            setLocation(jqXHR?.status === 410 ? getLocalizedSlug('tokenError') : getLocalizedSlug('unknownError'))
          }
        }))
      }}
    >
      {({ errors, submitForm }) => (
        <section className={classnames('not-logged-in-view reset', { loading, success })}>
          <div className="form-container">
            <h2>{COPY.my_pages.reset_password}</h2>
            <p className="body">{COPY.my_pages.reset_password_instructions}</p>
            <div className="form-row hideable">
              <Field name="newPassword" type="password" className={classnames('text-input', { 'has-error': errors?.newPassword })} placeholder={`${COPY.my_pages.label_new_password} ${COPY.my_pages.min_chars_label}`} />
            </div>
            <div className="form-row hideable">
              <Field name="repeatPassword" type="password" className={classnames('text-input', { 'has-error': errors?.repeatPassword })} placeholder={COPY.my_pages.label_repeat_password} />
            </div>
            <div className="form-row hideable">
              <button id="forgot-button" className="btn black fw" onClick={submitForm}>{COPY.my_pages.change_password_button}</button>
            </div>
            <div className="form-row message"></div>
            {message && <div className="success-message">{message}</div>}
          </div>
        </section>
      )}
    </Formik>
  )
}
