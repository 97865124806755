.wrapper {
  position: relative;
  width: 624px;
}

.overlay {
  position: fixed;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: calc(var(--spacing) * 2);
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s var(--easeOutCirc), visibility 0ms linear 0.5s;
  z-index: 900;
  pointer-events: none;

  &.isOpen {
    background: rgba(0, 0, 0, 0.8);
    transition: background 0.5s var(--easeOutCirc), visibility 0s linear 0s;
    pointer-events: auto;
    visibility: visible;
  }
}
