import { useContext } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import classnames from 'classnames/bind'
import { StoreContext } from '~/store'
import { StepperContext } from '~/components/ui/ViewStepper'
import { getOptions, formatReadableDate } from '~/utils/format'
import { getCarType, getTaxiAndEta, getOptionText, getVoucherValue, hasTaxiAndEta } from '~/utils/data'
import { formatTime } from '~/utils/format'
import { ChangeButton, CharIcon } from '~/components'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(styles)

const formatAddress = address => {
  return address.streetNumber 
    ? `${address.streetName} ${address.streetNumber}${address.entrance ? ' ' + address.entrance : ''}`
    : address.streetName
}

// const formatEta = (eta, serviceType) => {
//   const name = serviceType === 'business' ? 'premium' : serviceType
//   if (eta?.length > 0) {
//     const minutes = eta.find(item => name === item.vehicleType)?.expectedWaitTime 
//     if (minutes === undefined) return null
//     return i18next.t('info-available-in', { minutes })
//   }
// }

const formatAvailability = (availabilities, serviceType) => {
  const availability = availabilities.find(item => serviceType === item.serviceType)
  if (availability?.expectedWaitTime === undefined) return null
  // if (availability?.availableAt === 'DELAYED') return `Endast förbokning ${formatTime(new Date(availability.pickupTime))}`
  if (availability?.availableAt === 'DELAYED') return `Endast förbokning`
  return i18next.t('info-available-in', { minutes: availability?.expectedWaitTime })
}

const renderPayment = (values) => {
  if (values.payment === 'PayInCar') {
    return <div className={common.title}>{i18next.t('title-pay-in-car')}</div>
  }
  else {
    const isCreditCard = values.payment.indexOf('CreditCard') !== -1
    const title = isCreditCard ? values.account.maskedAccount : values.account.accountName
    const subtitle = isCreditCard ? values.account.cardType : values.account.accountNumber
    return (
      <>
        <div className={common.title}>{title}</div>
        <div className={common.subtitle}>{subtitle}</div>
      </>
    )
  }
}

const renderStops = (stops) => {
  return stops.map((item, index) => {
    return (
      <div key={index} className={styles.stop}>
        <div className={common.bold}>{formatAddress(item.address)}</div> 
        <div className={common.subtitle}>{item.address.city}</div>
      </div>
    )
  })
}

const renderPrice = (price) => {
  if (!price) return null
  if (price.finalPrice === price.fullPrice) return `${price.finalPrice}:-`
  else return <>{price.finalPrice}:- <s>{price.fullPrice}:-</s></>
}

const renderVoucherInfo = voucher => {
  return <>{i18next.t('title-voucher')} {voucher.voucherCode} - {getVoucherValue(voucher)}</>
}


export default function BookingSummary({ values, showHow = false, showPayment = false }) {
  const { t } = useTranslation()
  const { bookings } = useContext(StoreContext)
  const { prev } = useContext(StepperContext)

  const booking = bookings?.find(item => item.id === values.bookingId)

  const hasDeparture = values.departure?.address !== undefined
  const hasDestination = values.destination?.address !== undefined
  const hasStops = values.intermediateStopLocations?.length > 0
  const fromAddress = hasDeparture ? formatAddress(values.departure.address) : ''
  const fromCity = hasDeparture ? values.departure.address.city : ''
  const toAddress = hasDestination ? formatAddress(values.destination.address) : ''
  const toCity = hasDestination ? values.destination.address.city : ''

  const taxiAndEta = booking ? getTaxiAndEta(booking) : ''
  const time = values.autoValues.pickupTime || values.pickupTime 
    ? formatReadableDate(values.autoValues.pickupTime || values.pickupTime) 
    : t('label-i-want-to-go-now')
  // const availability = values.eta ? formatEta(values.eta, values.serviceType) : ''

  const fixedPrice = values.autoValues.fixedPrice !== null ? values.autoValues.fixedPrice : values.fixedPrice
  const serviceType = values.autoValues.serviceType !== null ? values.autoValues.serviceType : values.serviceType

  const availability = values.availability?.availabilities ? formatAvailability(values.availability.availabilities, serviceType) : false
  const showAvailability = values.pickupTime === '' && availability && !taxiAndEta
  const price = values?.priceEstimate?.prices?.find(p => p.serviceType === serviceType)
  const showPrice = fixedPrice && price
  const options = getOptions(values)
  const hasBooking = !isNaN(values?.bookingId)

  return (
    <>
      <MotionElement.div className={cx(common.content, styles.alignStart)}>
        <div className={cx(common.group, { showStops: hasStops} )}>
          <div>
            <div className={common.label}>{hasStops ? 'RESVÄG' : t('label-from')}{!hasBooking && <ChangeButton onClick={() => prev('locations')}/>}</div>
            <div className={cx(styles.content, styles.departure, { hasStops })}>
              {hasStops && <CharIcon char="A" className={styles.charIcon} small />}
              <div className={common.title}>{fromAddress}</div>
              <div className={common.subtitle}>{fromCity}</div>
            </div>
          </div>
          {hasStops && <div className={styles.stops}>{renderStops(values.intermediateStopLocations)}</div>}
          {hasDestination && <div>
            {!hasStops && <div className={common.label}>{t('label-to')}{!hasBooking && <ChangeButton onClick={() => prev('locations')}/>}</div>}
            <div className={cx(styles.content, styles.destination, { hasStops })}>
              {hasStops && <CharIcon char={'ABCDEFGHIJKLMNOPQRST'[values.intermediateStopLocations.length + 1]} className={styles.charIcon} small />}
              <div className={common.title}>{toAddress}</div>
              <div className={common.subtitle}>{toCity}</div>
            </div>
          </div>}
        </div>
        <div className={common.group}>
          <div>
            <div className={common.label}>{t('label-when')}{!hasBooking && <ChangeButton onClick={() => prev('time')}/>}</div>
            <div className={styles.content}>
              <div className={cx(common.title, common.capitalize)}>{time}</div>
              {showAvailability && <div className={common.subtitle}>{availability}&nbsp;</div>}
              {hasTaxiAndEta(booking) && taxiAndEta && <div className={common.subtitle}>{taxiAndEta}&nbsp;</div>}
            </div>
          </div>
          {showPayment && <div>
            <div className={common.label}>{t('label-payment')}</div>
            {renderPayment(values)}
            {values.vouchers && <ul className={styles.options}>
            <AnimatePresence>
              {values.voucherObjects.map((item, index) => <motion.li key={index} transition={{duration:.15}} initial={{ height:0, opacity:0 }} animate={{ height:'auto', opacity:1 }} exit={{ height: 0,  opacity:0 }} className={styles.option}>{renderVoucherInfo(item)}</motion.li>)}
              </AnimatePresence>
            </ul>}
          </div>}
          {showHow && <div>
            <div className={common.label}>{t('label-how')}</div>
            <div className={common.title}>{getCarType(serviceType)?.title}</div>
            {showPrice && <div className={common.subtitle}>{t('label-fixed-price')} {renderPrice(price)}</div>}

            {<ul className={styles.options}>
            <AnimatePresence>
              {options.map((value, index) => <motion.li key={index} transition={{duration:.15}} initial={{ height:0, opacity:0 }} animate={{ height:'auto', opacity:1 }} exit={{ height: 0,  opacity:0 }} className={styles.option}>{getOptionText(value)}</motion.li>)}
              </AnimatePresence>
            </ul>}
          </div>}
          {hasBooking && <div>
            <div className={common.label}>{t('label-booking-number')}</div>
            <div className={common.title}>{values.bookingId}</div>
          </div>}
        </div>
      </MotionElement.div>
    </>
  )
}
