.error {
  margin-top: -4px;
  grid-column: 1 / -1;
  padding: 0 calc(var(--spacing) * 2);
  background: white;
}

.icons {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.icon {
  width: 32px;
  height: 32px;
}

.info {
  font-size: 10px;
  grid-column: 1 / -1;
  padding: 0 calc(var(--spacing) * 2);
}
