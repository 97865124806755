import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function SearchIcon({ className, active }) {
  return (
    <svg viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx(styles.icon, className, { active })}>
      <path d="M11.5964 12.0973C12.7207 10.9739 13.4161 9.42161 13.4161 7.7069C13.4161 4.27892 10.6367 1.5 7.20807 1.5C3.77945 1.5 1 4.27892 1 7.7069C1 11.1349 3.77945 13.9138 7.20807 13.9138C8.92166 13.9138 10.4731 13.2196 11.5964 12.0973ZM11.5964 12.0973L16 16.5" strokeWidth="2"/>
    </svg>
  )
}
