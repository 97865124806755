import { useLocation, useSearch, Link } from 'wouter'
import { useContext } from 'react'
import { StoreContext } from '~/store'
import ProfileView from './ProfileView'
import PaymentView from './PaymentView'
import BookingsView from './BookingsView'
import InfoView from './InfoView'
import ResetPasswordView from './ResetPasswordView'
import { verifyEmailToken } from '~/lib/api'
import { useTranslation } from 'react-i18next'

const supportedLangs = ['sv', 'en']
let lang = document.querySelector('html').getAttribute('lang')?.substr(0, 2)
if (!supportedLangs.includes(lang)) lang = 'sv'

const slugs = {
  sv: { 
    root: '/',
    profile: '/profil', 
    payment: '/betalmedel', 
    orders: '/resor', 
    emailVerified: '/epost-verifierad', 
    resetPassword: '/aterstall-losenord',
    tokenError: '/ogiltig-lank', 
    unknownError: '/ovantat-fel',
  },
  en: { 
    root: '/',
    profile: '/profile', 
    payment: '/payment', 
    orders: '/orders', 
    emailVerified: '/email-verified', 
    resetPassword: '/reset-password',
    tokenError: '/token-error', 
    unknownError: '/unknown-error',
  }
}

const matches = (str, slug) => {
  const path = getLocalizedSlug(slug)
  return str === path || str === path + '/'
}

export const getLocalizedBaseUrl = () => lang === 'sv' ? '/sv/mina-sidor' : '/en/my-pages'

export const getLocalizedSlug = slug => {
  return getLocalizedBaseUrl() + slugs[lang][slug]
}

export default function ProfileModule() {
  const { t } = useTranslation()
  const { user } = useContext(StoreContext)
  const [location, setLocation] = useLocation()
  const searchString = useSearch()
  const COPY = window.COPY

  if (matches(location, 'root') && !user) {
    return <InfoView headline={COPY.my_pages.login_header} body={t('info-login-to-update-profile')} showLogin />
  }
  else if (matches(location, 'root') || matches(location, 'profile')) {
    return <ProfileView />
  }
  else if (matches(location, 'payment')) {
    return <PaymentView />
  }
  else if (matches(location, 'orders')) {
    return <BookingsView />
  }
  else if (matches(location, 'emailVerified')) {
    return <InfoView headline={COPY.my_pages.email_verified_headline} body={COPY.my_pages.email_verified_body} showLogin />
  }
  else if (matches(location, 'tokenError')) {
    return <InfoView headline={COPY.my_pages.token_error_headline} body={COPY.my_pages.token_error_body} showLogin />
  }
  else if (matches(location, 'unknownError')) {
    return <InfoView headline={COPY.my_pages.unknown_error_headline} body={COPY.my_pages.unknown_error_body} showLogin />
  }
  // NOTE: This should be removed when all clients have switched to OTP
  else if (matches(location, 'resetPassword')) {
    const token = new URLSearchParams(searchString).get('token')
    if (token) {
      verifyEmailToken(token)
        .then(() => {
          return <ResetPasswordView />
        })
        .catch(error => {
          console.error(error)
          return setLocation(error?.status === 410 ? getLocalizedSlug('tokenError') : getLocalizedSlug('unknownError'))
        })
    }
    else {
      return setLocation(getLocalizedSlug('tokenError'))
    }
  }
  else {
    setLocation(getLocalizedSlug('root'))
  }
}
