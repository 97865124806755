import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function ToolTip({ title, isVisible }) {
  return (
    <div className={cx(styles.wrapper, { isVisible })}>
      {title}
    </div>
  )
}
