.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  width: 44px;
}

.label {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:focus-visible {
    outline: none;
  }

  &:focus-visible:before {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px var(--color-yellow);
  }

  &:after {
    content: '';
    transform: scale(0);
    transition: transform 0.4s ease;
  }

  &.isChecked {
    transition: background-color 0.2s ease;
    background-color: var(--color-yellow);

    &:after {
      content: '';
      transform: scale(1);
      transition: transform 0.4s ease;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.2L4.29412 6L9 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

/* .eta { */
/*   color: white; */
/*   background: black; */
/*   font-size: 11px; */
/*   line-height: 12px; */
/*   border-radius: var(--border-radius); */
/*   padding: 4px 6px; */
/*   margin-left: 5px; */
/* } */

.pill {
  color: white;
  background: black;
  font-size: 11px;
  line-height: 12px;
  border-radius: var(--border-radius);
  padding: 4px 6px;
  margin-top: 4px;

  &.yellow {
    color: black;
    background: var(--color-yellow);
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.field {
  visibility: hidden;
}
