.wrapper {
  position: relative;
}

.field {
  appearance: none;
  width: 100%;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius);

  &:autofill, 
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  &.border {
    border: 1px solid var(--color-cool-gray);
  }

  &.alternateBackground {
    background: var(--color-cool-gray);

    &:autofill, 
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px var(--color-cool-gray) inset !important;
    }
  }

  &.uppercase {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
}

.changeIcon {
  position: absolute;
  top: 50%;
  right: calc(var(--spacing) * 2);
  pointer-events: none;
  z-index: 1;
}
