.error {
  /* position: absolute; */
  /* bottom: -1.8em; */
  /* z-index: 1; */
  display: flex;
  align-items: center;
  margin-top: 4px;
  
  &.noMarginTop {
    margin-top: 0;
  }
}

.icon {
  width: 15px;
}

.text {
  margin-left: 4px;
  font-size: 11px;
  color: #DA0A2B;
}

