import classnames from 'classnames/bind'
import { FieldWrapper, NumberStepper, TitleInfo } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function PackageTypeField({ field, form: { setFieldValue }, type, title, info, max }) {

  const onChange = value => {
    setFieldValue(field.name, value)
  }

  return (
    <FieldWrapper alternateBackground>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <img src={`/app/themes/taxi-stockholm/static/img/package-icons/${type}.png`} className={cx(styles.icon, type)} />
        </div>
        <TitleInfo title={title} info={info} />
      </div>
      <div className={styles.right}>
        <NumberStepper 
          value={field.value}
          min={0}
          max={max}
          onChange={onChange} 
        />
      </div>
    </FieldWrapper>
  )
}
