import { useContext, useState } from 'react'
import { useFormikContext } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import { StoreContext } from '~/store'
import { FormContent, FormHeader, PackageDetails, PackageBookingSummary, ButtonDrawer } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { createBooking } from '~/lib/api'
import { showErrorModal } from '~/utils'
import * as MotionElement from '~/components/ui/MotionElement'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(common)

const ConfirmBooking = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { prev, next } = useContext(StepperContext)
  const { user, getActiveBookings } = useContext(StoreContext)

  const { values, setFieldValue } = useFormikContext()

  const makeRequest = () => {
    setIsLoading(true)
    createBooking(values, user.userId).then((response) => {
      console.log(response)
      if (!isNaN(response?.bookingId)) {
        getActiveBookings()
        setFieldValue('bookingId', response.bookingId, false)
        next()
      }
    })
    .catch((error) => {
      if (error?.response?.error === 'INVALID_PHONE_NUMBER') {
        setFieldError('phone', error?.response?.message)
      }
      else if (!(error?.response?.status === 401 && error?.response?.error === 'Unauthorized')) {
        console.error(error)
        showErrorModal(error?.response?.message)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const wrappedNext = wrapWithValidation(makeRequest, useFormikContext())

  const buttons = [
    { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-book-now'), isLoading },
  ]

  return (
    <motion.div layout transition={{duration:.18}} className={cx(common.widgetWrapper, { isLoading })}>
      <FormContent>
        <FormHeader>{t('header-confirm-your-booking')}</FormHeader>
        <PackageBookingSummary values={values} showHow showPayment />
      </FormContent>
      <MotionElement.div className={cx(common.contentBottom, { largePadding: true })}>
        <PackageDetails values={values} />
      </MotionElement.div>
      <ButtonDrawer buttons={buttons} />
    </motion.div>
  )
}

ConfirmBooking.validationSchema = null

export default ConfirmBooking
