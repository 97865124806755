import { useContext, useEffect, useState, useRef } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { FormContent, FormHeader, FormError, CodeInput, ButtonDrawer } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { validateEmailOtp, resendEmailOtp, isBadState } from '~/lib/api'
import * as common from '~/config/common.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'

export default function ValidateEmail() {
  const { t } = useTranslation()
  const inputRef = useRef()
  const { reset, next } = useContext(StepperContext)
  const { values, initialValues, setFieldValue, setFieldError, setFieldTouched } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [isButtonLoading, setButtonIsLoading] = useState(false)
  const [activationBadState, setActivationBadState] = useState(false)

  // NOTE: Reset code when switching views
  const wrappedNext = (id) => {
    setFieldValue('emailCode', '')
    next(id)
  }

  const wrappedReset = () => {
    for (const [key, value] of Object.entries(initialValues)) {
      setFieldValue(key, value, false)
    }
    reset()
  }

  const makeRequest = () => {
    setIsLoading(true)
    validateEmailOtp(values.emailCode).then((response) => {
      console.log(response)
      if (response?.deviceActivationState === 'EMAIL_OTP_COMPLETE') {
        if (response?.shouldConsentToMarketing !== undefined) {
          setFieldValue('shouldConsentToMarketing', response.shouldConsentToMarketing, false)
        }
        if (response?.shouldConsentToTermsAndConditions !== undefined) {
          setFieldValue('shouldConsentToTermsAndConditions', response.shouldConsentToTermsAndConditions, false)
        }
        wrappedNext('acceptTerms')
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('emailCode', error?.response?.message)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const makeNewRequest = () => {
    setButtonIsLoading(true)
    resendEmailOtp().then((response) => {
      console.log(response)
      setFieldValue('emailCode', '')
      inputRef.current?.focus()
    })
    .catch((error) => {
      console.error(error)
      setFieldTouched('emailCode', true, true)
      // NOTE: Workaround to force the field to show the error
      setTimeout(() => setFieldError('emailCode', error?.response?.message), 100)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setButtonIsLoading(false)
      setButtonDisabled(true)
    })
  }

  // TODO: Use old JS for now, replace the ModalView with React in the future.
  const useOtherEmail = () => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      t('change-email-message'), [
        { title: COPY.booking.button_ok, callback: () => wrappedNext('inputEmail') },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  const buttons = [
    { func: wrappedReset, isBack: true },
    { func: useOtherEmail, title: t('button-select-other-email'), disabled: activationBadState },
    { func: makeNewRequest, title: t('button-did-not-get-code'), disabled: isButtonDisabled || activationBadState, isLoading: isButtonLoading },
  ]

  useEffect(() => {
    let timeoutId
    if (isButtonDisabled) {
      timeoutId = setTimeout(() => setButtonDisabled(false), 5000)
    }
    return () => clearTimeout(timeoutId)
  }, [isButtonDisabled])

  useEffect(() => {
    if (values.emailCode.length === 4) {
      console.log('Email OTP:', values.emailCode)
      makeRequest()
    }
  }, [values.emailCode])

  return (
    <>
      <FormContent isLoading={isLoading || isButtonLoading}>
        <FormHeader>{t('header-welcome-back')}</FormHeader>
        <MotionElement.div className={common.content}>
          <p className={common.fullWidth}>{t('info-your-phone-is-linked', { maskedEmail: values.maskedEmail })}</p>
          <div className={common.fullWidth}>
            <Field name="emailCode" inputRef={inputRef} component={CodeInput} />
            <ErrorMessage name="emailCode" component={FormError} />
          </div>
        </MotionElement.div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading || isButtonLoading} />
    </> 
  )
}
