import { useContext, useRef } from 'react'
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events'
import { useFormikContext } from 'formik'
import classnames from 'classnames/bind'
import { StoreContext } from '~/store'
import { searchLocation } from '~/lib/api'
import { formatServiceAvailabiltyQuery } from '~/utils/format'
import * as styles from './index.module.css'

export const DebugListener = ({ id }) => {
  const { values, errors, resetForm } = useFormikContext()
  const { user } = useContext(StoreContext)

  useCustomEventListener(
    'LOG_VALUES',
    (targetId) => {
      if (id === targetId) {
        console.log(values)
        console.log(errors)
      }
    },
    [values, errors]
  )

  useCustomEventListener(
    'RESET_VALUES',
    (targetId) => {
      console.log('reset values')
      if (id === targetId) {
        resetForm()
      }
    },
    [resetForm]
  )

  useCustomEventListener(
    'SEARCH',
    (query) => {
      searchLocation(query, false).then((locations) => {
        console.log(locations)
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error(error.message)
        }
      })
    },
    []
  )

  return null
}

export default function DebugPanel() {
  const { user, bookings } = useContext(StoreContext)

  return (
    <div className={classnames(styles.wrapper, styles.container)}>
      <span className={styles.title}>Debug:</span>
      <button onClick={() => console.log(user)}>User</button>
      <button onClick={() => console.log(bookings)}>Bookings</button>
      <span>|</span>
      <button onClick={() => emitCustomEvent('LOG_VALUES', 'main')}>Booking values</button>
      <button onClick={() => emitCustomEvent('RESET_VALUES', 'main')}>Reset</button>
      <span>|</span>
      <button onClick={() => emitCustomEvent('LOG_VALUES', 'package')}>Package values</button>
      <button onClick={() => emitCustomEvent('LOG_VALUES', 'otp')}>OTP values</button>
      <span>|</span>
      <button onClick={() => emitCustomEvent('SEARCH', 'kva')}>Search 1</button>
      <button onClick={() => emitCustomEvent('SEARCH', 'kvarn')}>Search 2</button>
    </div>
  )
}
