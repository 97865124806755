import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import { useContext, useState } from 'react'
import { StoreContext } from '~/store'
import { updateUser, deleteUser, logoutDevice } from '~/lib/api'
import { SimpleSwitch } from '~/components'
import Header from '../Header'
import Menu from '../Menu'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

export default function ProfileView() {
  const { t } = useTranslation()
  const { user, setUser, logoutUser } = useContext(StoreContext)
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)
  if (!user) return null

  const deleteProfile = async () => {
    console.log('deleteProfile:', user.userId)
    setLoading(true)
    deleteUser(user.userId).then((response) => {
      setMessage('')
      logoutUser()
    })
    .catch((error) => {
      console.error(error)
      setMessage(error?.response?.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const logout = async () => {
    setLoading(true)
    logoutDevice().then((response) => {
      setMessage('')
      logoutUser()
    })
    .catch((error) => {
      console.error(error)
      setMessage(error?.response?.message)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const onDeleteClick = () => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      COPY.my_pages.delete_profile_confirm, [
        { title: COPY.booking.button_ok, callback: deleteProfile },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email || user.username,
    phone: user.phone,
    marketingOptIn: user?.marketingOptIn?.consent
  }

  const COPY = window.COPY

  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().email().required(),
      })}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true)
        updateUser(user.userId, values).then((response) => {
          setMessage('')
          setUser(response)
        })
        .catch((error) => {
          console.log(error)
          resetForm()
          setMessage(error?.response?.message)
        })
        .finally(() => {
          setLoading(false)
        })
      }}
    >
      {({ errors, submitForm }) => (
        <section className="profile-view">
          <Header />
          <div className="my-pages-container">
            <Menu />
            <div className="my-pages-content">
              <h2>{COPY.my_pages.update_profile}</h2>
              <div className={classnames('my-pages-form profile-form', { loading })}>
                <header className="form-header">{COPY.my_pages.details_header}</header>
                <div className="form-group">
                  <label htmlFor="firstName">{t('label-firstname')}</label>
                  <Field name="firstName" className={classnames('input-field has-label', { 'has-error': errors?.firstName })}/>
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">{t('label-lastname')}</label>
                  <Field name="lastName" className={classnames('input-field has-label', { 'has-error': errors?.lastName })}/>
                </div>
                <div className="form-group">
                  <label htmlFor="email">{COPY.my_pages.label_email}</label>
                  <Field name="email" className={classnames('input-field has-label', { 'has-error': errors?.email })}/>
                </div>
                <div className="form-group">
                  <label htmlFor="phone">{t('label-mobile-phone')}</label>
                  <Field name="phone" className={classnames('input-field has-label', styles.phoneGroup)} disabled/>
                  <p className={styles.phoneInfo}>{t('info-change-phone')}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="marketingOptIn">{t('label-communications')}</label>
                  <div className={styles.wrapper}>
                    <p className={styles.marketingInfo}>{t('title-accept-marketing')}</p>
                    <Field name="marketingOptIn" component={SimpleSwitch} />
                  </div>
                </div>
                <div className="form-group with-padding remove-top-padding">
                  <div className={classnames('message', { show: message })}>{message}</div>
                  <button id="save-profile-button" className="btn" onClick={submitForm}>{COPY.my_pages.save_profile_button}</button>
                  <button id="delete-profile-button" className="btn gray" onClick={onDeleteClick}>{COPY.my_pages.delete_profile_button}</button>
                  <button id="logout-button" className="btn gray" onClick={logout}>{COPY.my_pages.logout_button}</button>
                </div>
              </div>

            </div>
          </div>
        </section>
      )}
    </Formik>
  )
}
