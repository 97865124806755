.flag {
  flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
}

.fallback {
  flex-shrink: 0;
  width: 30px;
}
