import { useContext, useState } from 'react'
import { useFormikContext, Field } from 'formik'
import * as Yup from 'yup'
import { FormContent, FormHeader, InputField, ButtonDrawer } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { addName, isBadState } from '~/lib/api'
import * as common from '~/config/common.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const InputName = () => {
  const { t } = useTranslation()
  const { reset, next } = useContext(StepperContext)
  const { values, initialValues, setFieldValue, setFieldError } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [activationBadState, setActivationBadState] = useState(false)

  const wrappedReset = () => {
    for (const [key, value] of Object.entries(initialValues)) {
      setFieldValue(key, value, false)
    }
    reset()
  }

  const makeRequest = () => {
    setIsLoading(true)
    addName(values.firstName, values.lastName).then((response) => {
      console.log(response)
      if (response?.deviceActivationState === 'NAME_ADDED') {
        if (response?.shouldConsentToMarketing !== undefined) {
          setFieldValue('shouldConsentToMarketing', response.shouldConsentToMarketing, false)
        }
        if (response?.shouldConsentToTermsAndConditions !== undefined) {
          setFieldValue('shouldConsentToTermsAndConditions', response.shouldConsentToTermsAndConditions, false)
        }
        next()
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('firstName', error?.response?.message)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const wrappedNext = wrapWithValidation(makeRequest, useFormikContext())

  const buttons = [
    { func: wrappedReset, isBack: true },
    { func: wrappedNext, title: t('button-continue'), disabled: activationBadState, isLoading },
  ]

  return (
    <>
      <FormContent isLoading={isLoading}>
        <FormHeader>{t('header-enter-name')}</FormHeader>
        <MotionElement.div className={common.content}>
          <div className={common.fullWidth}>{t('info-driver-confirm-pickup')}</div>
          <Field name="firstName" placeholder={t('placeholder-firstname')} component={InputField} />
          <Field name="lastName" placeholder={t('placeholder-lastname')} component={InputField} />
        </MotionElement.div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading} />
    </> 
  )
}

InputName.validationSchema = Yup.object({
  firstName: Yup.string().required(i18next.t('validation-need-firstname')),
  lastName: Yup.string().required(i18next.t('validation-need-lastname')),
})

export default InputName
