.wrapper {
  position: absolute;
  top: 13px;
  right: 13px;
  padding: 0 15px 0 13px;
  opacity: 0;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  background: black;
  border-radius: 5px;
  transition: transform 0.3s var(--easeInOutCirc), opacity 0.3s var(--easeInOutCirc);
  transform: translateX(26px);
  pointer-events: none;
  z-index: 10;

  &.isVisible {
    transform: translateX(0);
    opacity: 1;
  }

  &:before {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    left: -10px;
    border: 5px solid transparent;
    border-right-color: black;
    margin-top: calc(-5px + 15px);
  }
}
