import { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { emitCustomEvent } from 'react-custom-events'
import { FormContent, FormError, FormHeader, CheckboxFieldSimple, ButtonDrawer, InputField } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { StoreContext } from '~/store'
import { completeActivation, isBadState } from '~/lib/api'
import * as styles from './index.module.css'
import { useTranslation, Trans } from 'react-i18next'

export default function AcceptTerms({ isLogin }) {
  const { t } = useTranslation()
  const { reset, next } = useContext(StepperContext)
  const { values, initialValues, setFieldValue, setFieldError } = useFormikContext()
  const { config, loginAndFetchBookings } = useContext(StoreContext)
  const [isLoading, setIsLoading] = useState(false)
  const [activationBadState, setActivationBadState] = useState(false)
  const switchRef = useRef()

  const wrappedReset = () => {
    for (const [key, value] of Object.entries(initialValues)) {
      setFieldValue(key, value, false)
    }
    reset()
  }

  const makeRequest = () => {
    setIsLoading(true)
    const obj = { termsAndConditions: values.termsAndConditions }
    if (values.shouldConsentToMarketing) {
      obj.marketingOptIn = values.marketingOptIn
    }
    completeActivation(obj).then((response) => {
      console.log(response)
      if (response.userId && response?.deviceActivationState === 'ACTIVATION_COMPLETE_EXISTING_USER' || response?.deviceActivationState === 'ACTIVATION_COMPLETE_NEW_USER') {
        loginAndFetchBookings(response.userId)
        next()
        if (isLogin) emitCustomEvent('SHOW_LOGIN', false)
      }
      else {
        console.error('Error!')
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('termsAndConditions', error?.response?.message)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const buttons = [
    { func: wrappedReset, isBack: true },
    { func: makeRequest, title: t('button-confirm'), disabled: values.termsAndConditions === false || activationBadState, isLoading },
  ]

  useEffect(() => {
    if (switchRef.current) switchRef.current.focus()
  }, [switchRef])

  return (
    <>
      <FormContent isLoading={isLoading}>
        <FormHeader>{t('header-terms-and-conditions')}</FormHeader>
        <p className={styles.body}>
          <Trans 
            i18nKey="info-accept-terms" 
            components={{
              link1: <a href={config.termsLink} target="_blank"/>
            }}
          />
        </p>
        <div className={styles.wrapper}>
          <div>
            <Field name="termsAndConditions" title={t('title-accept-terms')} innerRef={switchRef} component={CheckboxFieldSimple} />
            <ErrorMessage name="termsAndConditions" component={FormError} />
          </div>
          {values.shouldConsentToMarketing && <Field name="marketingOptIn" title={t('title-accept-marketing')} component={CheckboxFieldSimple} />}
        </div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading} />
    </> 
  )
}
