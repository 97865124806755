import classnames from 'classnames/bind'
import { useEffect, useState } from 'react'
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events'
import { BookingModule, PackageModule } from '~/components'
import { qsa } from '~/utils'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

// TODO: Make a global solution to this
const supportedLangs = ['sv', 'en']
let lang = document.querySelector('html').getAttribute('lang')?.substr(0, 2)
if (!supportedLangs.includes(lang)) lang = 'sv'

const hashes = {
  sv: { 
    bookTaxi: '#boka-taxi', 
    bookDelivery: '#boka-bud', 
    bookDeliveryPath: 'boka-bud',
  },
  en: { 
    bookTaxi: '#book-taxi', 
    bookDelivery: '#book-delivery', 
    bookDeliveryPath: 'book-delivery',
  }
}

const getLocalizedHash = hash => {
  return hashes[lang][hash]
}


export default function BookingModuleWrapper() {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState(false)
  const [isPackage, setIsPackage] = useState(location.hash === getLocalizedHash('bookDelivery') ? true : location.href.includes(getLocalizedHash('bookDeliveryPath')))
  const [showButtons, setShowButtons] = useState(true)

  const switchBooking = (showPackage) => {
    const callback = () => {
      setIsPackage(showPackage)
      setIsDirty(false)
      emitCustomEvent('RESET_MAP')
      location.href = getLocalizedHash(showPackage ? 'bookDelivery' : 'bookTaxi')
      APP.trigger(APP.events.BOOKING_MODULE_SHOW_COVER)
    }
    if (!isDirty) {
      callback()
    }
    else {
      APP.trigger(
        APP.events.SHOW_MODAL,
          t('reset-booking-message'), [
          { title: t('button-yes'), callback },
          { title: t('button-no') },
        ]
      )
    }
  }

  const handleHashClick = e => {
    const t = e.target.hash === getLocalizedHash('bookTaxi')
    const d = e.target.hash === getLocalizedHash('bookDelivery')
    if (t || d) {
      switchBooking(d)
      scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  // Show the buttons only on the first step
  useCustomEventListener(
    'VIEW_CHANGE',
    (data) => {
      setShowButtons(data.id === 'locations')
    },
    [showButtons]
  )

  // Set isDirty when a location is selected
  useCustomEventListener(
    'LOCATION_CHANGE',
    ({ locations }) => {
      console.log('LOCATION_CHANGE')
      if (!locations.every(item => item === null)) {
        setIsDirty(true)
      }
    },
    [isDirty]
  )

  // Resetting the form clears the isDirty flag
  useCustomEventListener(
    'RESET_FORM',
    () => {
      console.log('RESET FORM')
      setIsDirty(false)
    },
    [isDirty]
  )

  useEffect(() => {
    setTimeout(() => {
      APP.trigger(APP.events.SHOW_BOOKING_WIDGET)
    }, 50)

    // Get all links containing a hash
    const links = []
    const l = qsa('a[href*="#"]')

    if (l.length > 0) {
      // Get hashes for current lang
      const h = Object.values(hashes[lang])

      // Iterate links and hashes
      l.forEach(link => {
        h.forEach(hash => {
          // If there is a link to the current page + a hash add a click listener
          if (link.href === location.origin + location.pathname + hash) {
            link.addEventListener('click', handleHashClick)
            links.push(link)
          }
        })
      })
    }

    // Return function that removes listeners
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleHashClick)
      })
    }
  }, [])

  return (
    <>
      {showButtons && <div className={styles.buttons}>
        <button onClick={() => switchBooking(false)} className={cx(styles.button, { active: !isPackage })}>
          <span className={styles.cornerTabsLeft}></span>
          {t('button-book-taxi')}
          <span className={cx(styles.corner, { right: true })}></span>
        </button>
        <button onClick={() => switchBooking(true)} className={cx(styles.button, { active: isPackage })}>
          <span className={cx(styles.corner, { left: true })}></span>
          {t('button-book-delivery')}
          <span className={cx(styles.corner, { right: true })}></span>
        </button>
      </div>}
      {!isPackage && <BookingModule />}
      {isPackage && <PackageModule />}
    </>
  )
}

