import { default as errors } from './errors.json'

export const getErrorMessage = (endPoint, error) => {
  if (errors[endPoint]?.[error]?.[APP.lang]) {
    return errors[endPoint][error][APP.lang]
  }
  else {
    for (const [key, value] of Object.entries(errors)) {
      if (RegExp(key.replace(/\{[^}]+\}/g, '[^\/]+')) && value[error]?.[APP.lang]) {
        return value[error][APP.lang]
      }
    }
  }
  return APP.lang === 'sv' ? 'Oj, något gick fel. Var vänlig försök igen' : 'Oops! Something went wrong, please try again'
}
