import { useContext } from 'react'
import { useFormikContext, Field } from 'formik'
import { motion } from "framer-motion"
import * as Yup from 'yup'
import { StoreContext } from '~/store'
import { FormContent, FormHeader, PackageBookingSummary, ButtonDrawer, RadioField, FieldDropDown, InputField } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import * as MotionElement from '~/components/ui/MotionElement'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const getPaymentText = (value, accounts) => {
  if (value !== 'PayInCar') {
    return accounts.find(a => a.accountNumber === parseInt(value))?.accountName
  }
  return i18next.t('title-pay-in-car-sender')
}

const SelectSender = () => {
  const { t } = useTranslation()
  const { prev, next } = useContext(StepperContext)
  const { user } = useContext(StoreContext)
  const { values, setFieldValue } = useFormikContext()

  const wrappedPrev = () => {
    prev('what')
  }

  const wrappedNext = wrapWithValidation(next, useFormikContext())

  const setAccount = account => {
    if (!account) {
      setFieldValue('accountReference', '', false)
    }
    setFieldValue('account', account, false)
  }
  const buttons = [
    { func: wrappedPrev, isBack: true },
    { func: wrappedNext, title: t('button-continue') },
  ]

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <FormContent>
        <FormHeader>{t('header-whos-the-sender')}</FormHeader>
        <PackageBookingSummary values={values} showHow showPayment />
      </FormContent>
      <MotionElement.div className={common.contentBottom}>
        <Field name="name" placeholder={t('placeholder-name')} component={InputField} border />
        <Field name="phone" placeholder={t('placeholder-phone')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageSender.nameOnDoor" placeholder={t('placeholder-name-on-door')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageSender.numberOfStairs" placeholder={t('placeholder-number-of-stairs')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageSender.doorCode" placeholder={t('placeholder-door-code')} component={InputField} border />
        <FieldDropDown title={getPaymentText(values.payment, user?.accounts)} alternateBackground>
          <Field type="radio" name="payment" title={t('title-pay-in-car-sender')} value="PayInCar" onClick={()=>setAccount(false)} component={RadioField} />
          {user?.accounts?.map((account, index) => (
            <Field key={index} type="radio" name="payment" title={account.accountName} info={account.accountNumber} value={`${account.accountNumber}`} onClick={()=>setAccount(account)} component={RadioField} />
          ))}
        </FieldDropDown>
        {values.account && <div className={common.fullWidth}>
          <Field name="accountReference" placeholder={t('placeholder-reference')} component={InputField} alternateBackground />
        </div>}
      </MotionElement.div>
      <ButtonDrawer buttons={buttons} />
    </motion.div>
  )
}

SelectSender.validationSchema = Yup.object({
  name: Yup.string().required(i18next.t('validation-need-name')),
  phone: Yup.string()
    .matches(/^\+?\d+$/, i18next.t('validation-only-numbers'))
    .required(i18next.t('validation-need-phone')),
  packageDeliveryInfo: Yup.object({
    packageSender: Yup.object({
      numberOfStairs: Yup.string()
        .matches(/^\+?\d+$/, i18next.t('validation-only-numbers')),
      doorCode: Yup.string(),
    })
  }),
  accountReference: Yup.string()
    .when('account', (account) => {
      return account && account.flags.includes('reference') ? Yup.string().required(i18next.t('validation-need-reference')) : Yup.string()
    }),
})
 
export default SelectSender
