.wrapper {
  position: absolute;
  top: calc(var(--spacing) * 2);
  right: calc(var(--spacing) * 2);
  pointer-events: auto;
}

.button { 
  width: 32px;

  &.isLarge {
    @media (--md) {
      width: 48px;
    }
  }

  @media (--hover) {
    &:hover {
      & .fg {
        opacity: 1;
      }
      & .bg {
        fill: var(--color-yellow);
      }
    }
  }
}

.svg {
  width: 100%;
  height: 32px;
  
  &.isLarge {
    height: 48px;
  }
}

.fg {
  fill: black;
  opacity: 0.5;
  transition: opacity 0.2s var(--easeOutQuad);
}

.bg {
  fill: rgba(0, 0, 0, 0.05);
  transition: fill 0.2s var(--easeOutQuad);
}
