import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames'
import { useContext, useState } from 'react'
import { ButtonDrawer, InputField } from '~/components'
import { StoreContext } from '~/store'
import { sendConfirmationEmail } from '~/lib/api'
import { sleep } from '~/utils'
import { useTranslation } from 'react-i18next'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function SendConfirmation({ bookingId, onComplete }) {
  const { t } = useTranslation()
  const { user } = useContext(StoreContext)
  const [message, setMessage] = useState()

  const initialValues = {
    email: user.email,
  }

  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t('validation-invalid-email'))
          .required(t('validation-need-email')),
      })}
      onSubmit={async (values) => {
        setMessage('')
        await sleep(500)
        sendConfirmationEmail(bookingId, values.email).then((response) => {
          console.log(response)
          // setMessage(t('info-confirmation-sent'))
          if (onComplete) {
            onComplete()
          }
        })
        .catch((error) => {
          console.error(error)
          setMessage(error?.response?.message)
        })
      }}
    >
      {({ errors, submitForm, isSubmitting }) => (
        <>
          <div className={styles.wrapper}>
            <div className={styles.title}>{t('header-send-confirmation')}</div>
            <Field name="email" placeholder={t('placeholder-email')} component={InputField} alternateBackground />
            {message && <div className={styles.message}>{message}</div>}
          </div>
          <ButtonDrawer buttons={[{ func: submitForm, title: t('button-send'), isLoading: isSubmitting }]} isLoading={isSubmitting} />
        </>
      )}
    </Formik>
  )
}

