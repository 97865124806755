.wrapper {
  height: 100%;
  transition: transform 0.3s var(--easeInOutCirc);

  &.isOpen {
    transform: translateX(-100%);
  }
}

.buttonWrapper {
  position: absolute;
  top: 14px;
  left: -72px;
  pointer-events: none;
  transition: transform 0.3s var(--easeInOutCirc);

  @media (--lg) {
    top: 30px;
    left: -84px;
  }

  &.isOpen {
    transform: translateX(90px);

    @media (--lg) {
      transform: none;
    }

    & .button {
      background-size: 13px 13px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTY0MTRCOTUyMUREMTFFNzgzRTJDMjQ1ODFCMDBCNEQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTY0MTRCOTYyMUREMTFFNzgzRTJDMjQ1ODFCMDBCNEQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5NjQxNEI5MzIxREQxMUU3ODNFMkMyNDU4MUIwMEI0RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5NjQxNEI5NDIxREQxMUU3ODNFMkMyNDU4MUIwMEI0RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pj9Z60AAAAC8SURBVHjatNZRCsMgDAZg6ZF2Fk16//d6gkLnwGytoMbk3w95qZKvlBISwi+vUjHgkmrPRz4PjlJnKQIgXHsdd0yQq5YXE0T6fbHYHHgw7vRKswsEQHbtRUIiHmwZsWBkRVYwN6LBYMgMgyIjrEUYNbd6mBrZlNBlPFsKKT4d/QM5gbNxiHD9wyDYCJG4MQ0ywxiJmDGyvt0K5kHUWAQgMyzKcpKBs6vFcrsJZeCAFCz3drsEXCDpjrwFGAD2Lep8CrxtcwAAAABJRU5ErkJggg==');
    }
  }

  &.isVisible {
    pointer-events: auto;

    & .button {
      transform: scale(1);
      opacity: 1;
    }
  }

  & .button {
    width: 54px;
    height: 54px;
    transform: scale(0);
    transition: transform 0.3s var(--easeInOutCirc), opacity 0.3s var(--easeInOutCirc);
    opacity: 0;
    cursor: pointer;
    border-radius: 100%;
    background-color: var(--color-yellow);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 21px 19px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAATCAYAAAB/TkaLAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAEdJREFUeNpiYMAE/xlIAxjqGck0CB9gxGYgpS79T21X0s5QRhoYyjB0vE9M7JPFp7qhsIiiRiZgHHreHw3TIVSgUL2QBggwAIu9Ps0VYC1hAAAAAElFTkSuQmCC');
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }

  & .tooltip {
    position: absolute;
    top: 50%;
    padding: 0 15px 0 13px;
    margin-left: -15px;  
    transform: translate(-125%, -50%);
    opacity: 0;
    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    white-space: nowrap;
    background: black;
    border-radius: 5px;
    transition: transform 0.3s var(--easeInOutCirc), opacity 0.3s var(--easeInOutCirc);
    pointer-events: none;

    &.show {
      transform: translate(-100%, -50%);
      opacity: 1;
    }

    &:after {
      position: absolute;
      content: '';
      height: 0;
      width: 0;
      border: 5px solid transparent;
      border-left-color: black;
      margin-top: calc(-5px + 15px);
      margin-left: 14px;
    }
  }

  & .num {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 23px;
    height: 23px;
    line-height: 21px;
    border-radius: 100%;
    font-size: 11px;
    font-weight: 500;
    background: white;
    text-align: center;
    transform: scale(0);
    transition: transform 0.3s var(--easeInOutCirc), opacity 0.3s var(--easeInOutCirc);
    border: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0;

    &.show {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.content {
  padding: 28px var(--padding-small) var(--padding-small);
  width: 100vw;
  height: 100%;
  background: #f4f4f4;
  overflow-y: auto;

  @media (--lg) {
    /* width: 525px; */
    width: 488px;
    padding: 35px var(--padding) var(--padding);
  }
}

.header {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 41px;

  @media (--lg) {
    margin-bottom: 35px;
  }
}
