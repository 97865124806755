.container {
  width: 100%;
}

.fieldwrapper {
  position: relative;
  /* min-width: 320px; */
  font-size: 16px;
  font-weight: 500;

  & .icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 3;
  }

  & input {
    position: relative;
    width: 100%;
    height: 56px;
    font-size: inherit;
    font-weight: 500;
    border: none;
    padding: 0 0 0 51px;
    border-radius: var(--border-radius);
    z-index: 2;

    &::placeholder {
      color: var(--color-black-30);
    }
  }

  &.dropDownVisible {
    & input {
      border-radius: var(--border-radius) var(--border-radius) 0 0;

      @media (--lg) {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
      }
    }
    & .dropdown {
      display: block;
    }
  }
}

.dropdown {
  position: absolute;
  display: none;
  width: 100%;  
  max-height: 240px; /* TODO: Use calc(5 * item-height) */
  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  z-index: 1;

  @media (--lg) {
    top: 0;
    transform: translateY(-100%);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
}

.item {
  font-size: inherit;
  padding: 0.5rem 20px;
  background: white;
  cursor: pointer;

  &.selected {
    background: var(--color-yellow);
  }

  @media (--hover) {
    &:hover {
      background: var(--color-yellow);
    }
  }
}

.locationbutton {
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7344 10C15.7344 13.4432 12.9432 16.2344 9.5 16.2344M15.7344 10C15.7344 6.55685 12.9432 3.76562 9.5 3.76562M15.7344 10H19M9.5 16.2344C6.05685 16.2344 3.26562 13.4432 3.26562 10M9.5 16.2344V19.5M3.26562 10C3.26562 6.55685 6.05685 3.76562 9.5 3.76562M3.26562 10H0M9.5 3.76562V0.5' stroke='black' stroke-opacity='0.3' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}

.charIcon {
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

