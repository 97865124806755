import { useRef } from 'react'
import { Field } from 'formik'
import classnames from 'classnames/bind'
import { FieldWrapper, TitleInfo } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function RadioField({ field, form: { setFieldValue }, title, info, disabled, ...props }) {
  const isChecked = field.checked
  const clickRef = useRef()

  const onKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const onKeyUp = e => {
    if (e.keyCode === 32) {
      e.preventDefault()
      setFieldValue(field.name, field.value)
      if (props.onClick) {
        props.onClick()
      }
    }
  }

  const wrapperClick = () => {
    (props?.innerRef || clickRef)?.current?.click()
  }

  return (
    <FieldWrapper isDisabled={disabled} onClick={wrapperClick} alternateBackground>
      <TitleInfo title={title} info={info} />
      <label ref={props?.innerRef || clickRef} className={cx(styles.label, { isChecked })} tabIndex={disabled ? -1 : 0} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        <Field 
          type="radio" 
          as="input" 
          className={styles.field} 
          name={field.name} 
          onChange={field.onChange} 
          onBlur={field.onBlur} 
          disabled={disabled}
          {...props} 
        />
      </label>
    </FieldWrapper>
  )
}
