import { useContext, useRef, useState, useEffect } from 'react'
import classnames from 'classnames/bind'
import { useCookieValue } from '@react-hookz/web/esm/useCookieValue/useCookieValue'
import { useIntersectionObserver } from '@react-hookz/web/esnext'
import { StoreContext } from '~/store'
import { default as Booking } from '../Booking'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export default function ActiveBookings({ slideableContent, header }) {
  const { t } = useTranslation()
  const { bookings, getActiveBookings } = useContext(StoreContext)
  const contentRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [hideTooltip, setHideTooltip] = useCookieValue('hideTooltip', { expires: 3600 })

  const intersection = useIntersectionObserver(header, { root: document, threshold: [0] })

  const onClick = () => {
    if (contentRef.current) {
      const el = contentRef.current
      slideableContent.style.transform = isOpen ? 'translateX(0)' : `translateX(-${el.offsetWidth}px)`
      setIsOpen(!isOpen)
      if (hideTooltip !== 'true') setHideTooltip('true')
    }
  }

  useEffect(() => {
    if (isOpen && bookings?.length === 0) {
      onClick()
    }
  }, [bookings, isOpen])

  useEffect(() => {
    if (contentRef.current && intersection) {
      const el = contentRef.current.parentElement
      if (intersection.isIntersecting && el.classList.contains('fixed')) el.classList.remove('fixed')
      else if (!intersection.isIntersecting && !el.classList.contains('fixed')) el.classList.add('fixed')
    }
  }, [intersection])

  return (
    <div ref={contentRef} className={cx(styles.wrapper, { isOpen })}>
      <div className={cx(styles.buttonWrapper, { isOpen, isVisible: bookings?.length > 0 })}>
        <div className={cx(styles.tooltip, { show: bookings?.length > 0 && hideTooltip !== 'true' })}>{t('tooltip-here-is-your-bookings')}</div>
        <button tabIndex="-1" type="button" className={styles.button} onClick={onClick}>
          <div className={cx(styles.num, { show: bookings?.length > 1 })}>{bookings?.length}</div>
        </button>
      </div>
      <div className={styles.content}>
        <h3 className={styles.header}>{t('header-your-bookings')}</h3>
        <ul>
          {bookings && bookings.map(booking => (
            <Booking key={booking.id} booking={booking} onCancel={getActiveBookings} />
          ))}
        </ul>
      </div>
    </div>
  )
}
