import { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import { motion } from "framer-motion"
import * as MotionElement from '~/components/ui/MotionElement'
import { 
  FormContent, FormHeader, BookingSummary, ButtonDrawer, CarRadioField, CheckboxField, 
  FieldButton, OptionsPanel, BoosterField, TitleInfo, FieldWrapper, Spinner, FormError, Notifications
} from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { StoreContext } from '~/store'
import { getCarTypes, getAvailableVehicleOptions } from '~/utils/data'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(common)

const renderPrice = (prices, serviceType) => {
  const price = prices.find(p => p.serviceType === serviceType)
  if (price === undefined) {
    console.error('Could not find price for servicetype:', serviceType)
    return null
  }
  if (price.finalPrice === price.fullPrice) return `${price.finalPrice}:-`
  else return <>{price.finalPrice}:- <s>{price.fullPrice}:-</s></>
}

const SelectHow = () => {
  const { t } = useTranslation()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const buttonRef = useRef()
  const checkboxRef = useRef()
  const carTypeRef = useRef()
  const { prev, next } = useContext(StepperContext)
  const { user } = useContext(StoreContext)

  const { values, setFieldValue, handleChange, handleBlur } = useFormikContext()
  // const showAvailability = values.departure?.address !== undefined && values.pickupTime === ''
  const disableFixedPrice = values.destination?.address === undefined || values.autoValues.fixedPrice !== null
  const fixedPrice = values.autoValues.fixedPrice !== null ? values.autoValues.fixedPrice : values.fixedPrice
  const showPrices = fixedPrice && values.priceEstimate
  const vehicleOptions = getAvailableVehicleOptions(values)
  const petAvailable = vehicleOptions.includes('pet')
  const stationWagonAvailable = vehicleOptions.includes('stationWagon')
  const rearFacingChildSeatAvailable = vehicleOptions.includes('rearFacingChildSeat')
  const boosterSeat1Available = vehicleOptions.includes('boosterSeat1')
  const boosterSeat2Available = vehicleOptions.includes('boosterSeat2')

  let numSeatsMax = 0
  if (boosterSeat1Available) {
    numSeatsMax = boosterSeat2Available ? 2 : 1
  }
  
  const fields = getCarTypes()
    .filter(field => field.value !== 'delivery')
    .filter(field => {
      const key = values.pickupTime === '' ? 'direct' : 'prebook'
      const name = field.value
      return values.availability[key].availabilities.find(item => name === item.serviceType) !== undefined
    })
    .map(field => {
      const key = values.pickupTime === '' ? 'direct' : 'prebook'
      const name = field.value
      if (values.availability[key]?.availabilities?.length) {
        field.availability = values.availability[key].availabilities.find(item => name === item.serviceType)
        field.disabled = field.availability.availableAt === 'NOT_AVAILABLE'
      }
      if (showPrices) field.price = renderPrice(values.priceEstimate.prices, field.value)
      if (field.value === values.serviceType) field.labelRef = carTypeRef
      return field
    })

  const openPanel = () => {
    setIsPanelOpen(true)
    setTimeout(() => {
      if (checkboxRef.current) checkboxRef.current.focus()
    }, 200)
  }

  const closePanel = () => {
    setIsPanelOpen(false)
    if (buttonRef.current) buttonRef.current.focus()
  }

  const wrappedNext = () => {
    if (!user) {
      next()
    }
    else {
      next('confirm')
    }
  }

  const buttons = [
    { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-continue') },
  ]

  useEffect(() => {
    // Handle vehicle options from SAS here
    if (!petAvailable) setFieldValue('pet', false, false)
    if (!stationWagonAvailable) setFieldValue('stationWagon', false, false)
    if (numSeatsMax === 0) {
      setFieldValue('booster', 0, false)
    }
    else if (numSeatsMax > 0 && values.booster > numSeatsMax) {
      setFieldValue('booster', numSeatsMax, false)
    }
  }, [vehicleOptions])

  useEffect(() => {
    if (carTypeRef.current) carTypeRef.current.focus()
  }, [carTypeRef])

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <Notifications />
      <FormContent>
        <FormHeader>{t('header-how-do-you-want-to-go')}</FormHeader>
        <BookingSummary values={values} showHow />
      </FormContent>
      <div className={common.contentBottom}>
        <MotionElement.div className={common.group}>
          {fields.map((field, index) => <Field key={index} type="radio" name="serviceType" component={CarRadioField} {...field} />)}
        </MotionElement.div>
        <MotionElement.div className={common.group}>
          {/* NOTE: Override state fixedPrice if we got autoValues or no destination */} 
          <Field 
            field={{ 
              name: 'fixedPrice',
              onChange: handleChange,
              onBlur: handleBlur,
              value: fixedPrice
            }}
            title={t('title-fixed-price')} 
            disabled={disableFixedPrice} 
            tabIndex="0" 
            alternateBackground 
            component={CheckboxField}
          >
            {values.priceIsLoading && <Spinner />}
            <ErrorMessage name="fixedPrice" component={FormError} noMarginTop />
          </Field>
          <FieldButton title={t('title-booking-options')} info={t('info-booking-options')} innerRef={buttonRef} onClick={openPanel} disabled={values.serviceType === 'business'} alternateBackground />
        </MotionElement.div>
      </div>
      <ButtonDrawer buttons={buttons} />
      <OptionsPanel title={t('title-booking-options')} isOpen={isPanelOpen} onClose={closePanel}>
        <div className={cx(common.contentBottom)}>
          {petAvailable && <Field name="pet" tabIndex={isPanelOpen ? 0 : -1} title={t('title-pet-option')} innerRef={checkboxRef} alternateBackground component={CheckboxField} />}
          {stationWagonAvailable && <Field name="stationWagon" tabIndex={isPanelOpen ? 0 : -1} title={t('title-stationwagon-option')} alternateBackground component={CheckboxField} />}
          {rearFacingChildSeatAvailable && <FieldWrapper alternateBackground>
            <TitleInfo title={t('title-childseat-option')} info={t('info-childseat-option')} />
          </FieldWrapper>}
          <Field name="booster" tabIndex={isPanelOpen ? 0 : -1} title={t('title-booster-option')} info={t('info-booster-option')} numSeatsMax={numSeatsMax} alternateBackground component={BoosterField} />
        </div>
      </OptionsPanel>
    </motion.div>
  )
}

SelectHow.validationSchema = null

export default SelectHow
