import { useContext, useEffect, useState, useRef } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { FormContent, FormHeader, FormError, CodeInput, ButtonDrawer } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { validatePhoneOtp, resendPhoneOtp, isBadState } from '~/lib/api'
import * as common from '~/config/common.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation, Trans } from 'react-i18next'

export default function ValidatePhone() {
  const { t } = useTranslation()
  const inputRef = useRef()
  const { reset, next } = useContext(StepperContext)
  const { values, initialValues, setFieldValue, setFieldError, setFieldTouched } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const [isButtonLoading, setButtonIsLoading] = useState(false)
  const [activationBadState, setActivationBadState] = useState(false)

  const wrappedReset = () => {
    for (const [key, value] of Object.entries(initialValues)) {
      setFieldValue(key, value, false)
    }
    reset()
  }

  const makeRequest = () => {
    setIsLoading(true)
    validatePhoneOtp(values.phoneCode).then((response) => {
      console.log(response)
      if (response?.shouldConsentToMarketing !== undefined) {
        setFieldValue('shouldConsentToMarketing', response.shouldConsentToMarketing, false)
      }
      if (response?.shouldConsentToTermsAndConditions !== undefined) {
        setFieldValue('shouldConsentToTermsAndConditions', response.shouldConsentToTermsAndConditions, false)
      }
      if (response?.deviceActivationState === 'PHONE_OTP_COMPLETE_EXISTING_DEVICE') {
        next('acceptTerms')
      }
      else if (response?.deviceActivationState === 'PHONE_OTP_COMPLETE_NEW_USER') {
        next('inputEmail')
      }
      else if (response?.deviceActivationState === 'EMAIL_OTP_INITIATED') {
        setFieldValue('maskedEmail', response.maskedEmail)
        next('validateEmail')
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('phoneCode', error?.response?.message)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const makeNewRequest = () => {
    setButtonIsLoading(true)
    resendPhoneOtp().then((response) => {
      console.log(response)
      setFieldValue('phoneCode', '')
      inputRef.current?.focus()
    })
    .catch((error) => {
      console.error(error)
      setFieldTouched('phoneCode', true, true)
      // NOTE: Workaround to force the field to show the error
      setTimeout(() => setFieldError('phoneCode', error?.response?.message), 100)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setButtonIsLoading(false)
      setButtonDisabled(true)
    })
  }

  const buttons = [
    { func: wrappedReset, isBack: true },
    { func: makeNewRequest, title: t('button-did-not-get-code'), disabled: isButtonDisabled || activationBadState, isLoading: isButtonLoading },
  ]

  useEffect(() => {
    let timeoutId
    if (isButtonDisabled) {
      timeoutId = setTimeout(() => setButtonDisabled(false), 5000)
    }
    return () => clearTimeout(timeoutId)
  }, [isButtonDisabled])

  useEffect(() => {
    if (values.phoneCode.length === 4) {
      console.log('Phone OTP:', values.phoneCode)
      makeRequest()
    }
  }, [values.phoneCode])


  return (
    <>
      <FormContent isLoading={isLoading || isButtonLoading}>
        <FormHeader isLoading={isLoading}>{t('header-enter-your-code')}</FormHeader>
        <MotionElement.div className={common.content}>
          <div>
            <Field name="phoneCode" inputRef={inputRef} component={CodeInput} />
            <ErrorMessage name="phoneCode" component={FormError} />
          </div>
          <div className={common.fieldDescription}>
            <Trans 
              i18nKey="info-enter-the-code-sent-to" 
              values={{ dialCode: values.dialCode, phone: values.phone }} 
            />
          </div>
        </MotionElement.div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading || isButtonLoading} />
    </> 
  )
}
