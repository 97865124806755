import { useEffect, useState, useRef } from 'react'
import classnames from 'classnames/bind'
import { FieldWrapper, TitleInfo } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function FieldDropDown({ title, reverseLayout, alternateBackground, isDisabled, children }) {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef()
  const buttonRef = useRef()

  const handleFocus = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) close()
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const close = () => {
    if (buttonRef.current) buttonRef.current.focus()
    setIsOpen(false)
  }

  const onClick = (e) => {
    if (e.target.tagName === 'LABEL') close()
  }

  const onKeyUp = e => {
    if (e.keyCode === 32 && e.target.tagName === 'LABEL') close()
  }

  useEffect(() => {
    const removeListeners = () => {
      document.removeEventListener('click', handleFocus)
      document.removeEventListener('focusin', handleFocus)
    }
    if (isOpen) {
      document.addEventListener('click', handleFocus)
      document.addEventListener('focusin', handleFocus)
    }
    else {
      removeListeners()
    }
    return removeListeners
  }, [isOpen])

  return (
    <div ref={wrapperRef} className={cx(styles.wrapper, { isOpen })}>
      <FieldWrapper reverseLayout={reverseLayout} alternateBackground={alternateBackground} isDisabled={isDisabled} onClick={toggle}>
        <TitleInfo title={title} />
        <button 
          type="button" 
          className={styles.button} 
          onClick={toggle} 
          ref={buttonRef}
        />
      </FieldWrapper>
      <div className={styles.dropDown} onClick={onClick} onKeyUp={onKeyUp}>{children}</div>
    </div>
  )
}
