export default [
  {
    "code": "SE",
    "country": "Sweden",
    "dialCode": "+46"
  },
  {
    "code": "DK",
    "country": "Denmark",
    "dialCode": "+45"
  },
  {
    "code": "NO",
    "country": "Norway",
    "dialCode": "+47"
  },
  {
    "code": "FI",
    "country": "Finland",
    "dialCode": "+358"
  },
  {
    "code": "DE",
    "country": "Germany",
    "dialCode": "+49"
  },
  {
    "code": "AF",
    "country": "Afghanistan",
    "dialCode": "+93"
  },
  {
    "code": "AX",
    "country": "Aland Islands",
    "dialCode": "+358"
  },
  {
    "code": "AL",
    "country": "Albania",
    "dialCode": "+355"
  },
  {
    "code": "DZ",
    "country": "Algeria",
    "dialCode": "+213"
  },
  {
    "code": "AS",
    "country": "AmericanSamoa",
    "dialCode": "+1684"
  },
  {
    "code": "AD",
    "country": "Andorra",
    "dialCode": "+376"
  },
  {
    "code": "AO",
    "country": "Angola",
    "dialCode": "+244"
  },
  {
    "code": "AI",
    "country": "Anguilla",
    "dialCode": "+1264"
  },
  {
    "code": "AQ",
    "country": "Antarctica",
    "dialCode": "+672"
  },
  {
    "code": "AG",
    "country": "Antigua and Barbuda",
    "dialCode": "+1268"
  },
  {
    "code": "AR",
    "country": "Argentina",
    "dialCode": "+54"
  },
  {
    "code": "AM",
    "country": "Armenia",
    "dialCode": "+374"
  },
  {
    "code": "AW",
    "country": "Aruba",
    "dialCode": "+297"
  },
  {
    "code": "AC",
    "country": "Ascension Island",
    "dialCode": "+247"
  },
  {
    "code": "AU",
    "country": "Australia",
    "dialCode": "+61"
  },
  {
    "code": "AT",
    "country": "Austria",
    "dialCode": "+43"
  },
  {
    "code": "AZ",
    "country": "Azerbaijan",
    "dialCode": "+994"
  },
  {
    "code": "BS",
    "country": "Bahamas",
    "dialCode": "+1242"
  },
  {
    "code": "BH",
    "country": "Bahrain",
    "dialCode": "+973"
  },
  {
    "code": "BD",
    "country": "Bangladesh",
    "dialCode": "+880"
  },
  {
    "code": "BB",
    "country": "Barbados",
    "dialCode": "+1246"
  },
  {
    "code": "BY",
    "country": "Belarus",
    "dialCode": "+375"
  },
  {
    "code": "BE",
    "country": "Belgium",
    "dialCode": "+32"
  },
  {
    "code": "BZ",
    "country": "Belize",
    "dialCode": "+501"
  },
  {
    "code": "BJ",
    "country": "Benin",
    "dialCode": "+229"
  },
  {
    "code": "BM",
    "country": "Bermuda",
    "dialCode": "+1441"
  },
  {
    "code": "BT",
    "country": "Bhutan",
    "dialCode": "+975"
  },
  {
    "code": "BO",
    "country": "Bolivia",
    "dialCode": "+591"
  },
  {
    "code": "BA",
    "country": "Bosnia and Herzegovina",
    "dialCode": "+387"
  },
  {
    "code": "BW",
    "country": "Botswana",
    "dialCode": "+267"
  },
  {
    "code": "BR",
    "country": "Brazil",
    "dialCode": "+55"
  },
  {
    "code": "IO",
    "country": "British Indian Ocean Territory",
    "dialCode": "+246"
  },
  {
    "code": "BN",
    "country": "Brunei Darussalam",
    "dialCode": "+673"
  },
  {
    "code": "BG",
    "country": "Bulgaria",
    "dialCode": "+359"
  },
  {
    "code": "BF",
    "country": "Burkina Faso",
    "dialCode": "+226"
  },
  {
    "code": "BI",
    "country": "Burundi",
    "dialCode": "+257"
  },
  {
    "code": "KH",
    "country": "Cambodia",
    "dialCode": "+855"
  },
  {
    "code": "CM",
    "country": "Cameroon",
    "dialCode": "+237"
  },
  {
    "code": "CA",
    "country": "Canada",
    "dialCode": "+1"
  },
  {
    "code": "CV",
    "country": "Cape Verde",
    "dialCode": "+238"
  },
  {
    "code": "KY",
    "country": "Cayman Islands",
    "dialCode": "+1345"
  },
  {
    "code": "CF",
    "country": "Central African Republic",
    "dialCode": "+236"
  },
  {
    "code": "TD",
    "country": "Chad",
    "dialCode": "+235"
  },
  {
    "code": "CL",
    "country": "Chile",
    "dialCode": "+56"
  },
  {
    "code": "CN",
    "country": "China",
    "dialCode": "+86"
  },
  {
    "code": "CX",
    "country": "Christmas Island",
    "dialCode": "+61"
  },
  {
    "code": "CC",
    "country": "Cocos (Keeling) Islands",
    "dialCode": "+61"
  },
  {
    "code": "CO",
    "country": "Colombia",
    "dialCode": "+57"
  },
  {
    "code": "KM",
    "country": "Comoros",
    "dialCode": "+269"
  },
  {
    "code": "CG",
    "country": "Congo",
    "dialCode": "+242"
  },
  {
    "code": "CK",
    "country": "Cook Islands",
    "dialCode": "+682"
  },
  {
    "code": "CR",
    "country": "Costa Rica",
    "dialCode": "+506"
  },
  {
    "code": "CI",
    "country": "Cote d'Ivoire",
    "dialCode": "+225"
  },
  {
    "code": "HR",
    "country": "Croatia",
    "dialCode": "+385"
  },
  {
    "code": "CU",
    "country": "Cuba",
    "dialCode": "+53"
  },
  {
    "code": "CY",
    "country": "Cyprus",
    "dialCode": "+357"
  },
  {
    "code": "CZ",
    "country": "Czech Republic",
    "dialCode": "+420"
  },
  {
    "code": "CD",
    "country": "Democratic Republic of the Congo",
    "dialCode": "+243"
  },
  {
    "code": "DJ",
    "country": "Djibouti",
    "dialCode": "+253"
  },
  {
    "code": "DM",
    "country": "Dominica",
    "dialCode": "+1767"
  },
  {
    "code": "DO",
    "country": "Dominican Republic",
    "dialCode": "+1849"
  },
  {
    "code": "EC",
    "country": "Ecuador",
    "dialCode": "+593"
  },
  {
    "code": "EG",
    "country": "Egypt",
    "dialCode": "+20"
  },
  {
    "code": "SV",
    "country": "El Salvador",
    "dialCode": "+503"
  },
  {
    "code": "GQ",
    "country": "Equatorial Guinea",
    "dialCode": "+240"
  },
  {
    "code": "ER",
    "country": "Eritrea",
    "dialCode": "+291"
  },
  {
    "code": "EE",
    "country": "Estonia",
    "dialCode": "+372"
  },
  {
    "code": "SZ",
    "country": "Eswatini",
    "dialCode": "+268"
  },
  {
    "code": "ET",
    "country": "Ethiopia",
    "dialCode": "+251"
  },
  {
    "code": "FK",
    "country": "Falkland Islands (Malvinas)",
    "dialCode": "+500"
  },
  {
    "code": "FO",
    "country": "Faroe Islands",
    "dialCode": "+298"
  },
  {
    "code": "FJ",
    "country": "Fiji",
    "dialCode": "+679"
  },
  {
    "code": "FR",
    "country": "France",
    "dialCode": "+33"
  },
  {
    "code": "GF",
    "country": "French Guiana",
    "dialCode": "+594"
  },
  {
    "code": "PF",
    "country": "French Polynesia",
    "dialCode": "+689"
  },
  {
    "code": "GA",
    "country": "Gabon",
    "dialCode": "+241"
  },
  {
    "code": "GM",
    "country": "Gambia",
    "dialCode": "+220"
  },
  {
    "code": "GE",
    "country": "Georgia",
    "dialCode": "+995"
  },
  {
    "code": "GH",
    "country": "Ghana",
    "dialCode": "+233"
  },
  {
    "code": "GI",
    "country": "Gibraltar",
    "dialCode": "+350"
  },
  {
    "code": "GR",
    "country": "Greece",
    "dialCode": "+30"
  },
  {
    "code": "GL",
    "country": "Greenland",
    "dialCode": "+299"
  },
  {
    "code": "GD",
    "country": "Grenada",
    "dialCode": "+1473"
  },
  {
    "code": "GP",
    "country": "Guadeloupe",
    "dialCode": "+590"
  },
  {
    "code": "GU",
    "country": "Guam",
    "dialCode": "+1671"
  },
  {
    "code": "GT",
    "country": "Guatemala",
    "dialCode": "+502"
  },
  {
    "code": "GG",
    "country": "Guernsey",
    "dialCode": "+44"
  },
  {
    "code": "GN",
    "country": "Guinea",
    "dialCode": "+224"
  },
  {
    "code": "GW",
    "country": "Guinea-Bissau",
    "dialCode": "+245"
  },
  {
    "code": "GY",
    "country": "Guyana",
    "dialCode": "+592"
  },
  {
    "code": "HT",
    "country": "Haiti",
    "dialCode": "+509"
  },
  {
    "code": "VA",
    "country": "Holy See (Vatican City State)",
    "dialCode": "+379"
  },
  {
    "code": "HN",
    "country": "Honduras",
    "dialCode": "+504"
  },
  {
    "code": "HK",
    "country": "Hong Kong",
    "dialCode": "+852"
  },
  {
    "code": "HU",
    "country": "Hungary",
    "dialCode": "+36"
  },
  {
    "code": "IS",
    "country": "Iceland",
    "dialCode": "+354"
  },
  {
    "code": "IN",
    "country": "India",
    "dialCode": "+91"
  },
  {
    "code": "ID",
    "country": "Indonesia",
    "dialCode": "+62"
  },
  {
    "code": "IR",
    "country": "Iran",
    "dialCode": "+98"
  },
  {
    "code": "IQ",
    "country": "Iraq",
    "dialCode": "+964"
  },
  {
    "code": "IE",
    "country": "Ireland",
    "dialCode": "+353"
  },
  {
    "code": "IM",
    "country": "Isle of Man",
    "dialCode": "+44"
  },
  {
    "code": "IL",
    "country": "Israel",
    "dialCode": "+972"
  },
  {
    "code": "IT",
    "country": "Italy",
    "dialCode": "+39"
  },
  {
    "code": "JM",
    "country": "Jamaica",
    "dialCode": "+1876"
  },
  {
    "code": "JP",
    "country": "Japan",
    "dialCode": "+81"
  },
  {
    "code": "JE",
    "country": "Jersey",
    "dialCode": "+44"
  },
  {
    "code": "JO",
    "country": "Jordan",
    "dialCode": "+962"
  },
  {
    "code": "KZ",
    "country": "Kazakhstan",
    "dialCode": "+77"
  },
  {
    "code": "KE",
    "country": "Kenya",
    "dialCode": "+254"
  },
  {
    "code": "KI",
    "country": "Kiribati",
    "dialCode": "+686"
  },
  {
    "code": "KP",
    "country": "Korea, Democratic People's Republic of Korea",
    "dialCode": "+850"
  },
  {
    "code": "KR",
    "country": "Korea, Republic of South Korea",
    "dialCode": "+82"
  },
  {
    "code": "XK",
    "country": "Kosovo",
    "dialCode": "+383"
  },
  {
    "code": "KW",
    "country": "Kuwait",
    "dialCode": "+965"
  },
  {
    "code": "KG",
    "country": "Kyrgyzstan",
    "dialCode": "+996"
  },
  {
    "code": "LA",
    "country": "Laos",
    "dialCode": "+856"
  },
  {
    "code": "LV",
    "country": "Latvia",
    "dialCode": "+371"
  },
  {
    "code": "LB",
    "country": "Lebanon",
    "dialCode": "+961"
  },
  {
    "code": "LS",
    "country": "Lesotho",
    "dialCode": "+266"
  },
  {
    "code": "LR",
    "country": "Liberia",
    "dialCode": "+231"
  },
  {
    "code": "LY",
    "country": "Libya",
    "dialCode": "+218"
  },
  {
    "code": "LI",
    "country": "Liechtenstein",
    "dialCode": "+423"
  },
  {
    "code": "LT",
    "country": "Lithuania",
    "dialCode": "+370"
  },
  {
    "code": "LU",
    "country": "Luxembourg",
    "dialCode": "+352"
  },
  {
    "code": "MO",
    "country": "Macau",
    "dialCode": "+853"
  },
  {
    "code": "MG",
    "country": "Madagascar",
    "dialCode": "+261"
  },
  {
    "code": "MW",
    "country": "Malawi",
    "dialCode": "+265"
  },
  {
    "code": "MY",
    "country": "Malaysia",
    "dialCode": "+60"
  },
  {
    "code": "MV",
    "country": "Maldives",
    "dialCode": "+960"
  },
  {
    "code": "ML",
    "country": "Mali",
    "dialCode": "+223"
  },
  {
    "code": "MT",
    "country": "Malta",
    "dialCode": "+356"
  },
  {
    "code": "MH",
    "country": "Marshall Islands",
    "dialCode": "+692"
  },
  {
    "code": "MQ",
    "country": "Martinique",
    "dialCode": "+596"
  },
  {
    "code": "MR",
    "country": "Mauritania",
    "dialCode": "+222"
  },
  {
    "code": "MU",
    "country": "Mauritius",
    "dialCode": "+230"
  },
  {
    "code": "YT",
    "country": "Mayotte",
    "dialCode": "+262"
  },
  {
    "code": "MX",
    "country": "Mexico",
    "dialCode": "+52"
  },
  {
    "code": "FM",
    "country": "Micronesia, Federated States of Micronesia",
    "dialCode": "+691"
  },
  {
    "code": "MD",
    "country": "Moldova",
    "dialCode": "+373"
  },
  {
    "code": "MC",
    "country": "Monaco",
    "dialCode": "+377"
  },
  {
    "code": "MN",
    "country": "Mongolia",
    "dialCode": "+976"
  },
  {
    "code": "ME",
    "country": "Montenegro",
    "dialCode": "+382"
  },
  {
    "code": "MS",
    "country": "Montserrat",
    "dialCode": "+1664"
  },
  {
    "code": "MA",
    "country": "Morocco",
    "dialCode": "+212"
  },
  {
    "code": "MZ",
    "country": "Mozambique",
    "dialCode": "+258"
  },
  {
    "code": "MM",
    "country": "Myanmar",
    "dialCode": "+95"
  },
  {
    "code": "NA",
    "country": "Namibia",
    "dialCode": "+264"
  },
  {
    "code": "NR",
    "country": "Nauru",
    "dialCode": "+674"
  },
  {
    "code": "NP",
    "country": "Nepal",
    "dialCode": "+977"
  },
  {
    "code": "NL",
    "country": "Netherlands",
    "dialCode": "+31"
  },
  {
    "code": "NC",
    "country": "New Caledonia",
    "dialCode": "+687"
  },
  {
    "code": "NZ",
    "country": "New Zealand",
    "dialCode": "+64"
  },
  {
    "code": "NI",
    "country": "Nicaragua",
    "dialCode": "+505"
  },
  {
    "code": "NE",
    "country": "Niger",
    "dialCode": "+227"
  },
  {
    "code": "NG",
    "country": "Nigeria",
    "dialCode": "+234"
  },
  {
    "code": "NU",
    "country": "Niue",
    "dialCode": "+683"
  },
  {
    "code": "NF",
    "country": "Norfolk Island",
    "dialCode": "+672"
  },
  {
    "code": "MK",
    "country": "North Macedonia",
    "dialCode": "+389"
  },
  {
    "code": "MP",
    "country": "Northern Mariana Islands",
    "dialCode": "+1670"
  },
  {
    "code": "OM",
    "country": "Oman",
    "dialCode": "+968"
  },
  {
    "code": "PK",
    "country": "Pakistan",
    "dialCode": "+92"
  },
  {
    "code": "PW",
    "country": "Palau",
    "dialCode": "+680"
  },
  {
    "code": "PS",
    "country": "Palestine",
    "dialCode": "+970"
  },
  {
    "code": "PA",
    "country": "Panama",
    "dialCode": "+507"
  },
  {
    "code": "PG",
    "country": "Papua New Guinea",
    "dialCode": "+675"
  },
  {
    "code": "PY",
    "country": "Paraguay",
    "dialCode": "+595"
  },
  {
    "code": "PE",
    "country": "Peru",
    "dialCode": "+51"
  },
  {
    "code": "PH",
    "country": "Philippines",
    "dialCode": "+63"
  },
  {
    "code": "PN",
    "country": "Pitcairn",
    "dialCode": "+872"
  },
  {
    "code": "PL",
    "country": "Poland",
    "dialCode": "+48"
  },
  {
    "code": "PT",
    "country": "Portugal",
    "dialCode": "+351"
  },
  {
    "code": "PR",
    "country": "Puerto Rico",
    "dialCode": "+1939"
  },
  {
    "code": "QA",
    "country": "Qatar",
    "dialCode": "+974"
  },
  {
    "code": "RE",
    "country": "Reunion",
    "dialCode": "+262"
  },
  {
    "code": "RO",
    "country": "Romania",
    "dialCode": "+40"
  },
  {
    "code": "RU",
    "country": "Russia",
    "dialCode": "+7"
  },
  {
    "code": "RW",
    "country": "Rwanda",
    "dialCode": "+250"
  },
  {
    "code": "BL",
    "country": "Saint Barthelemy",
    "dialCode": "+590"
  },
  {
    "code": "SH",
    "country": "Saint Helena, Ascension and Tristan Da Cunha",
    "dialCode": "+290"
  },
  {
    "code": "KN",
    "country": "Saint Kitts and Nevis",
    "dialCode": "+1869"
  },
  {
    "code": "LC",
    "country": "Saint Lucia",
    "dialCode": "+1758"
  },
  {
    "code": "MF",
    "country": "Saint Martin",
    "dialCode": "+590"
  },
  {
    "code": "PM",
    "country": "Saint Pierre and Miquelon",
    "dialCode": "+508"
  },
  {
    "code": "VC",
    "country": "Saint Vincent and the Grenadines",
    "dialCode": "+1784"
  },
  {
    "code": "WS",
    "country": "Samoa",
    "dialCode": "+685"
  },
  {
    "code": "SM",
    "country": "San Marino",
    "dialCode": "+378"
  },
  {
    "code": "ST",
    "country": "Sao Tome and Principe",
    "dialCode": "+239"
  },
  {
    "code": "SA",
    "country": "Saudi Arabia",
    "dialCode": "+966"
  },
  {
    "code": "SN",
    "country": "Senegal",
    "dialCode": "+221"
  },
  {
    "code": "RS",
    "country": "Serbia",
    "dialCode": "+381"
  },
  {
    "code": "SC",
    "country": "Seychelles",
    "dialCode": "+248"
  },
  {
    "code": "SL",
    "country": "Sierra Leone",
    "dialCode": "+232"
  },
  {
    "code": "SG",
    "country": "Singapore",
    "dialCode": "+65"
  },
  {
    "code": "SX",
    "country": "Sint Maarten",
    "dialCode": "+1721"
  },
  {
    "code": "SK",
    "country": "Slovakia",
    "dialCode": "+421"
  },
  {
    "code": "SI",
    "country": "Slovenia",
    "dialCode": "+386"
  },
  {
    "code": "SB",
    "country": "Solomon Islands",
    "dialCode": "+677"
  },
  {
    "code": "SO",
    "country": "Somalia",
    "dialCode": "+252"
  },
  {
    "code": "ZA",
    "country": "South Africa",
    "dialCode": "+27"
  },
  {
    "code": "GS",
    "country": "South Georgia and the South Sandwich Islands",
    "dialCode": "+500"
  },
  {
    "code": "SS",
    "country": "South Sudan",
    "dialCode": "+211"
  },
  {
    "code": "ES",
    "country": "Spain",
    "dialCode": "+34"
  },
  {
    "code": "LK",
    "country": "Sri Lanka",
    "dialCode": "+94"
  },
  {
    "code": "SD",
    "country": "Sudan",
    "dialCode": "+249"
  },
  {
    "code": "SR",
    "country": "Suriname",
    "dialCode": "+597"
  },
  {
    "code": "SJ",
    "country": "Svalbard and Jan Mayen",
    "dialCode": "+47"
  },
  {
    "code": "CH",
    "country": "Switzerland",
    "dialCode": "+41"
  },
  {
    "code": "SY",
    "country": "Syrian Arab Republic",
    "dialCode": "+963"
  },
  {
    "code": "TW",
    "country": "Taiwan",
    "dialCode": "+886"
  },
  {
    "code": "TJ",
    "country": "Tajikistan",
    "dialCode": "+992"
  },
  {
    "code": "TZ",
    "country": "Tanzania, United Republic of Tanzania",
    "dialCode": "+255"
  },
  {
    "code": "TH",
    "country": "Thailand",
    "dialCode": "+66"
  },
  {
    "code": "TL",
    "country": "Timor-Leste",
    "dialCode": "+670"
  },
  {
    "code": "TG",
    "country": "Togo",
    "dialCode": "+228"
  },
  {
    "code": "TK",
    "country": "Tokelau",
    "dialCode": "+690"
  },
  {
    "code": "TO",
    "country": "Tonga",
    "dialCode": "+676"
  },
  {
    "code": "TT",
    "country": "Trinidad and Tobago",
    "dialCode": "+1868"
  },
  {
    "code": "TN",
    "country": "Tunisia",
    "dialCode": "+216"
  },
  {
    "code": "TR",
    "country": "Turkey",
    "dialCode": "+90"
  },
  {
    "code": "TM",
    "country": "Turkmenistan",
    "dialCode": "+993"
  },
  {
    "code": "TC",
    "country": "Turks and Caicos Islands",
    "dialCode": "+1649"
  },
  {
    "code": "TV",
    "country": "Tuvalu",
    "dialCode": "+688"
  },
  {
    "code": "UG",
    "country": "Uganda",
    "dialCode": "+256"
  },
  {
    "code": "UA",
    "country": "Ukraine",
    "dialCode": "+380"
  },
  {
    "code": "AE",
    "country": "United Arab Emirates",
    "dialCode": "+971"
  },
  {
    "code": "GB",
    "country": "United Kingdom",
    "dialCode": "+44"
  },
  {
    "code": "US",
    "country": "United States",
    "dialCode": "+1"
  },
  {
    "code": "UY",
    "country": "Uruguay",
    "dialCode": "+598"
  },
  {
    "code": "UZ",
    "country": "Uzbekistan",
    "dialCode": "+998"
  },
  {
    "code": "VU",
    "country": "Vanuatu",
    "dialCode": "+678"
  },
  {
    "code": "VE",
    "country": "Venezuela, Bolivarian Republic of Venezuela",
    "dialCode": "+58"
  },
  {
    "code": "VN",
    "country": "Vietnam",
    "dialCode": "+84"
  },
  {
    "code": "VG",
    "country": "Virgin Islands, British",
    "dialCode": "+1284"
  },
  {
    "code": "VI",
    "country": "Virgin Islands, U.S.",
    "dialCode": "+1340"
  },
  {
    "code": "WF",
    "country": "Wallis and Futuna",
    "dialCode": "+681"
  },
  {
    "code": "YE",
    "country": "Yemen",
    "dialCode": "+967"
  },
  {
    "code": "ZM",
    "country": "Zambia",
    "dialCode": "+260"
  },
  {
    "code": "ZW",
    "country": "Zimbabwe",
    "dialCode": "+263"
  }
]
