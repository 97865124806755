import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import * as styles from './index.module.css'


const cx = classnames.bind(styles)

export default function FormContent({ isLoading, children }) {
  return (
    <motion.div layout transition={{duration:.18}} className={cx(styles.wrapper, { isLoading })}>
      {children}
    </motion.div>
  )
}
