.subHeader {
  color: black;
  font-size: 13px;
}

.info {
  padding: var(--spacing) calc(var(--spacing) * 2);
}

.info, .instructions {
  color: var(--color-black-50);
  font-size: 13px;

  & > a {
    color: var(--color-black-50);

    &:focus-visible {
      color: black;
      outline: none;
    }

    @media (--hover) {
      &:hover {
        color: black;
      }
    }
  }
}

.marginTop {
  margin-top: var(--spacing);
}

.message {
  padding: calc(var(--spacing) * 2);
}

.panelPadding {
  padding: calc(var(--spacing) * 2);
}

.panelGray {
  background: var(--color-cool-gray); 
}

.button {
  color: var(--color-black-50);
  font-size: 13px;
  text-decoration: underline;

  &:focus-visible {
    color: black;
  }

  @media (--hover) {
    &:hover {
      color: black;
    }
  }
}
