.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 20px;
  background: var(--color-black-10);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid var(--color-border-gray);
    transition: transform 0.2s ease;
  }

  &.isChecked {
    background: var(--color-yellow);
    transition: background-color 0.3s ease;

    &:after {
      transition: transform 0.2s ease;
      transform: translateX(100%);
    }
  }

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::before {
    content: '';
    position: absolute;
    display: block;
    width: 44px;
    height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 14px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px var(--color-yellow);
  }
}

.field {
  position: absolute;
  opacity: 0;
}

