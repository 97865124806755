.info {
  color: var(--color-black);
  font-size: 13px;
  line-height: 1.23em;
  margin-top: calc(var(--spacing) * 3);

  & p {
    font-size: 13px;
    line-height: 1.23em;
  }

  & p + p {
    margin-top: 1.23em;
  }

  & a {
    color: var(--color-black);
  }
}

.hideOverflow {
  overflow: hidden;
}
