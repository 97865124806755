import { useContext, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { StepperContext } from '~/components/ui/ViewStepper'
import { CloseButton } from '~/components'
import { useTranslation } from 'react-i18next'

const hideOnId = ['locations'] // ID:s for views where this button should be hidden
const noModalOnId = ['done', 'inputPhone'] // ID:s for views where the confirm modal should be skipped

export default function ResetButton({ message }) {
  const { t } = useTranslation()
  const { id, reset: resetStepper } = useContext(StepperContext)
  const { resetForm } = useFormikContext()
  const ref = useRef()

  const reset = () => {
    resetForm()
    resetStepper()
  }

  const onClick = () => {
    if (noModalOnId.includes(id)) {
      reset()
    }
    else {
      APP.trigger(
        APP.events.SHOW_MODAL,
          message, [
          { title: t('button-yes'), callback: reset },
          { title: t('button-no') },
        ]
      )
    }
  }

  useEffect(() => {
    if (ref.current) {
      const margin = ref.current?.parentElement?.querySelector('.notifications')?.offsetHeight || 0
      ref.current.style.marginTop = `${margin}px`
    }
  }, [ref, id])

  // Do not show on some id:s
  if (hideOnId.includes(id)) return null

  return (
    <CloseButton innerRef={ref} onClick={onClick} />
  )
}
