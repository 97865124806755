.wrapper {
  background: #FFF;
  padding: var(--padding-small);

  &.isLoading {
    pointer-events: none;
  }

  @media (--lg) {
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  }
}

.buttons {
  display: flex;
  gap: var(--spacing);
  align-items: start;
}

.button { 
  width: 100%;
  height: 56px;
  padding: 0 16px;
  color: black;
  background-color: var(--color-black-10);
  border-radius: var(--border-radius);
  transition: background-color 0.4s;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  line-height: 1.1em;

  &:focus-visible {
    background-color: var(--color-black-20);
  }

  @media (--hover) {
    &:hover {
      background-color: var(--color-black-20);
    }
  }

  &.isPrimary {
    background-color: var(--color-yellow);

    &:focus-visible {
      background-color: var(--color-dark-yellow);
    }

    @media (--hover) {
      &:hover:not(.isLoading) {
        background-color: var(--color-dark-yellow);
      }
    }

    &:disabled {
      color: var(--color-black-20);
    }
  }

  &.isBack {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 1.5L1.5 6.5L6.5 11.5' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
  }

  &.isLoading {
    cursor: default;
    color: transparent !important;
    user-select: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='64' height='64' viewBox='0 0 128 128'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z'/%3E%3Cpath d='M92.19 6.374l6.928 4-20 34.641-6.929-4 20-34.64zM117.226 28.19l4 6.928-34.641 20-4-6.929 34.64-20zM128 59.6v8H88v-8h40zM121.626 92.19l-4 6.928-34.641-20 4-6.929 34.64 20z' fill='%23ccc' fill-opacity='.2'/%3E%3Cpath d='M99.81 117.226l-6.928 4-20-34.641 6.929-4 20 34.64z' fill='%23b2b2b2' fill-opacity='.3'/%3E%3Cpath d='M68.4 128h-8V88h8v40z' fill='%23999' fill-opacity='.4'/%3E%3Cpath d='M35.81 121.626l-6.928-4 20-34.641 6.929 4-20 34.64z' fill='%237f7f7f' fill-opacity='.5'/%3E%3Cpath d='M10.774 99.81l-4-6.928 34.641-20 4 6.929-34.64 20z' fill='%23666' fill-opacity='.6'/%3E%3Cpath d='M0 68.4v-8h40v8H0z' fill='%234c4c4c' fill-opacity='.7'/%3E%3Cpath d='M6.374 35.81l4-6.928 34.641 20-4 6.929-34.64-20z' fill='%23333' fill-opacity='.8'/%3E%3Cpath d='M28.19 10.774l6.928-4 20 34.641-6.929 4-20-34.64z' fill='%23191919' fill-opacity='.9'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    &:not(.isPrimary) {
      background-color: var(--color-cool-gray);
      color: var(--color-black-10);
    }
  }

}
