.subhead {
  font-size: 24px;

  @media (--lg) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--lg) {
    display: block;
  }
}

