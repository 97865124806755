import { useContext, useState } from 'react'
import { useFormikContext, Field } from 'formik'
import * as Yup from 'yup'
import { FormContent, FormHeader, InputField, ButtonDrawer } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { addEmail, isBadState } from '~/lib/api'
import * as common from '~/config/common.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const InputEmail = () => {
  const { t } = useTranslation()
  const { reset, next } = useContext(StepperContext)
  const { values, initialValues, setFieldValue, setFieldError } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [activationBadState, setActivationBadState] = useState(false)

  const wrappedReset = () => {
    for (const [key, value] of Object.entries(initialValues)) {
      setFieldValue(key, value, false)
    }
    reset()
  }

  const makeRequest = () => {
    setIsLoading(true)
    addEmail(values.email).then((response) => {
      console.log(response)
      if (response?.deviceActivationState === 'EMAIL_ADDED') {
        next('inputName')
      }
      else if (response?.deviceActivationState === 'EMAIL_OTP_INITIATED') {
        setFieldValue('maskedEmail', response.maskedEmail)
        next('validateEmail')
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('email', error?.response?.message)
      if (isBadState(error)) {
        setActivationBadState(true)
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const wrappedNext = wrapWithValidation(makeRequest, useFormikContext())

  const buttons = [
    { func: wrappedReset, isBack: true },
    { func: wrappedNext, title: t('button-continue'), disabled: activationBadState, isLoading },
  ]

  return (
    <>
      <FormContent isLoading={isLoading}>
        <FormHeader>{t('header-enter-your-email')}</FormHeader>
        <MotionElement.div className={common.content}>
          <Field name="email" placeholder={t('placeholder-enter-your-email')} component={InputField} />
          <div className={common.fieldDescription}>{t('info-we-use-your-email')}</div>
        </MotionElement.div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading} />
    </> 
  )
}

InputEmail.validationSchema = Yup.object({
  email: Yup.string()
    .email(i18next.t('validation-invalid-email'))
    .required(i18next.t('validation-need-email')),
})

export default InputEmail
