.alertItem {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: left;
  background: var(--color-yellow);

  & > p {
    padding: 25px 15px 25px 60px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: black;
  }

  @media (--lg) {
    text-align: center;
    margin-top: 0px;
    & > p {
      font-size: 16px;
      padding: 38px 75px;
    }
  }
}

.info {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 15px;
  width: 30px;
  height: 30px;
  background: url(/app/themes/taxi-stockholm/static/img/info.png);
  background-size: 30px 30px;

  @media (--lg) {
    left: 35px;
  }
}
