import * as Yup from 'yup'
import { useContext } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import { motion } from "framer-motion"
import * as MotionElement from '~/components/ui/MotionElement'
import { 
  FormContent, FormHeader, PackageBookingSummary, ButtonDrawer, PackageTypeField, FormError
} from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { StoreContext } from '~/store'
import { wrapWithValidation } from '~/utils'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import { useTranslation, Trans } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(common)

const SelectWhat = () => {
  const { t } = useTranslation()
  const { prev, next } = useContext(StepperContext)
  const { user } = useContext(StoreContext)
  const { values } = useFormikContext()
  
  const fields = [
    { type: 'ENVELOPE', title: t('title-ENVELOPE'), info: '', max: 100000 },
    { type: 'BOX', title: t('title-BOX'), info: t('weight-0-20'), max: 4 },
    { type: 'BAG', title: t('title-BAG'), info: t('weight-0-20'), max: 4 },
    { type: 'MOVING_BOX', title: t('title-MOVING_BOX'), info: t('weight-0-20'), max: 2 }
  ]

  const openModal = () => {
    APP.trigger(APP.events.SHOW_MODAL, i18next.t('info-delivery-terms'), undefined, true)
  }

  const wrappedNext = wrapWithValidation(() => {
    if (!user) {
      next()
    }
    else {
      next('sender')
    }
  }, useFormikContext())

  const buttons = [
    { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-continue') },
  ]

  const icons = ['no-animals.svg', 'no-dangerous-goods.svg', 'no-food.svg', 'no-tobacco.svg']

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <FormContent>
        <FormHeader>{t('header-what-would-you-like-to-send')}</FormHeader>
        <PackageBookingSummary values={values} />
      </FormContent>
      <div className={common.contentBottom}>
        {fields.map((field, index) => <Field key={index} name={field.type} component={PackageTypeField} {...field} />)}
        <div className={styles.error}>
          <ErrorMessage name="hasPackages" component={FormError} />
        </div>
        <div className={styles.info}>{t('info-delivery-categories')}</div>
      </div>
      <div className={cx(common.contentBottom, { largePadding: true })}>
        <div className={cx(common.fullWidth, common.smaller)}>
          <div className={styles.icons}>
            {icons.map((icon, index) => <img key={index} src={`/app/themes/taxi-stockholm/static/img/${icon}`} className={styles.icon} />)}
          </div>
          <Trans 
            i18nKey="info-delivery" 
            components={{
              button1: <button onClick={openModal} className={common.linkButton}/>
            }}
          />
        </div>
      </div>
      <ButtonDrawer buttons={buttons} />
    </motion.div>
  )
}

// SelectWhat.validationSchema = null
SelectWhat.validationSchema = Yup.object({
  ENVELOPE: Yup.number(),
  BOX: Yup.number(),
  BAG: Yup.number(),
  MOVING_BOX: Yup.number(),
  hasPackages: Yup.bool().when(['ENVELOPE', 'BOX', 'BAG', 'MOVING_BOX'], {
    is: (...args) => {
      const sum = args.reduce((acc, cur) => acc + cur)
      return isNaN(sum) || sum === 0
    },
    then: () => Yup.bool().required(i18next.t('validation-package-required')),
    otherwise: () => Yup.bool()
  })
})

export default SelectWhat
