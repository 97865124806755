import { useEffect, useState } from 'react'
import classnames from 'classnames/bind'
import { usePrevious } from '~/utils/hooks'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function CodeInput({ field, inputRef, form: { setFieldValue, setFieldTouched }}) {
  const [inputValue, setInputValue] = useState('')
  const previousValue = usePrevious(field.value)

  const onChange = e => {
    const value = e.target.value.replaceAll(/[^\d]/g, '')
    setInputValue(value)
    if (value.length === 4) {
      setFieldValue(field.name, value)
      setFieldTouched(field.name, true)
    }
  }

  const onKeyDown = e => {
    onFocus()
  }

  const setFocus = () => {
    inputRef.current?.focus()
  }

  const onFocus = () => {
    const end = inputRef.current?.value?.length || 0;
    inputRef.current?.setSelectionRange(end, end);
  }

  useEffect(() => {
    if (field.value === '' && previousValue !== '') {
      setInputValue('')
    }
  }, [field.value])
  
  useEffect(setFocus, [])

  return (
    <div className={styles.wrapper}>
      <input 
        type="text" 
        inputMode="numeric"
        autoComplete="one-time-code"
        autoFocus
        tabIndex="0" 
        maxLength="4" 
        ref={inputRef} 
        value={inputValue} 
        onChange={onChange} 
        onKeyDown={onKeyDown} 
        onFocus={onFocus} 
        className={styles.input} 
      />
      {[0, 1, 2, 3].map((value, index) => (
        <span key={index} onClick={setFocus} className={cx(styles.number, { hasFocus: index === inputValue.length, isLast: index === 3 && inputValue.length === 4 })}>
          <span className={styles.text}>{inputValue[index] || '\u00A0'}</span>
        </span>))}
    </div>
  )
}
