.wrapper {
  position: relative;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.input:focus ~ .number.hasFocus:after {
  display: block;
}

.input:focus ~ .number.isLast:after {
  display: block;
  left: 34px;
}

.number {
  position: relative;
  display: inline-flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  outline: none;
  background: white;
  border-radius: var(--border-radius);
  cursor: text;

  &:after {
    position: absolute;
    display: none;
    content: '';
    top: 18px;
    left: 28px;
    width: 1px;
    height: 20px;
    background: black;
    animation: cursor-blink 1.25s steps(1) infinite;
  }
}

.text {
  position: relative;
  font-size: 16px;
}

.number + .number {
  margin-left: var(--spacing);
}

@keyframes cursor-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
