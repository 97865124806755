import { useEffect, useRef } from 'react'

// NOTE: This component is used to set focus in a specific place on the page to have the first tab go to the next element
export default function FocusElement() {
  const buttonRef = useRef()

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [buttonRef])

  return (
    <button ref={buttonRef} style={{ position: 'absolute', opacity: 0 }} />
  )
}
