import classnames from 'classnames/bind'
import * as styles from './index.module.css'
import * as MotionElement from '~/components/ui/MotionElement'

const cx = classnames.bind(styles)

export default function FormHeader({ isLoading, className, children }) {
  return (
    <MotionElement.h3 className={cx(styles.wrapper, className, { isLoading })}>
      {children}
    </MotionElement.h3>
  )
}
