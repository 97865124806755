var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-use-before-define,no-use-before-define */
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { useFirstMountState } from "../useFirstMountState/useFirstMountState.js";
import { useMountEffect } from "../useMountEffect/useMountEffect.js";
import { useSafeState } from "../useSafeState/useSafeState.js";
import { useSyncedRef } from "../useSyncedRef/useSyncedRef.js";
import { isBrowser } from "../util/const.js";
var cookiesSetters = new Map();
var registerSetter = function (key, setter) {
    var setters = cookiesSetters.get(key);
    if (!setters) {
        setters = new Set();
        cookiesSetters.set(key, setters);
    }
    setters.add(setter);
};
var unregisterSetter = function (key, setter) {
    var setters = cookiesSetters.get(key);
    // almost impossible to test in normal situation
    /* istanbul ignore next */
    if (!setters)
        return;
    setters.delete(setter);
    if (!setters.size) {
        cookiesSetters.delete(key);
    }
};
var invokeRegisteredSetters = function (key, value, skipSetter) {
    var setters = cookiesSetters.get(key);
    // almost impossible to test in normal situation
    /* istanbul ignore next */
    if (!setters)
        return;
    setters.forEach(function (s) {
        if (s !== skipSetter)
            s(value);
    });
};
/**
 * Manages a single cookie.
 *
 * @param key Name of the cookie to manage.
 * @param options Cookie options that will be used during setting and deleting the cookie.
 */
export function useCookieValue(key, options) {
    if (options === void 0) { options = {}; }
    // no need to test it, dev-only notification about 3rd party library requirement
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'development' && Cookies === undefined) {
        throw new ReferenceError('Dependency `js-cookies` is not installed, it is required for `useCookieValue` work.');
    }
    // eslint-disable-next-line prefer-const
    var _a = options.initializeWithValue, initializeWithValue = _a === void 0 ? true : _a, cookiesOptions = __rest(options, ["initializeWithValue"]);
    if (!isBrowser) {
        initializeWithValue = false;
    }
    var methods = useSyncedRef({
        set: function (value) {
            setState(value);
            Cookies.set(key, value, cookiesOptions);
            // update all other hooks with the same key
            invokeRegisteredSetters(key, value, setState);
        },
        remove: function () {
            setState(null);
            Cookies.remove(key, cookiesOptions);
            invokeRegisteredSetters(key, null, setState);
        },
        fetchVal: function () { var _a; return (_a = Cookies.get(key)) !== null && _a !== void 0 ? _a : null; },
        fetch: function () {
            var val = methods.current.fetchVal();
            setState(val);
            invokeRegisteredSetters(key, val, setState);
        },
    });
    var isFirstMount = useFirstMountState();
    var _b = useSafeState(isFirstMount && initializeWithValue ? methods.current.fetchVal() : undefined), state = _b[0], setState = _b[1];
    useMountEffect(function () {
        if (!initializeWithValue) {
            methods.current.fetch();
        }
    });
    useEffect(function () {
        registerSetter(key, setState);
        return function () {
            unregisterSetter(key, setState);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
    return [
        state,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(function (value) { return methods.current.set(value); }, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(function () { return methods.current.remove(); }, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(function () { return methods.current.fetch(); }, []),
    ];
}
