import { useState } from 'react'
import classnames from 'classnames/bind'
import { ButtonDrawer, Modal } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function BasicModal({ title, message, buttons, isOpen, onClose, lightBackground, absolute }) {

  return (
    <>
      {isOpen && <Modal onClose={onClose} isOpen rounded absolute={absolute}>
        <div className={styles.wrapper}>
          <div className={styles.title}>{title}</div>
          {message && <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />}
        </div>
        <ButtonDrawer buttons={buttons} />
      </Modal>}
    </>
  )
}

