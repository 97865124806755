.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s var(--easeInOutQuad);
}

.isLoaded {
  opacity: 1;
}

.dropAnim {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

