.wrapper {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--padding-small);
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  &.isOpen {
    display: flex;
  }

  &.lightBackground {
    background: rgba(255, 255, 255, 0.5);
  }

  &.absolute {
    position: absolute;
  }

  &.rounded {
    border-radius: var(--border-radius-large);
  }
}

.container {
  position: relative;
  width: 100%;
  padding: 30px 0 0;
  background: #FFF;
  border-radius: var(--border-radius-large);
  overflow: hidden;

  @media (--md) {
    max-width: 420px;
  }
}

.lightBackground .container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
