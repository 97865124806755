.item {
  position: relative;
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.top {
  display: flex;
  height: 56px;
  padding: 0 18px;
  gap: 16px;
  align-items: center;
  color: white;
  background: black;
  font-size: 14px;
  line-height: 16px;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  cursor: pointer;
  user-select: none;
}

.bottom {
  display: flex;
  flex-direction: column;
  color: black;
  background: white;
  border-bottom-left-radius: var(--border-radius-large);
  border-bottom-right-radius: var(--border-radius-large);

  &.showBorder {
    border: 1px solid rgba(0, 0, 0, 0.10);
  }
}

.bottomClickable {
  cursor: pointer;
}

.stateIcon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  border: 1px solid var(--color-yellow);
  border-radius: 50%;

  &.prebooked {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/prebooked.png);
  }
  &.searching {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/searching.png);
  }
  &.cancelled {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/cancelled.png);
  }
  &.arriving {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/arriving.png);
  }
  &.in-car {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/in-car.png);
  }
  &.finished {
    background-image: url(/app/themes/taxi-stockholm/static/img/states/finished.png);
  }
}

.stateStatus {
  flex-grow: 1;
}

.taxiInfo {
  text-align: right;
}

.stopsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  padding: calc(var(--spacing) * 2) var(--padding);
  position: relative;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: var(--padding);
    left: calc(var(--padding) + 10px);
    width: 1px;
    height: calc(100% - (var(--padding) * 2));
    border-left: 1px dotted #A7A8A9;
  }
}

.stops {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}

.stop {
  font-size: 13px;
  line-height: 20px;
  padding-left: 30px;
}

.intermediate {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 7px;
    left: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #A7A8A9;
  }
}

.infoSection {
  padding: 0 var(--padding) var(--padding-small);
  font-size: 13px;
  line-height: 16px;
  color: var(--color-black-50);
}

.row {
  display: flex;
  padding: var(--padding-small) 0;
}

.row + .row {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.info {
  flex: 1;
  text-align: right;
  color: black;
}

.infoParts {
  display: block;
  font-size: 11px;
  color: var(--color-black-50);
}

.receiptButton {
  text-decoration: underline;

  &:hover {
    color: var(--color-black-50);
  }
}

.buttonRow {
  padding: var(--padding-small);
}

.isClosed {
  display: none;
}

.options {
  display: flex;
  flex: 1;
  justify-content: right;
  gap: 2px;
}

.option {
  color: black;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 11px;
  border-radius: var(--border-radius);
  /* padding: 4px 6px; */
  padding: 1px 8px 2px;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.error {
  display: flex;
  justify-content: center;
}

.spacer {
  height: 30px;
}

