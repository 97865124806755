.wrapper {
  background: var(--color-cool-gray);
  padding: var(--padding);

  &.isLoading {
    pointer-events: none;
  }

  @media (--lg) {
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  }
}
