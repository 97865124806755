import { lazy, Suspense } from 'react'
import * as styles from './index.module.css'

export function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export default function Flag({ code }) {
  if (navigator.userAgent.toLowerCase().indexOf('windows') !== -1) {
    return (
      <Suspense fallback={<Fallback />}>
        <SVGFlag code={code} />
      </Suspense>
    )
  }
  return <EmojiFlag code={code} />
}

const SVGFlag = lazy(() => import('./SVGFlag'))

const EmojiFlag = ({ code }) => {
  return <span className={styles.flag} dangerouslySetInnerHTML={{ __html: getFlagEmoji(code) }}></span>
}

const Fallback = () => {
  return <span className={styles.fallback} />
}
