.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 calc(var(--spacing) * 3) var(--spacing);
  gap: var(--spacing);
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: calc(var(--spacing) * 2);
}

.message {
  font-size: 13px;
}
