import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function ButtonDrawer({ buttons, isLoading, className, children}) {
  return (
    <div className={cx(styles.wrapper, className, { isLoading })}>
      {children}
      <div className={styles.buttons}>
        {buttons.map((button, index) => (
          <button 
            key={index} 
            type="button" 
            tabIndex={button.tabIndex !== undefined ? button.tabIndex : 0}
            onClick={button.func}
            className={cx(styles.button, { isPrimary: index === buttons.length - 1, isBack: button.isBack === true, isLoading: button.isLoading === true })}
            disabled={button?.disabled === true}
            dangerouslySetInnerHTML={{ __html: button.title }}
          />
        ))}
      </div>
    </div>
  )
}
