import { useEffect, useState } from 'react'
import { TimeWidget } from '~/components'
import * as styles from './index.module.css'

export default function TimePicker({ field, form: { setFieldValue }, minDate, maxDate, clampDate }) {
  const [selectedDate, setSelectedDate] = useState(field.value || minDate)

  const onDateChange = date => {
    const clamped = clampDate(date)
    setSelectedDate(clamped)
    setFieldValue(field.name, clamped)
  }

  useEffect(() => {
    if (field.value) setSelectedDate(field.value)
  }, [field.value])

  return (
    <div className={styles.time}>
      <TimeWidget 
        selected={selectedDate} 
        onChange={onDateChange} 
      />
    </div>
  )
}
