import { useContext, useEffect } from 'react'
import { useMediaQuery } from '@react-hookz/web/esnext'
import { StepperContext } from '~/components/ui/ViewStepper'
import { useDidMountEffect, usePrevious } from '~/utils/hooks'

export default function ViewListener({ onViewChange }) {
  const isMobile = useMediaQuery('only screen and (max-width: 969px)')
  const { id } = useContext(StepperContext)
  const prevId = usePrevious(id)

  useDidMountEffect(() => {
    if (isMobile && document.scrollingElement.scrollTop > 70) {
      document.scrollingElement.scrollTop = 70
    }
  }, [isMobile, id])

  useEffect(() => {
    if (prevId && id !== prevId) {
      if (onViewChange) onViewChange(id, prevId)
    }
  }, [id, prevId])
}
