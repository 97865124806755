import { useContext, useState } from 'react'
import { useFormikContext, Field } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import * as Yup from 'yup'
import { FormContent, FormHeader, PackageBookingSummary, ButtonDrawer, CheckboxField, FieldButton, OptionsPanel, InputField } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import * as MotionElement from '~/components/ui/MotionElement'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(common)

const SelectReceiver = () => {
  const { t } = useTranslation()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const { prev, next } = useContext(StepperContext)
  const { values } = useFormikContext()

  const wrappedNext = wrapWithValidation(next, useFormikContext())

  const openPanel = () => {
    setIsPanelOpen(true)
  }

  const closePanel = () => {
    setIsPanelOpen(false)
  }

  const buttons = [
    { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-continue') },
  ]

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <FormContent>
        <FormHeader>{t('header-whos-the-receiver')}</FormHeader>
        <PackageBookingSummary values={values} showHow showPayment />
      </FormContent>
      <MotionElement.div className={common.contentBottom}>
        <Field name="packageDeliveryInfo.packageReceiver.nameOnDoor" placeholder={t('placeholder-name-receiver-on-door')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageReceiver.phoneNumber" placeholder={t('placeholder-phone-receiver')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageReceiver.numberOfStairs" placeholder={t('placeholder-number-of-stairs')} component={InputField} border />
        <Field name="packageDeliveryInfo.packageReceiver.doorCode" placeholder={t('placeholder-door-code')} component={InputField} border />
        <FieldButton title={t('title-extra-information')} onClick={openPanel} alternateBackground />
        <Field name="packageDeliveryInfo.packageReceiver.leaveOutsideDoor" title={t('title-leave-outside')} tabIndex="0"alternateBackground component={CheckboxField} />
        {values?.packageDeliveryInfo?.otherInformation && <div className={cx(common.fullWidth, styles.message)}>
          <div className={common.label}>{t('title-extra-information')}</div>
          <div className={common.title}>{values.packageDeliveryInfo.otherInformation}</div>
        </div>}
      </MotionElement.div>
      <ButtonDrawer buttons={buttons} />
      <OptionsPanel title={t('title-extra-information')} isOpen={isPanelOpen} onClose={closePanel}>
        <div className={cx(common.contentBottom)}>
          <div className={cx(styles.instructions, common.fullWidth)}>{t('info-extra-information')}</div>
          <div className={common.fullWidth}>
            <Field name="packageDeliveryInfo.otherInformation" placeholder={t('title-extra-information')} maxLength="30" component={InputField} alternateBackground />
          </div>
        </div>
      </OptionsPanel>
    </motion.div>
  )
}

SelectReceiver.validationSchema = Yup.object({
  packageDeliveryInfo: Yup.object({
    packageReceiver: Yup.object({
      nameOnDoor: Yup.string().required(i18next.t('validation-name-receiver-required')),
      phoneNumber: Yup.string()
        .matches(/^\+?\d+$/, i18next.t('validation-only-numbers'))
        .required(i18next.t('validation-need-phone')),
      numberOfStairs: Yup.string()
        .matches(/^\+?\d+$/, i18next.t('validation-only-numbers')),
      doorCode: Yup.string(),
    })
  }),
})

export default SelectReceiver
