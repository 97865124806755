.wrapper {
  display: flex;
  align-items: center;
}

.number, .separator {
  display: inline-flex;
  height: 112px;
  justify-content: center;
  align-items: center;
}

.number {
  position: relative;
  width: 116px;
  outline: none;
  background: white;
  border-radius: var(--border-radius);

  &:focus:after {
    position: absolute;
    display: block;
    content: '';
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 5px;
    background: var(--color-yellow);
    z-index: 1;
  }
}

.text {
  position: relative;
  font-size: 56px;
  z-index: 2;
}

.separator {
  flex: 1;
  font-size: 56px;
}
