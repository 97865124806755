.wrapper {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 24px 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' width='64' height='64' viewBox='0 0 128 128'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z'/%3E%3Cpath d='M92.19 6.374l6.928 4-20 34.641-6.929-4 20-34.64zM117.226 28.19l4 6.928-34.641 20-4-6.929 34.64-20zM128 59.6v8H88v-8h40zM121.626 92.19l-4 6.928-34.641-20 4-6.929 34.64 20z' fill='%23ccc' fill-opacity='.2'/%3E%3Cpath d='M99.81 117.226l-6.928 4-20-34.641 6.929-4 20 34.64z' fill='%23b2b2b2' fill-opacity='.3'/%3E%3Cpath d='M68.4 128h-8V88h8v40z' fill='%23999' fill-opacity='.4'/%3E%3Cpath d='M35.81 121.626l-6.928-4 20-34.641 6.929 4-20 34.64z' fill='%237f7f7f' fill-opacity='.5'/%3E%3Cpath d='M10.774 99.81l-4-6.928 34.641-20 4 6.929-34.64 20z' fill='%23666' fill-opacity='.6'/%3E%3Cpath d='M0 68.4v-8h40v8H0z' fill='%234c4c4c' fill-opacity='.7'/%3E%3Cpath d='M6.374 35.81l4-6.928 34.641 20-4 6.929-34.64-20z' fill='%23333' fill-opacity='.8'/%3E%3Cpath d='M28.19 10.774l6.928-4 20 34.641-6.929 4-20-34.64z' fill='%23191919' fill-opacity='.9'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'/%3E%3C/g%3E%3C/svg%3E%0A");

  &.light {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='64px' height='64px' viewBox='0 0 128 128' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='1'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(30 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(60 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(90 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.2' transform='rotate(120 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.3' transform='rotate(150 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.4' transform='rotate(180 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.5' transform='rotate(210 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.6' transform='rotate(240 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.7' transform='rotate(270 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.8' transform='rotate(300 64 64)'/%3E%3Cpath d='M59.6 0h8v40h-8V0z' fill='%23ffffff' fill-opacity='0.9' transform='rotate(330 64 64)'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64' calcMode='discrete' dur='600ms' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

