import classnames from 'classnames/bind'
import { emitCustomEvent } from 'react-custom-events'
import { useContext } from 'react'
import { StoreContext } from '~/store'
import { getLocalizedSlug } from '~/components/ProfileModule'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

const getFirstName = user => {
  return user?.firstName || user?.lastName?.trim().replace(/\s.+/, '')
} 

const getInitials = user => {
  if (user.firstName !== undefined && user.lastName === undefined) {
    return user?.firstName?.substring(0, 2).toUpperCase()
  }
  else if (user.firstName === undefined && user.lastName !== undefined) {
    return user?.lastName?.substring(0, 2).toUpperCase()
  }
  return user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase()
}

export default function LoginStatus() {
  const { t } = useTranslation()
  const { user } = useContext(StoreContext)

  const onLoginComplete = () => {
    console.log('Login is complete! This is a callback!')
  }

  const onClick = () => {
    if (!user) {
      emitCustomEvent('SHOW_LOGIN', { show: true, callback: onLoginComplete })
    }
    else {
      location.href = getLocalizedSlug('root')
    }
  }

  return (
    <button className={cx(styles.wrapper, { notLoggedIn: !user })} onClick={onClick}>
      {user ? getFirstName(user) : t('button-login')}
      {user && <div className={styles.userIcon}>{getInitials(user)}</div>}
    </button>
  )
}
