import classnames from 'classnames/bind'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(common)

export default function PackageDetails({ values }) {
  const { t } = useTranslation()
  return (
    <>
      <div className={common.group}>
        <h3 className={styles.subhead}>Avsändare</h3>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-name')}</div>
          <div>{values.name}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-phone')}</div>
          <div>{values.phone}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-name-on-door')}</div>
          <div>{values.packageDeliveryInfo.packageSender?.nameOnDoor || '-'}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-number-of-stairs')}</div>
          <div>{values.packageDeliveryInfo.packageSender?.numberOfStairs || '-'}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-door-code')}</div>
          <div>{values.packageDeliveryInfo.packageSender?.doorCode || '-'}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('title-extra-information')}</div>
          <div>{values.packageDeliveryInfo?.otherInformation || '-'}</div>
        </div>
      </div>
      <div className={common.group}>
        <h3 className={styles.subhead}>Mottagare</h3>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-name-receiver-on-door')}</div>
          <div>{values.packageDeliveryInfo.packageReceiver.nameOnDoor}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-phone')}</div>
          <div>{values.packageDeliveryInfo.packageReceiver.phoneNumber}</div>
        </div>
        <div className={styles.desktop}>
          <div className={cx(common.label, { gray: true })}>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-number-of-stairs')}</div>
          <div>{values.packageDeliveryInfo.packageReceiver?.numberOfStairs || '-'}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('label-door-code')}</div>
          <div>{values.packageDeliveryInfo.packageReceiver?.doorCode || '-'}</div>
        </div>
        <div>
          <div className={cx(common.label, { gray: true })}>{t('title-leave-outside')}</div>
          <div>{values.packageDeliveryInfo.packageReceiver.leaveOutsideDoor ? t('label-yes') : t('label-no')}</div>
        </div>
      </div>
    </>
  )
}
