import { utcToZonedTime, formatInTimeZone, toDate } from 'date-fns-tz'
import { addMinutes, differenceInCalendarDays, formatRelative, format as fd } from 'date-fns'
import { sv } from 'date-fns/locale'

// -----------------------------------------------------
//  Dates 
// -----------------------------------------------------

export const defaultTz = 'Europe/Stockholm'

// Format Date object for datetime-local input
export const formatLocalDateTime = date => formatInTimeZone(date, defaultTz, "yyyy-MM-dd'T'HH:mm")

// Parse datetime-local string to Date object 
export const parseLocalDateTime = value => toDate(`${value}:00`, { timeZone: defaultTz })

// Format Date object to timestamp used in API requests
export const formatTimestamp = date => formatInTimeZone(date, defaultTz, "yyyy-MM-dd'T'HH:mm:ssxxx")

// Format readable date and time
export const formatReadable = date => formatInTimeZone(date, defaultTz, 'yyyy-MM-dd HH:mm')

// Format readable date only
export const formatReadableDateOnly = date => formatInTimeZone(date, defaultTz, 'yyyy-MM-dd')

// Format time
export const formatTime = date => formatInTimeZone(date, defaultTz, 'HH:mm')

// Format localized readable date, example: 20:e december kl. 12:30
export const formatLocalized = date => APP.lang === 'sv' ? fd(date, 'do MMMM \'kl.\' p', { locale: sv }) : fd(date, 'do MMMM p')

// Return a relative or a formatted date
export const formatReadableDate = date => {
  date = utcToZonedTime(date, defaultTz)
  const now = utcToZonedTime(new Date(), defaultTz)
  if (differenceInCalendarDays(date, now) < 7) {
    return APP.lang === 'sv' ?
      formatRelative(date, now, { locale: sv }) :
      formatRelative(date, now)
  }
  return formatLocalized(date)
}


// -----------------------------------------------------
//  User 
// -----------------------------------------------------

export const getUserName = user => {
  let userName = ''
  if (user && user.firstName !== undefined) userName += user.firstName
  if (user && user.lastName !== undefined) userName += userName !== '' ? ' ' + user.lastName : user.lastName
  return userName
}


// -----------------------------------------------------
//  Addresses etc.
// -----------------------------------------------------

export function formatAddress(address, streetNumber) {
  if (streetNumber) {
    return `${address.streetName} ${streetNumber}${address.city ? ', ' + address.city : ''}`
  }
  return `${address.streetName}${address.streetNumber ? ' ' + address.streetNumber : ''}${address.entrance ? ' ' + address.entrance : ''}${address.city ? ', ' + address.city : ''}`
}

// NOTE: This is used to avoid a bug where TTES instances have different zone for the 
// same address resulting in a ZONE_ID_NOT_MATCHING error when doing price estimate.
const filterAddress = address => {
  delete address.zone
  return address
}

export function formatLocationId(location) {
  if (location.locationId) return location.locationId
  else {
    const address = location.address
    return `${address.streetName}${address.streetNumber ? ' ' + address.streetNumber : ''}${address.entrance ? ' ' + address.entrance : ''}${address.city ? ' ' + address.city : ''}`
  }
}


// -----------------------------------------------------
//  API requests
// -----------------------------------------------------

export function formatLocationQuery(address) {
  return `${address.streetName}${address.streetNumber ? ' ' + address.streetNumber : ''}${address.entrance ? address.entrance : ''}${address.city ? ', ' + address.city : ''}`
}

export function formatServiceAvailabiltyQuery(values, user) {
  const params = new URLSearchParams()
  if (values.departure?.address) {
    params.append('fromLocationId', formatLocationId(values.departure))
  }
  if (values.destination?.address) {
    params.append('toLocationId', formatLocationId(values.destination))
  }
  if (values?.intermediateStopLocations?.length > 0) {
    const stops = values.intermediateStopLocations.filter(location => location?.address !== undefined)
    if (stops.length > 0) {
      params.append(
        'intermediateLocationIdList', 
        stops.map(location => formatLocationId(location)).toString()
      )
    }
  }
  if (values.pickupTime !== '') {
    params.append('dateTime', formatTimestamp(values.pickupTime))
  }
  if (user && user?.phone !== '') {
    params.append('phone', user.phone)
  }
  if (values?.account?.accountNumber && values?.account?.type) {
    params.append('accountNumber', values.account.accountNumber)
    params.append('accountType', values.account.type)
  }
  return params.toString()
}

export function formatPriceEstimateRequest(values) {
  const booking = {
    originLocation: filterAddress(values.departure.address),
    destinationLocation: filterAddress(values.destination.address),
    serviceTypes: values.serviceType === 'delivery' ? ['delivery'] : ['taxi', 'largeCar', 'business'],
    // vehicleOptions: values.vehicleOptions[0] === '' ? [] : values.vehicleOptions,
  }

  if (values.pickupTime || values.autoValues.pickupTime) {
    booking.pickUpTimeStamp = formatTimestamp(values.autoValues.pickupTime || values.pickupTime)
  }

  if (values?.vouchers?.length > 0) {
    booking.voucherCodes = values.vouchers
  }

  if (values?.intermediateStopLocations?.length > 0) {
    const stops = values.intermediateStopLocations.filter(location => location?.address !== undefined)
    if (stops.length > 0) {
      booking.intermediateStopLocations = stops.map(location => filterAddress(location.address))
    }
  }

  return booking
}

export function getOptions(values) {
  const options = []
  if (values.pet) options.push('pet')
  if (values.stationWagon) options.push('stationWagon')
  if (values.booster === 1) options.push('boosterSeat1')
  if (values.booster === 2) options.push('boosterSeat2')
  return options
}

export function getPackages(values) {
  const packages = []
  if (values.ENVELOPE) packages.push({ packageType: 'ENVELOPE', count: values.ENVELOPE })
  if (values.BOX) packages.push({ packageType: 'BOX', count: values.BOX })
  if (values.BAG) packages.push({ packageType: 'BAG', count: values.BAG })
  if (values.MOVING_BOX) packages.push({ packageType: 'MOVING_BOX', count: values.MOVING_BOX })
  return packages
}

export function formatBookingRequest(values, userId) {

  // const pickup = values.pickupTime 
  //   ? values.pickupTime
  //   : addMinutes(new Date(), 1)

  const booking = {
    // pickUpTimeStamp: formatTimestamp(pickup),
    originLocation: values.departure.address,
    destinationLocation: values.destination.address,
    serviceType: values.autoValues.serviceType || values.serviceType,
    traveler: {
      name: values.name,
      phoneNumber: values.phone
    },
    generalComment: values.generalComment,
    userId: userId,
    vehicleOptions: getOptions(values)
  }

  // Skip pickup time when direct booking
  if (values.pickupTime || values.autoValues.pickupTime) {
    booking.pickUpTimeStamp = formatTimestamp(values.autoValues.pickupTime || values.pickupTime)
  }

  // Add intermediate stops
  if (values?.intermediateStopLocations?.length > 0) {
    booking.intermediateStopLocations = values.intermediateStopLocations.map(location => location.address)
  }

  // Airport
  if (values.departure.locationTypes?.includes('AIRPORT')) {
    booking.originLocation.flightInfo = {
      flightNumber: values.flightNumber,
      checkedInBaggage: values.checkedInBaggage
    }
  }

  // Fixed price?
  const fixedPrice = values.autoValues.fixedPrice !== null ? values.autoValues.fixedPrice : values.fixedPrice
  if (fixedPrice && values.priceToken) {
    booking.priceToken = values.priceToken
  }

  // Add service availability token
  const key = values.pickupTime === '' ? 'direct' : 'prebook'
  if (values?.availability[key]?.serviceToken) {
    booking.serviceToken = values.availability[key].serviceToken
  }

  // Payment
  if (values.payment.indexOf('Account') !== -1) {
    booking.paymentMethod = values.account.type === 'TCA' ? 'TravelClearingAccount' : 'TSABAccount'
    booking.accountInfo = {
      type: values.account.type,
      number: values.account.accountNumber
    }
    booking.invoiceReference = values.accountReference
  }
  else if (values.payment.indexOf('CreditCard') !== -1) {
    booking.paymentMethod = 'CreditCard'
  }
  else {
    booking.paymentMethod = 'PayInCar'
  }

  // Vouchers
  if (values?.vouchers?.length > 0) {
    booking.voucherCodes = values.vouchers
  }

  return booking
}

export function formatPackageBookingRequest(values, userId) {

  // TODO: Skip pickup time when direct booking?
  const pickup = values.pickupTime 
    ? values.pickupTime
    : addMinutes(new Date(), 1)

  const booking = {
    pickUpTimeStamp: formatTimestamp(pickup),
    originLocation: values.departure.address,
    destinationLocation: values.destination.address,
    serviceType: values.serviceType,
    traveler: {
      name: values.name,
      phoneNumber: values.phone
    },
    userId: userId,
    priceToken: values.priceToken,
    packageDeliveryInfo: values.packageDeliveryInfo
  }

  // Do not send empty strings
  if (booking.packageDeliveryInfo.packageSender.nameOnDoor === '') {
    booking.packageDeliveryInfo.packageSender.nameOnDoor = values.name
  }
  if (booking.packageDeliveryInfo.packageSender.numberOfStairs === '') {
    delete booking.packageDeliveryInfo.packageSender.numberOfStairs
  }
  if (booking.packageDeliveryInfo.packageSender.doorCode === '') {
    delete booking.packageDeliveryInfo.packageSender.doorCode
  }

  if (booking.packageDeliveryInfo.packageReceiver.numberOfStairs === '') {
    delete booking.packageDeliveryInfo.packageReceiver.numberOfStairs
  }
  if (booking.packageDeliveryInfo.packageReceiver.doorCode === '') {
    delete booking.packageDeliveryInfo.packageReceiver.doorCode
  }

  // Packages
  const packages = []
  if (values.ENVELOPE > 0) {
    packages.push({ packageType: 'ENVELOPE', count: values.ENVELOPE })
  }
  if (values.BOX > 0) {
    packages.push({ packageType: 'BOX', count: values.BOX })
  }
  if (values.BAG > 0) {
    packages.push({ packageType: 'BAG', count: values.BAG })
  }
  if (values.MOVING_BOX > 0) {
    packages.push({ packageType: 'MOVING_BOX', count: values.MOVING_BOX })
  }
  booking.packageDeliveryInfo.packages = packages

  // Payment
  if (values.payment === 'PayInCar') {
    booking.paymentMethod = 'PayInCar'
  }
  else {
    booking.paymentMethod = values.account.type === 'TCA' ? 'TravelClearingAccount' : 'TSABAccount'
    booking.accountInfo = {
      type: values.account.type,
      number: values.account.accountNumber
    }
    booking.invoiceReference = values.accountReference
  }

  return booking
}


export function formatStatusRequest(bookings) {
  return bookings.map(booking => {
    // NOTE: Support anonymous bookings using the clientBookingToken
    return booking.clientBookingToken ?
      { bookingId: booking.id, clientBookingToken: booking.clientBookingToken } : { bookingId: booking.id }
  })
}
