import { Formik } from 'formik'
import { motion } from "framer-motion"
import { ViewStepper, CurrentView, ViewListener } from '~/components'
import { default as InputPhone } from './InputPhone'
import { default as ValidatePhone } from './ValidatePhone'
import { default as InputEmail } from './InputEmail'
import { default as ValidateEmail } from './ValidateEmail'
import { default as InputName } from './InputName'
import { default as AcceptTerms } from './AcceptTerms'
import { DebugListener } from '~/components/DebugPanel'
import * as common from '~/config/common.module.css'

const OtpFlow = () => {
  const views = [
    { id: 'inputPhone', component: InputPhone },
    { id: 'validatePhone', component: ValidatePhone },
    { id: 'inputEmail', component: InputEmail },
    { id: 'validateEmail', component: ValidateEmail },
    { id: 'inputName', component: InputName },
    { id: 'acceptTerms', component: AcceptTerms },
  ]

  const initialValues = {
    dialCode: '+46',
    phone: '',
    phoneCode: '',
    email: '',
    maskedEmail: '',
    emailCode: '',
    firstName: '',
    lastName: '',
    marketingOptIn: false,
    termsAndConditions: false,
    shouldConsentToMarketing: true,
    shouldConsentToTermsAndConditions: true,
  }

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <ViewStepper views={views}>
        {({ View }) => {
          return (
            <Formik initialValues={initialValues} validationSchema={View?.validationSchema || null}>
              <>
                <CurrentView />
                <ViewListener />
                {process.env.NODE_ENV !== 'production' && <DebugListener id="otp" />}
              </>
            </Formik>
          )
        }}
      </ViewStepper>
    </motion.div>
  )
}

OtpFlow.validationSchema = null

export default OtpFlow
