import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function CharIcon({ className, char, small, gray, ...props }) {
  return (
    <div className={cx(styles.icon, className, { small, gray })} {...props}>{char}</div>
  )
}
