import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames/bind'
import { useCustomEventListener } from 'react-custom-events'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { Spinner } from '~/components'
import { qsa } from '~/utils'
// import { default as mapStyles } from './MapStyles'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

const settings = {
  // mapId: 'fcd856d6f8054356',
  mapId: 'e52abccdf61d11e8',
  center: { lat: 59.323569, lng: 18.06683 },
  zoom: 14,
  // bounds: null,
  // locations: {},
  // mapLoaded: false,
  // mapStarted: false
}

const formatPos = pos => {
  return { lat: pos.latitude, lng: pos.longitude }
}

// NOTE: Only for debug
const formatAddress = address => {
  return address.streetNumber 
    ? address.streetName + ' ' + address.streetNumber
    : address.streetName
}

const MapComponent = () => {
  const elRef = useRef()
  const mapRef = useRef()
  const markersRef = useRef()
  const listenersRef = useRef()
  const timeoutRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)


  const initMap = () => {
    console.log('Init map')

    mapRef.current = new window.google.maps.Map(elRef.current, {
      mapId: settings.mapId,
      gestureHandling: 'none',
      scrollwheel: false,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      ...settings
      // styles: mapStyles,
    })

    createMarkers()

    window.google.maps.event.addListenerOnce(mapRef.current, 'tilesloaded', () => {
      console.log('Map loaded')
      setIsLoaded(true)
      // NOTE: set tabindex to -1 for all elements in the map to avoid scrolling bug
      setTimeout(() => {
        qsa('#map-modern button, #map-modern *[tabindex="0"]').forEach(el => {
          el.tabIndex = -1
        })
      }, 100)
      // window.google.maps.event.addListener(mapRef.current, 'idle', () => {
      //   console.log('Map is idle')
      // })
    })
  }

  const createMarkers = async () => {
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary
    markersRef.current = []
    listenersRef.current = []

    for (let i = 0; i < 10; i++) {
      const pin = new PinElement({
        background: '#febf00',
        borderColor: '#e0a902',
        glyphColor: '#000000',
        // glyph: character,
        scale: 1.25,
      })

      const marker = new AdvancedMarkerElement({
        map: mapRef.current,
        // position: formatPos(location.address.position),
        content: pin.element,
      })

      markersRef.current.push({ marker, pin })      
      listenersRef.current.push(null)
    }
  }

  const handleLocationChange = ({ locations, active }) => {
    if (mapRef.current) {
      clearTimeout(timeoutRef.current)
      locations = locations.slice(-10)

      // NOTE: only for debug
      // locations.forEach(location => {
      //   if (location?.address) console.log(formatAddress(location.address))
      // })


      // Iterate map markers
      for (let i = 0; i < markersRef.current.length; i++) {                                               
        const m = markersRef.current[i]

        // If we have a location, set position and glyph
        if (locations[i]?.address?.position !== undefined) {
          const position = formatPos(locations[i].address.position)
          m.marker.position = position
          m.pin.glyph = 'ABCDEFGHIJKLMNOPQRST'[i]

          // If it is active, animate it
          if (i === active) {
            m.marker.content.addEventListener('animationend', () => { 
              m.marker.content.classList.remove(styles.dropAnim)
            })
            m.marker.content.classList.add(styles.dropAnim)
            zoomPosition(m.marker.position)
          }

          // Remove any previously added listeners
          if (listenersRef[i]?.remove) listenersRef[i].remove()

          // Create and add click listener
          listenersRef[i] = m.marker.addListener('click', () => zoomPosition(m.marker.position))
        }

        // Otherwise hide it
        else {
          m.marker.position = null
          if (listenersRef[i]?.remove) listenersRef[i].remove()
        }
      }
    }
  }

  const handleZoomLocation = location => {
    if (mapRef.current && location?.address?.position !== undefined) {
      clearTimeout(timeoutRef.current)

      // Iterate map markers and find the one matching location position
      for (let i = 0; i < markersRef.current.length; i++) {
        const m = markersRef.current[i]

        // Zoom to the marker
        if (m.marker?.position?.lat === location.address.position.latitude &&
            m.marker?.position?.lng === location.address.position.longitude) {
          zoomPosition(m.marker.position)
        }
      }

    }
  }

  const handleDisplayTrip = () => {
    if (mapRef.current) {
      clearTimeout(timeoutRef.current)

      const numLocations = markersRef.current.reduce((a, m) => m.marker.position !== null ? a + 1 : a, 0)
      if (numLocations > 1) {
        const bounds = new google.maps.LatLngBounds()
        markersRef.current.forEach(m => {
          if (m?.marker?.position) bounds.extend(m.marker.position)
        })
        mapRef.current.fitBounds(bounds, { top: 100, bottom: 250, left: 100, right: 724 })
      }
      else {
        mapRef.current.setZoom(settings.zoom)

        const m = markersRef.current.find(m => m.marker.position !== null)
        if (m?.marker?.position) {
          mapRef.current.panTo(m.marker.position)
          mapRef.current.panBy(362, 0) // Adjust position to not have the booking module cover the marker
        }
      }
    }
  }

  const handleReset = () => {
    if (mapRef.current) {
      clearTimeout(timeoutRef.current)
      markersRef.current.forEach(m => m.marker.position = null)
      mapRef.current.panTo(settings.center)
      mapRef.current.setZoom(settings.zoom)
    }
  }

  const zoomPosition = position => {
    clearTimeout(timeoutRef.current)
    mapRef.current.setZoom(settings.zoom + 2)
    mapRef.current.panTo(position)
    mapRef.current.panBy(362, 0) // Adjust position to not have the booking module cover the marker
    displayTrip()
  }

  const displayTrip = () => {
    timeoutRef.current = setTimeout(handleDisplayTrip, 5000)
  }

  useCustomEventListener('LOCATION_CHANGE', handleLocationChange)
  useCustomEventListener('ZOOM_LOCATION', handleZoomLocation)
  useCustomEventListener('DISPLAY_TRIP', handleDisplayTrip)
  useCustomEventListener('RESET_MAP', handleReset)

  useEffect(() => {
    if (!mapRef.current) {
      initMap()
    }
  })

  return (
    <div ref={elRef} id="map" className={cx(styles.map, { isLoaded })} />
  )
}

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )
    case Status.FAILURE:
      return 'Error'
    case Status.SUCCESS:
      return <MapComponent />
  }
}

const Map = () => <Wrapper apiKey={'AIzaSyCVkV2uTILscU5D4Zij6bf4zPedVjtb534'} render={render} />
export default Map
