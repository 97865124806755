.options {
  position: relative;
  display: flex;
  height: 20px;
  background: var(--color-black-10);
  border-radius: var(--border-radius);
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: background-color 0.1s ease;

  &:focus-visible::before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + 4px);
    height: 24px;
    top: 50%;
    left: 50%;
    border-radius: 7px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px var(--color-yellow);
  }

  &.isSelected {
    background: var(--color-yellow);
    transition: background-color 0.1s ease;
  }
}

.option {
  width: 25px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: black;
  transition: background-color 0.1s ease;

  &.isSelected {
    box-shadow: inset 0 0 0 1px var(--color-border-gray);
    border-radius: var(--border-radius);
    background: white;
    transition: background-color 0.1s ease;
  }
}
