import { useRef, useState } from 'react'
import { formatInTimeZone, toDate } from 'date-fns-tz'
import { isEqual } from 'date-fns'
import { useDidMountEffect } from '~/utils/hooks'
import { defaultTz } from '~/utils/format'
import * as styles from './index.module.css'

const useTimeInput = (initial, min, max) => {
  const v = useRef('')
  const timeout = useRef()
  const [time, setTime] = useState(initial)

  const clampTime = value => {
    return Math.max(min, Math.min(max, value)).toString()
  }

  const formatTime = value => {
    return value < 10 ? '0' + value : value
  }

  const setter = value => {
    setTime(formatTime(clampTime(value)))
  }

  const setterDelayed = value => {
    clearTimeout(timeout.current)
    v.current = v.current.length < 2 ? v.current + value : value.toString()
    v.current = clampTime(v.current)
    setTime(formatTime(v.current))

    timeout.current = setTimeout(() => {
      v.current = ''
      timeout.current = null
    }, 1000)
  }

  return [time, setter, setterDelayed]
}

const getHours = date => formatInTimeZone(date, defaultTz, 'HH')
const getMinutes = date => formatInTimeZone(date, defaultTz, 'mm')


export default function TimeWidget({ selected, onChange }) {
  const timeout = useRef()
  const [hours, setInputHours, setInputHoursDelayed] = useTimeInput(getHours(selected), 0, 23)
  const [minutes, setInputMinutes, setInputMinutesDelayed] = useTimeInput(getMinutes(selected), 0, 59)

  const updateTime = () => {
    clearTimeout(timeout.current)
    const dateStr = formatInTimeZone(selected, defaultTz, 'yyyy-MM-dd')
    const d = toDate(`${dateStr} ${hours}:${minutes}:00`, { timeZone: defaultTz })
    if (!isEqual(selected, d)) onChange(d)
  } 

  const onKeyDown = e => {
    const isHours = e.target.id === 'hours'

    // Arrow keys
    if (e.keyCode === 38 || e.keyCode === 40) {
      const increase = e.keyCode === 38
      e.preventDefault()

      if (isHours) {
        let h = parseInt(hours)
        h = increase ? h + 1 : h - 1
        if (h > 23) h = 0
        else if (h < 0) h = 23
        setInputHours(h)
      }
      else {
        let m = parseInt(minutes)
        m = increase ? m + 1 : m - 1
        if (m > 59) m = 0
        else if (m < 0) m = 59
        setInputMinutes(m)
      }
    }
    // Numbers
    else {
      const v = parseInt(e.key)
      if (!isNaN(v)) {
        if (isHours) setInputHoursDelayed(v) 
        else setInputMinutesDelayed(v)
      }
    }
  }

  useDidMountEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(updateTime, 1100)
  }, [hours, minutes])

  // Force update if selected date is changed
  useDidMountEffect(() => {
    setInputHours(getHours(selected), true)
    setInputMinutes(getMinutes(selected), true)
  }, [selected])

  return (
    <div className={styles.wrapper}>
      <span className={styles.number} id="hours" tabIndex="0" onBlur={updateTime} onKeyDown={onKeyDown}>
        <span className={styles.text}>{hours}</span>
      </span>
      <span className={styles.separator}>:</span>
      <span className={styles.number} id="minutes" tabIndex="0" onBlur={updateTime} onKeyDown={onKeyDown}>
        <span className={styles.text}>{minutes}</span>
      </span>
    </div>
  )
}

