import classnames from 'classnames/bind'
import { FieldWrapper, Switch } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function CheckboxFieldSimple({ field, form, title, reverseLayout, alternateBackground, ...props }) {
  const isChecked = field.value
  const isDisabled = props.disabled
  return (
    <FieldWrapper reverseLayout={reverseLayout} alternateBackground={alternateBackground} isDisabled={isDisabled}>
      <div className={cx(styles.title, { isChecked })}>{title}</div>
      <Switch 
        isChecked={isChecked}
        name={field.name} 
        form={form}
        onChange={field.onChange} 
        onBlur={field.onBlur} 
        {...props} 
      />
    </FieldWrapper>
  )
}
