  @media (--lg) {
    .datepicker {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: var(--border-radius);
    }
}
