import classnames from 'classnames/bind'
import { ButtonDrawer } from '~/components'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export default function OptionsPanel({ title, isOpen, onClose, children, noPadding, alternateBackground }) {
  const { t } = useTranslation()

  const buttons = [
    { func: onClose, title: t('button-done'), tabIndex: isOpen ? 0 : -1 },
  ]

  return (
    <div className={cx(styles.wrapper, { isOpen })}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.panel}>
        <div className={cx(styles.panelContent, { noPadding })}>
          {title && <div className={styles.title}>{title}</div>}
          {children}
        </div>
        <ButtonDrawer buttons={buttons} className={alternateBackground ? styles.alternateBackground : null} />
      </div>
    </div>
  )
}
