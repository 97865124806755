.wrapper {
  position: fixed;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--padding-small);
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;

  &.isVisible {
    display: flex;
  }
}

.container {
  position: relative;
  padding: 30px 0 0;
  background: #FFF;
  border-radius: var(--border-radius-large);
  overflow: hidden;

  &.isCashier {
    width: 100%;
    min-height: 500px;
  }

  @media (--md) {
    max-width: 700px;
    padding: 50px 2px 0;
  }
}

.form {
  display: none;
}

.iframe {
  /* width: 100%; */
  /* height: 100%; */

  @media (--sm) {
  }
}

