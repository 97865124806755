import { useState } from 'react'
import { Formik } from 'formik'
import classnames from 'classnames/bind'
import { useCustomEventListener } from 'react-custom-events'
import { ViewStepper, CurrentView, ResetButton } from '~/components'
import { default as InputPhone } from '~/components/OtpFlow/InputPhone'
import { default as ValidatePhone } from '~/components/OtpFlow/ValidatePhone'
import { default as InputEmail } from '~/components/OtpFlow/InputEmail'
import { default as ValidateEmail } from '~/components/OtpFlow/ValidateEmail'
import { default as InputName } from '~/components/OtpFlow/InputName'
import { default as AcceptTerms } from '~/components/OtpFlow/AcceptTerms'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(styles)

export default function LoginModule() {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [completeCallback, setCompleteCallback] = useState(null)

  const views = [
    { id: 'inputPhone', component: InputPhone, props: { isLogin: true } },
    { id: 'validatePhone', component: ValidatePhone },
    { id: 'inputEmail', component: InputEmail },
    { id: 'validateEmail', component: ValidateEmail },
    { id: 'inputName', component: InputName },
    { id: 'acceptTerms', component: AcceptTerms, props: { isLogin: true } },
  ]

  const initialValues = {
    dialCode: '+46',
    phone: '',
    phoneCode: '',
    email: '',
    maskedEmail: '',
    emailCode: '',
    firstName: '',
    lastName: '',
    marketingOptIn: false,
    termsAndConditions: false,
    shouldConsentToMarketing: true,
    shouldConsentToTermsAndConditions: true,
  }

  useCustomEventListener(
    'SHOW_LOGIN',
    (data) => {
      const show = data === null || data?.show === true 
      if (show && data?.callback) {
        setCompleteCallback(() => data.callback)
      }
      else {
        setCompleteCallback(null)
      }
      setIsOpen(show)
    },
    [isOpen]
  )

  return (
    <div className={cx(styles.overlay, { isOpen })}>
      {isOpen && <div className={styles.wrapper}>
        <ViewStepper views={views} complete={completeCallback}>
          {({ View }) => {
            return (
              <Formik 
                initialValues={initialValues} 
                validationSchema={View?.validationSchema || null}
                onReset={() => {
                  // Triggered by the X close button
                  setIsOpen(false)
                }}
              >
                <>
                  <CurrentView />
                  <ResetButton message={t('reset-login-message')} />
                </>
              </Formik>
            )
          }}
        </ViewStepper>
      </div>}
    </div>
  )
}
