.icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width:25px;
  height: 25px;
  background: var(--color-yellow);
  border-radius: 50%;
  color: black;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 25px;
  user-select: none;
  z-index: 3;

  &.small {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  &.gray {
    background: #A7A8A9;
  }
}
