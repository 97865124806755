.widgetWrapper {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  pointer-events: auto;
  background: var(--color-cool-gray);
  border-radius: var(--border-radius-large);
  /* box-shadow: var(--box-shadow); */

  @media (--lg) {
    width: 624px;
    margin-left: auto;
    margin-right: initial;
  }

  &.isLoading {
    pointer-events: none;
  }

  &.tabsLeft {
    border-top-left-radius: 0;
  }
}

.content, .contentBottom {
  display: grid;
  grid-template-columns: 1fr;

  @media (--lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.group {
  display: grid;
  grid-template-rows: max-content;
  align-content: start;
  gap: inherit;
  width: 100%;
}

.content {
  gap: calc(var(--spacing) * 2);
}

.contentBottom {
  gap: var(--spacing);
  padding: var(--padding-small);
  background: white;

  &.noBackground {
    background: none;
  }

  &.largePadding {
    padding: var(--padding);
    gap: calc(var(--spacing) * 2);
  }
}

.fullWidth {
  grid-column: 1 / -1;
}


.fieldDescription {
  display: flex;
  height: 56px;
  align-items: center;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.bold {
  font-weight: 500;
}

.label {
  position: relative;
  font-size: 13px;
  text-transform: uppercase;

  &.gray {
    color: var(--color-black-50);
  }
}

.title {
  font-size: 20px;
  margin-top: 5px;
}

.subtitle {
  font-size: 13px;
  line-height: 16px;
  color: var(--color-black-50);
  margin-top: 2px;
}

.smaller {
  font-size: 13px;
}

.linkButton {
  text-decoration: underline;
}

.option {
  color: black;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 12px;
  border-radius: var(--border-radius);
  padding: 4px 6px;
}
