import { FieldWrapper, Icon, TitleInfo } from '~/components'
import * as styles from './index.module.css'

export default function FieldButton({ title, info, innerRef, reverseLayout, alternateBackground, disabled, onClick, children }) {
  return (
    <FieldWrapper reverseLayout={reverseLayout} alternateBackground={alternateBackground} isDisabled={disabled} onClick={onClick}>
      <div className={styles.content}>
        <TitleInfo title={title} info={info} />
        {children}
      </div>
      <button 
        ref={innerRef}
        tabIndex="0"
        type="button" 
        className={styles.button} 
        onClick={onClick} 
      >
        <Icon id="plus" />
      </button>
    </FieldWrapper>
  )
}
