.title {
  font-size: 14px;
  font-weight: 500;
}

.info {
  font-size: 11px;
  line-height: 1.2em;
  color: var(--color-black-50);
  margin-top: 2px;
}
