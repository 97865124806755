.options {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.option {
  color: black;
  background: var(--color-yellow);
  font-size: 11px;
  line-height: 12px;
  border-radius: var(--border-radius);
  padding: 4px 6px;
}
