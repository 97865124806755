import { useContext } from 'react'
import { StoreContext } from '~/store'
import { getUserName } from '~/utils/format'

export default function Header() {
  const { user } = useContext(StoreContext)
  const COPY = window.COPY
  return (
    <section id="my-pages-header">
      <div className="my-pages-container">
        <h2>{COPY.my_pages.my_profile}</h2>
        <div className="user-name">{`${getUserName(user)}`}</div>
      </div>
    </section>
  )
}
